export const RouteNames = {
  Root: {
    nl: 'root__nl',
    en: 'root__en',
  },
  Index: {
    nl: 'index___nl',
    en: 'index___en',
  },
  ProfielSelectie: {
    nl: 'profielselectie___nl',
    en: 'profielselectie___en',
  },
  ProfielNietBeschikbaar: {
    nl: 'profielselectie-unavailable___nl',
    en: 'profielselectie-unavailable___en',
  },
  Notificatie: {
    nl: 'notificatie___nl',
    en: 'notificatie___en',
  },
  NotificatieBulk: {
    nl: 'notificatie-bulk___nl',
    en: 'notificatie-bulk___en',
  },
  NotificatieLid: {
    nl: 'notificatie-lid___nl',
    en: 'notificatie-lid___en',
  },
  NotificatieVersturen: {
    nl: 'notificatie-versturen___nl',
    en: 'notificatie-versturen___en',
  },
  NotificatieGroep: {
    nl: 'notificatie-groep___nl',
    en: 'notificatie-groep___en',
  },
  NotificatieGroepVersturen: {
    nl: 'notificatie-groep-versturen___nl',
    en: 'notificatie-groep-versturen___en',
  },
  NotificatieBulkResultaat: {
    nl: 'notificatie-bulk-resultaat___nl',
    en: 'notificatie-bulk-resultaat___en',
  },
  NotificatieLidNieuw: {
    nl: 'notificatie-lid-nieuw___nl',
    en: 'notificatie-lid-nieuw___en',
  },
  NotificatieLidDetail: {
    nl: 'notificatie-lid-id___nl',
    en: 'notificatie-lid-id___en',
  },
  Rapportering: 'rapportering',
  RapporteringSelectie: 'rapporteringselectie',
  RapporteringTitel: 'rapportering_titel',
  Download: 'download-guid',
  Documentendienst: {
    nl: 'documentendienst___nl',
    en: 'documentendienst___en',
  },
  DocumentendienstBriefVerzenden: 'documentendienst-brief-verzenden',
  DocumentendienstEboxVerzenden: 'documentendienst-ebox-verzenden',
  DocumentendienstEmailVerzenden: 'documentendienst-email-verzenden',
  DocumentendienstVerzendingMessageid1: {
    nl: 'documentendienst-verzending-messageid1___nl',
    en: 'documentendienst-verzending-messageid1___en',
  },
  DocumentendienstVerzendingMessageid1Messageid2: {
    nl: 'documentendienst-verzending-messageid1-messageid2___nl',
    en: 'documentendienst-verzending-messageid1-messageid2___en',
  },
  DocumentendienstBriefHerverzenden: {
    nl: 'documentendienst-brief-herverzenden-messageId___nl',
    en: 'documentendienst-brief-herverzenden-messageId___en',
  },
  DocumentendienstEboxHerverzenden: {
    nl: 'documentendienst-ebox-herverzenden-messageId___nl',
    en: 'documentendienst-ebox-herverzenden-messageId___en',
  },
  DocumentendienstAdvancedBulk: 'documentendienst-bulk-advanced',
  DocumentendienstAdvancedBulkResultaat: 'documentendienst-bulk-advanced-resultaat',
  DocumentendienstSimpleBulk: 'documentendienst-bulk-simple',
  DocumentendienstSimpleBulkResultaat: 'documentendienst-bulk-simple-resultaat',
  Persoon: 'persoon',
  PersoonToevoegen: 'persoon-toevoegen',
  PersoonInsz: 'persoon-insz-insz',
  PersoonGezinssamenstelling: 'persoon-gezinssamenstelling',
  PersoonGezinssamenstellingInsz: 'persoon-gezinssamenstelling-insz-insz',
  PersoonFout: 'persoon-fout',
  Mobiliteit: 'mobiliteit',
  BelgischVoertuig: 'mobiliteit-belgischvoertuig-nummerplaat',
  BuitenlandVoertuigNummerplaat1: 'mobiliteit-buitenlandsvoertuig-land-nummerplaat1',
  BuitenlandVoertuigNummerplaat2: 'mobiliteit-buitenlandsvoertuig-land-nummerplaat1-nummerplaat2',
  SocialeZekerheid: 'socialezekerheid',
  DocumentProvider: 'documentprovider',
  Kadaster: 'kadaster',
  KadasterEigendomstoestandDetail: 'kadaster-capakeyPartitionId',
  KadasterVerkoopprijzenDetail: 'kadaster-salesdetail',
  KadasterHistoriekEigendomstoestand: 'kadaster-ownershipstatushistory',
  LED: 'leer_en_ervaring',
  LedInsz: 'leer_en_ervaring-insz',
  LedInszReferte: 'leer_en_ervaring-insz-referte',
  LedToevoegen: 'leer_en_ervaring-toevoegen',
  LedToevoegenViaBestand: 'leer_en_ervaring-toevoegen-via_bestand',
  LedToevoegenViaBestandResultaat: 'leer_en_ervaring-toevoegen-via_bestand-resultaat-jobId',
  Attesten: {
    nl: 'attesten___nl',
    en: 'attesten___en',
  },
  OnDoseRequired: {
    nl: 'attesten-onedoserequired___nl',
    en: 'attesten-onedoserequired___en',
  },
  AttestId: {
    nl: 'attesten-attestId___nl',
    en: 'attesten-attestId___en',
  },
  CtpcId: {
    nl: 'ctpc-ctpcId___nl',
    en: 'ctpc-ctpcId___en',
  },
  Logging: 'logging',
  LoggingDetail: 'logging-detail-referte',
  LoggingRequestId: 'logging-request-id',
  Gezin: 'gezin',
  Onderneming: 'onderneming',
  OndernemingDetailKbo: 'onderneming-detail-kbo',
}
