import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MoLabelValueWrapper extends Vue {
  @Prop({ required: false, default: '' })
  label: string

  @Prop({ required: true, default: '' })
  value: string
}
