import 'reflect-metadata'
import Vue, { h } from 'vue'
import App from './app.vue'
import pinia from './infrastructure/store'
import router from './infrastructure/router'
import i18n from './infrastructure/i18n'
import { API } from './plugins/api'
import axios from './infrastructure/axios'
import { Alert } from './plugins/mo-alert'
import utils from './assets/util'
import GTag from './infrastructure/ga'
import GA from './plugins/vue-gtag'
// register metadata
import './infrastructure/meta/meta'
// register plugins
import './plugins/text-mask.js'
import './plugins/ippro-components.js'
import './plugins/vue2-editor.js'
import './plugins/vl-ui-vue-components'
import './plugins/mo-shared-components'
import './plugins/vue-gtag'
import './plugins/directives.js'
import './plugins/vue-breadcrumbs.js'
import './plugins/vue-router-locales'
import './plugins/mo-dialog'
import { Component } from 'vue/types/umd'
import { GezinClient } from './api/gezin/gezin-client'
import { OndernemingClient } from './api/onderneming/onderneming-client'
import { LogClient } from './api/log/log-client'
import { SocZekClient } from './api/soczek/soczek-client'

Vue.prototype.$axios = axios
const api = new API({ $axios: axios }, false)
Vue.prototype.$api = api

const gezinClient = new GezinClient('/sss/api/gezin', axios)
Vue.prototype.$gezinClient = gezinClient

const ondernemingClient = new OndernemingClient('/sss/api/onderneming', axios)
Vue.prototype.$ondernemingClient = ondernemingClient

const logClient = new LogClient('/sss/api/log', axios)
Vue.prototype.$logClient = logClient

const socZekClient = new SocZekClient('/sss/api/soczek', axios)
Vue.prototype.$socZekClient = socZekClient

const alert = new Alert({ $axios: axios })
Vue.prototype.$alert = alert

const gtag = GTag
const ga = new GA({ $gtag: gtag })
Vue.prototype.$ga = ga

Vue.prototype.$utils = utils

const app = new Vue({
  pinia: pinia,
  router,
  i18n,
  render: (h) => h(App as Component),
})

app.$mount('#app')
