import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MoInputField extends Vue {
  @Prop(Boolean)
  modError: boolean

  @Prop(Boolean)
  modDisabled: boolean

  @Prop(Boolean)
  modBlock: boolean

  @Prop(String)
  value: string

  @Prop({ default: 'text' })
  type: string

  @Prop({ default: null })
  name: string

  @Prop({ default: null })
  id: string

  @Prop(String)
  placeholder: string

  get inputValue(): string {
    return this.value
  }

  set inputValue(newValue: string) {
    this.$emit('input', newValue)
  }
}
