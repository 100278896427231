import { decryptBase64 } from './../libs/base64'
import { Component, Vue } from 'vue-property-decorator'
import { GACategory } from '@/libs/constants'
import { userStore } from '@/store/user'
import { pageStore } from '@/store/page'
import { IRole } from '@/declarations/references.models'
import { routeMap } from '@/assets/routeMap'

const roleUriRegex = new RegExp(/.*\/(.*)-mo.*|.*\/(.*)/)

@Component
export default class RoleSelection extends Vue {
  searching: boolean

  userStore = userStore()
  pageStore = pageStore()
  get roles(): Array<IRole> {
    const roles = this.userStore.roles || []

    return [...roles].sort((a, b) => {
      const roleNameA = this.getRoleDisplayname(a)
      const roleNameB = this.getRoleDisplayname(b)
      if (roleNameA < roleNameB) {
        return -1
      }
      if (roleNameA > roleNameB) {
        return 1
      }
      return 0
    })
  }

  public get activeRole(): IRole {
    return this.userStore.activeRole
  }

  get hasMultipleRoles(): boolean {
    return this.roles && this.roles.length > 1
  }

  isActiveRole(role: IRole): boolean {
    return this.activeRole && this.activeRole?.full == role.full
  }

  getActiveRoleDisplayName(): string {
    if (this.activeRole) {
      const role = this.roles.find((x) => x.full == this.activeRole.full)
      if (role) {
        return this.getRoleDisplayname(role)
      }
    }

    return this.$t('header.label.chooseProfile').toString()
  }

  getRoleDisplayname(role: IRole): string {
   if (role) {
      if (role.service) {
        return role.service
      }
      if (role.uri) {
        const match = roleUriRegex.exec(role.uri)
        if (match && match.length >= 1) {
          if (match[1]) {
            return match[1]
          }
          if (match[2]) {
            return match[2]
          }
        }
        return role.uri
      }
    }

    return ''
  }

  async selectRole(role: IRole): Promise<void> {
    try {
      this.searching = true
      this.$ga.sendEvent(GACategory.profiel.category, GACategory.profiel.selectNewRole)

      await this.userStore.setActiveRole(role)
      await this.pageStore.clear()

      const path = this.getRedirectUriAfterRoleSelection(role)
      this.$router.push(path).catch(() => {})
    } catch (e) {
      this.$alert.updateError({
        closeable: true,
        errorObject: e,
        id: 0,
        pageName: this.$route.name,
        sourceFile: 'profielselectie.vue',
        sourceMethod: 'selectRole',
      })
    } finally {
      this.searching = false
    }
  }

  private getRedirectUriAfterRoleSelection(role: IRole): string {
    let routeToRedirect

    const route = this.$route
    const redirectUri = route.query.redirect

    if (role.domains?.length === 1 && routeMap[role.domains[0].toLowerCase()]) {
      routeToRedirect = this.localePath({ name: routeMap[role.domains[0].toLowerCase()] })
    }
    else if (redirectUri) {
      routeToRedirect = decryptBase64(redirectUri)
    } else {
      routeToRedirect = this.localePath('/')
    }

    return routeToRedirect
  }
}
