import { Route } from 'vue-router'

export function redirectToLogin(to: Route = null): void {
  let href = `${window.location.origin}/auth/login`
  const redirectUri = buildRedirectUri(to)
  if (redirectUri) {
    href += `?redirectUri=${redirectUri}`
  }
  window.location.href = href
}

export function redirectToSwitch(): void {
  window.location.href = `${window.location.origin}/auth/switch`
}

export function redirectToLogout(): void {
  window.location.href = `${window.location.origin}/auth/signout`
}

function buildRedirectUri(to: Route = null): string {
  let redirectUri: string = null
  if (to !== null) {
    redirectUri = encodeURIComponent(to.fullPath)
  } else {
    redirectUri = encodeURIComponent('/')
  }
  return redirectUri
}
