import validation from '@/mixins/validation'
import { isNaN } from 'lodash'
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'

export enum UnitEnum {
  Surface,
  Currency,
  No,
  Year,
}

export class MinMax {
  min: number
  max: number
}

@Component
export default class MoMinMaxWrapper extends mixins(validation) {
  min: number = null
  max: number = null

  @Prop({ default: UnitEnum[UnitEnum.No] })
  public unit: UnitEnum

  @Prop({
    default: null,
  })
  public value: MinMax

  @Watch('value', { immediate: true, deep: true })
  onValueChanged(newValue) {
    if (newValue) {
      this.min = this.value.min
      this.max = this.value.max
    }
  }

  isNumber(evt: KeyboardEvent): void {
    const keysAllowed: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    const keyPressed: string = evt.key

    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault()
    }
  }

  @Watch('min')
  onMinChanged() {
    this.emitInput()
  }

  @Watch('max')
  onMaxChanged() {
    this.emitInput()
  }

  emitInput() {
    this.$emit('input', {
      min: this.min == null || isNaN(this.min) ? null : parseInt(this.min?.toString(), 10),
      max: this.max == null || isNaN(this.max) ? null : parseInt(this.max?.toString(), 10),
    })
  }

  get minLabel() {
    return this.unit.toString() === UnitEnum[UnitEnum.Year] ? 'Van' : 'Min'
  }

  get maxLabel() {
    return this.unit.toString() === UnitEnum[UnitEnum.Year] ? 'Tot' : 'Max'
  }
}
