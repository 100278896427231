import { Component, Mixins } from 'vue-property-decorator'
import RoleSelectionMixin from '@/mixins/roleSelection'
import { IRole } from '@/declarations/references.models'

@Component
export default class MoAccountPanel extends Mixins(RoleSelectionMixin) {
  public isOpen = false
  public toggleFocus = false

  get familyName() {
    return this.userStore.familyName
  }

  get givenName() {
    return this.userStore.givenName
  }

  get displayName(): string {
    let name = ''
    if (this.familyName || this.givenName) {
      name = `${this.givenName} ${this.familyName}`.trim()
    }

    return name
  }

  buttonClicked() {
    this.toggle()
  }

  close(): void {
    this.isOpen = false
  }

  toggle(): void {
    this.isOpen = !this.isOpen
  }

  switchRole(role: IRole) {
    this.selectRole(role)
    this.close()
  }

  toRoleSelectionpage(): void {
    if (this.$route.name.split('___')[0] !== 'profielselectie') {
      this.$router.push(this.localePath({ name: 'profielselectie' }))
    }
    this.close()
  }
}
