var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c(
        "div",
        {
          staticClass: "vl-col--1-1 vl-tab__pane vl-u-spacer--large",
          attrs: {
            id: `${_vm.uid}-tab-content`,
            role: "tabpanel",
            "aria-labelledby": `${_vm.uid}-tab-link`,
            "data-vl-show": _vm.isActive,
          },
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }