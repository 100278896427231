import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import moment, { months } from 'moment'

moment.locale('nl-BE')

export class MonthYear {
  month: number
  year: number
}

class Month {
  value: number
  name: string
}

@Component({
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
  },
})
export default class MoMonthYearSelector extends Vue {
  month: Month = this.months.filter((m) => m.value === moment().month() + 1).at(0)
  year = moment().year()

  value: MonthYear = { month: null, year: null }

  @Prop()
  localValue: MonthYear

  @Watch('localValue', { immediate: true })
  localValueChanged(newValue: MonthYear) {
    if (newValue && (newValue.month || newValue.year)) {
      this.month = this.months.filter((m) => m.value === newValue.month)?.at(0)
      this.year = this.years.indexOf(newValue.year) > -1 ? newValue.year : null
    } else {
      this.month = this.months.filter((m) => m.value === moment().month() + 1).at(0)
      this.year = moment().year()
    }
  }

  get months() {
    return moment.months().map((m, index) => {
      return { value: index + 1, name: m }
    })
  }
  get years() {
    const years = []
    for (let i = 2019; i <= moment().year(); i++) {
      years.push(i)
    }
    return years
  }

  @Watch('month', { immediate: true })
  monthChanged() {
    this.value.month = this.month?.value
  }

  @Watch('year', { immediate: true })
  yearChanged() {
    this.value.year = this.year
  }

  @Watch('value', { deep: true, immediate: true })
  valueChanged() {
    this.$emit('input', this.value)
  }
}
