var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "mo-formfield-decorator",
        { attrs: { label: _vm.minLabel } },
        [
          _c(
            "vl-input-group",
            [
              _vm.unit === "Currency"
                ? _c("vl-input-addon", {
                    attrs: { tooltip: "In Euro", text: "€" },
                  })
                : _vm._e(),
              _c("vl-input-field", {
                class:
                  _vm.unit === "No" || _vm.unit === "Year" ? "bordered" : "",
                style:
                  _vm.unit === "Currency"
                    ? "max-width: 100px;"
                    : "width: 70px;",
                attrs: {
                  type: "number",
                  min: "0",
                  "mod-block": "",
                  "mod-error": _vm.showError,
                },
                on: {
                  keypress: function ($event) {
                    return _vm.isNumber($event)
                  },
                },
                model: {
                  value: _vm.min,
                  callback: function ($$v) {
                    _vm.min = _vm._n($$v)
                  },
                  expression: "min",
                },
              }),
              _vm.unit === "Surface"
                ? _c("vl-input-addon", {
                    attrs: { tooltip: "Oppervlakte in m²", text: "m²" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "mo-formfield-decorator",
        { attrs: { label: _vm.maxLabel } },
        [
          _c(
            "vl-input-group",
            [
              _vm.unit === "Currency"
                ? _c("vl-input-addon", {
                    attrs: { tooltip: "In Euro", text: "€" },
                  })
                : _vm._e(),
              _c("vl-input-field", {
                class:
                  _vm.unit === "No" || _vm.unit === "Year" ? "bordered" : "",
                style:
                  _vm.unit === "Currency"
                    ? "max-width: 100px;"
                    : "width: 70px;",
                attrs: {
                  type: "number",
                  "mod-block": "",
                  min: "0",
                  "mod-error": _vm.showError,
                },
                on: {
                  keypress: function ($event) {
                    return _vm.isNumber($event)
                  },
                },
                model: {
                  value: _vm.max,
                  callback: function ($$v) {
                    _vm.max = _vm._n($$v)
                  },
                  expression: "max",
                },
              }),
              _vm.unit === "Surface"
                ? _c("vl-input-addon", {
                    attrs: { tooltip: "Oppervlakte in m²", text: "m²" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }