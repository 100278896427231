var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mo-datepicker", {
    attrs: {
      locale: _vm.$i18n.locale,
      "mod-error": _vm.showError,
      "mod-disabled": _vm.modDisabled,
      "min-date": _vm.minDate,
      "max-date": _vm.maxDate,
      "enable-time": _vm.enableTime,
      defaultHour: _vm.defaultHour,
      defaultMinute: _vm.defaultMinute,
      mask: _vm.mask,
      "show-mask": _vm.showMask,
      guide: _vm.guide,
      format: _vm.format,
      "placeholder-char": _vm.placeholderChar,
      placeholder: _vm.placeholder,
    },
    on: {
      blur: function ($event) {
        return _vm.$emit("blur", $event)
      },
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }