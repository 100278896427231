import { IAddress } from '@/components/shared/mo-address-form/mo-address-form'
import { IProof } from './leer_en_ervaring.models'
import { IKeyVal } from './location.models'

export interface IGAButtonEvent {
  category: string
  action: string
  value?: string
}

export interface IPersonResponse {
  insz: string
  voornaam: string[]
  achternaam: string[]
  geboortedatum: string
  geboorteGemeente: string
  geboorteLand: string
  geboortePostcode: string
  hoofdverblijfplaats: {
    straat: string
    huisnummer: string
    busnummer: string
    gemeente: string
    land: string
    postcode: string
    vanaf: string
  }
  contactAdres: {
    straat: string
    huisnummer: string
    busnummer: string
    gemeente: string
    land: string
    postcode: string
    vanaf: string
    tot: string
    type: string
  }
  overlijden: {
    datum: string
    gemeente: string
    land: string
  }
  aangifeAdresWijziging: {
    datumAangifte: string
    opmerking: string
  }
  aangifteAdresBuitenland: {
    land: string
    opmerking: string
    vanaf: string
  }
  opgegevenAdres: {
    straat: string
    postcode: string
    gemeente: string
    huisnummer: string
    busnummer: string
    vanaf: string
    tot: string
    inrichting: string
  }
  verblijfplaatsBuitenland: {
    diplomatiekePost: string
    landDiplomatiekePost: string
    landVanVerblijf: string
    vanaf: string
    tot: string
    opmerking: string
  }
  tijdelijkeAfwezigheid: {
    type: string
    opmerking: string
    vanaf: string
    tot: string
  }
  postAdresBuitenland: {
    land: string
    opmerking: string
    vanaf: string
    tot: string
  }
  persoonsrelatie: {
    samenwonen: {
      datum: string
      persoon: {
        insz: string
      }
      gemeente: string
      land: string
      notariaat: {
        naam: string
        gemeente: string
        land: string
      }
      heeftBeeindiging: {
        omschrijving: string
        kennisgeving: {
          gemeente: string
          land: string
        }
      }
    }
  }
  burgerlijkeStaat: IPersonBurgerlijkeStaatResponse[]
  nationaliteiten: string[]
  register: {
    naam: string
    code: string
  }
  geslacht: string
  waarschuwingen: IWarning[]
}

export interface IPersonBurgerlijkeStaatResponse {
  typeNaam: string
  partnerInsz: string
  gemeente: string
  postcode: string
  land: string
  vanaf: string
}

export interface IPersonNameRequest {
  lastname?: string
  birthdate?: string
  tolerance?: string
  originalBirthDate?: string
  firstname?: string
  gender?: IKeyVal | string
  pResidenceTownObject?: ITown
  source?: string
  reference?: string
}

export interface IWarning {
  code: number
  message: string
  description: string
  externalCode: string
  correlationId?: string
}

export interface IOption {
  validations?: () => { string }
  mask?: string
  mandatory?: boolean
}

export interface IValidation {
  errorMessage: string
  mandatory: boolean
  mask: string
  regex: string | RegExp
  extra?: unknown[]
}

export interface IList {
  val: string
  label: string
  selected?: boolean
  allowReset?: boolean
}

export interface ITown extends IList {
  name?: string
  key?: string
  zip?: string
}

export interface IStreet extends IList {
  townNis?: string
  townZip?: string
  townPostkanton?: string
  streetCode?: string
}

export interface INationalty {
  key: number
  nationality: IList
  since: string
  until: string
  countryList?: IList[]
}

export interface IBrief {
  files: File[]
  services: IKeyVal[]
  deliveryTime: IKeyVal
  documentType: string
  otherDocumentType?: string
  addresseeAddress: IAddress
  ebox?: {
    subject: string
    inszType: IKeyVal
    insz: string
  } | null
  returnAddress: IAddress
}

export interface IPerson {
  lastname: string
  firstname: string[]
  nameSince: string

  birthdate: string
  birthCountry: IList
  birthPlace: string
  birthPlaceObject: ITown

  gender: IKeyVal
  genderSince: string

  nationalities: INationalty[]

  pResidenceCountry: IList
  pResidenceTown: string
  pResidenceTownObject: ITown
  pResidenceStreet: string
  pResidenceStreetObject: IList
  pResidenceNumber: string
  pResidenceBus: string
  pResidenceSince: string
  pResidenceZip: string

  contactTown: ITown
  contactStreet: IList
  contactStreetNumber: string
  contactStreetBus: string
  contactSince: string
  contactType: IList
  contactUntil: string

  maritalStatus: IMartitalStatus
  maritalInsz: string
  maritalSince: string
  maritalUntil: string
  maritalCountry: IList
  maritalTown: string
  maritalTownObject: ITown

  passingDate: string
  passingCountry: IList
  passingTown: string
  passingTownObject: ITown
}

export interface IMartitalStatus {
  label: string
  val: string
}
export interface ISendMail {
  naam: string
  email: string
  bevestigingsEmail?: string
  onderwerp: string
  inhoud: string
}

export interface IEboxActief {
  eboxType: number
  nummer: string
}

export interface IResponsePersonName {
  personen: IResponsePersonNamePersons[]
  waarschuwingen?: IResponseWarning[]
}

export interface IResponsePersonAddress {
  personen: IResponsePersonNamePersons[]
  waarschuwingen?: IResponseWarning[]
  totalAmount: number
}

export interface IResponsePersonNamePersons {
  insz: string
  voornaam: string[]
  achternaam: string[]
  geboortedatum: string
  nationaliteiten?: string[]
  register: string
  geslacht: string
  busnummer?: string
}

export interface IResponseWarning {
  code: number
  message: string
  description: string
  externalCode: string
  correlationId?: string
}

export interface ILoggingDetail {
  coOperator: {
    insz: string
    role: string
  }
  IKeyVal: string
  referte: string
  service: string
  timestamp: {
    answer: string
    request: string
  }
}

export interface ILogResponseDetail {
  antwoord?: {
    dienstVersie: string
    dienst: string
    gebruiker: string
    hoedanigheid: string
    referte: string
    sleutelwaarde: string[]
    transactieId: string
    uri: string
    wanneer: string
    fouten: Array<{
      identificatie: string
      oorsprong: string
      type: string
      diagnose: string
    }>
  }

  onbeantwoord?: {
    dienstVersie: string
    dienst: string
    gebruiker: string
    hoedanigheid: string
    referte: string
    transactieId: string
    uri: string
    wanneer: string
  }

  dienst: string
  gebruiker: string
  hoedanigheid: string
  referte: string
  sleutelwaarde: string[]
  transactieId: string
  uri: string
  wanneer: string
}

export interface ILogResponse {
  details: ILogResponseDetail[]
}

export interface ILogResponseSearch {
  currentPage: number
  pageCount: number
  rowCount: number
  vragen: ILogResponseDetail[]
}

export interface Iservice {
  naam: string
  versie: string
}

export interface IRole {
  character?: string
  full: string
  service?: string
  type?: RoleType
  uri?: string
  domains?: DomainType[]
  permissions?: any
  features?: any
  vaccinatie?: IVaccinationActiveRole
}

export enum DomainType {
  None = 'None',
  Person = 'Person',
  Logging = 'Logging',
  Reporting = 'Reporting',
  Messages = 'Messages',
  Led = 'Led',
  Mobility = 'Mobility',
  HealthAttestations = 'HealthAttestations',
  DocProvider = 'DocProvider',
  Company = 'Company',
  SocialSecurity = 'SocialSecurity',
  LandRegistry = 'LandRegistry',
  PushNotifications = 'PushNotifications',
  Family = 'Family',
}

export enum DateChoice {
  On = 0,
  Period = 1,
}

export enum DomainTypePermission {
  persoon = 'Person',
  logging = 'Logging',
  rapportering = 'Reporting',
  messages = 'Messages',
  led = 'Led',
  mobility = 'Mobility',
  healthattestations = 'HealthAttestations',
  ebox = 'DocProvider',
  soczek = 'SocialSecurity',
  kadaster = 'LandRegistry',
  pushnotifications = 'PushNotifications',
  gezin = 'Family',
  onderneming = 'Company',
}

export enum RoleType {
  Gebruiker = 0,
  Admin = 1,
  Reporter = 3,
  LegalLog = 4,
}

export interface IDomain {
  value: string
  label: string
  uri: string
}

export interface IServicesResponse {
  diensten: Iservice[]
  waarschuwingen: IWarning[]
}

export interface IRoleServicesResponse {
  role: string
  serviceResponse: IServicesResponse
}

export interface UserTokenset {
  access_token: string
  expireDate?: Date
  expires?: string
  id_token: string
  refresh_token?: string
  token_type: string
}

export interface GeoTokenset {
  access_token: string
  expireDate?: Date
  expires?: string
  scope?: string
  type: string
}

export interface ISearchParamsLegalLog {
  dateSince?: string
  dateUntil?: string
  keyval?: string
  page?: number
  referteId?: string
  service?: string
  transactionId?: string
  uris?: string[]
  user?: string
  offset?: number
  pageSize?: number
  character?: string
  sort?: string
}

export interface IValidationOptions {
  [key: string]: IValidationOption
}

export interface IValidationOption {
  mandatory?: boolean | string
  numeric?: boolean | string
  mask?: (string | RegExp)[]
  locale?: string
  validations?: (
    | ((value: string, element: HTMLFormElement) => string)
    | ((value: string[], element: HTMLFormElement) => string)
    | {
        func: (value: string, regex: RegExp, errorMessage: string) => string
        params: (string | RegExp)[]
      }
  )[]
}

export interface IUser {
  givenName: string
  familyName: string
  rrn: string
  sub: string
  doelgroepCode: string
  organisatieCode: string
  taalkeuze: string
  roles: IRole[]
  serviceDeskUrl?: string
  misconfiguredRoles: IRole[]
  hiddenRoles: IRole[]
  nisCode: string
}

export enum VaccinatieUriSoort {
  None = 0,
  Corona1700 = 1,
  LokaalBestuur = 2,
  VaccinatieCentrum = 3,
  AttestBeheer = 4,
  AttestCenter = 5,
  AttestSciensano = 6,
  AttestCenterBeheer = 7,
  AttestCenter_INSZ = 8,
  AttestCenterBeheer_INSZ = 9,
}

export enum CoronaUriSoort {
  None = 0,
  Corona1700 = 1,
  LokaalBestuur = 2,
  VaccinatieCentrum = 3,
  AttestBeheer = 4,
  AttestCenter = 5,
  AttestSciensano = 6,
  AttestCenterBeheer = 7,
  AttestCenter_INSZ = 8,
  AttestCenterBeheer_INSZ = 9,
}

export interface IActiveRole {
  geselecteerdeMagdaRol: string
  origineleMagdaRol: string
  vaccinatie: IVaccinationActiveRole
  type: RoleType
}

export interface IVaccinationActiveRole {
  isAttestCase: boolean
  isVaccinatieCase: boolean
  vaccinatieUriSoort: CoronaUriSoort
  vaccinatieCentra: Array<any>
  vaccinatiePostcodes: Array<any>
}

export interface ISource {
  dienst: string
  bronnen: string[]
}

export interface IState {
  currentActiveRole: IRole
  user: IUser
  isGetUserError: boolean
  expiresIn: string
}

export interface ISelectionItem {
  label: string
  val: string
  selected?: boolean
}

export interface ISearchParamsMessages {
  delivery?: Array<string>
  sentAfter?: string
  sentBefore?: string
  subject?: string
  reference?: string
  orderBy?: string
  order?: string
  take?: number
  skip?: number
  bestemmeling?: {
    naam?: string
    voornaam?: string
    insz?: string
    kbo?: string
    organisatie: string
    email: string
  }
  vaccinatie?: {
    vaccinatieCentrumRizivCode?: string
    postcode?: string
    vaccinatieCode?: string
  }
}

export interface ISearchMessagesResponse {
  totalAmount: number
  totalPages: number
  messages: Array<{
    delivery: number
    dateSent: string
    receiver: {
      lastName: string
      firstName: string
      organisationName: string
      citizenId: string
    }
    subject: string
    statusInfo: Array<IDocumentIdentifier>
  }>
}

export interface IDocumentStatus {
  date: string
  status: string
  comment: string
}

export interface IGetDocumentStatus {
  documents: Array<{
    messageId: string
    delivery: number
  }>
}

export interface IDocumentIdentifier {
  delivery: number
  messageId: string
}

export interface ISearchInszResult {
  insz: string
  data: ISearchInszResultData
}

export interface ISearchInszResultData {
  bewijzen: IProof[]
  waarschuwingen: IWarning[]
}

export interface IDienst {
  naam?: string | undefined
  versie?: string | undefined
}
