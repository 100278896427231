import { defineStore } from 'pinia'
import versionInfo from '../../versioninfo.json'

export enum HeaderType {
  Mopro = 'Mopro',
  Documentendienst = 'Documentendienst',
}

export const moproStore = defineStore('mopro', {
  state: () => {
    return {
      currentPageTitle: '',
      breadcrumb: '',
      showSessionTimeout: false,
      isPublic: false,
      currentGoBackRouteName: ''
    }
  },
  getters: {
    header: () => {
      return process.env.VUE_APP_MO_PRIVATE_HEADER
    },
    footer: () => {
      return process.env.VUE_APP_MO_PRIVATE_FOOTER
    },
    documentendienstHeader: () => {
      return process.env.VUE_APP_MO_PUBLIC_HEADER
    },
    documentendienstFooter: () => {
      return process.env.VUE_APP_MO_PUBLIC_FOOTER
    },
    sessionTimeout: (state) => {
      return state.showSessionTimeout
    },
    public: (state) => {
      return state.isPublic
    },
    pageTitle: (state) => {
      return state.currentPageTitle
    },
    selfRegistrationUserManagementUrl() {
      return process.env.VUE_APP_MO_SELF_REGISTRATION_USER_MANAGEMENT_URL
    },
    covidVaccinUrl() {
      return process.env.VUE_APP_MO_COVID_VACCIN_URL
    },
    versionNumber() {
      return versionInfo.version
    },
    environment() {
      return process.env.VUE_APP_CURRENT_ENVIRONMENT
    },
    geoSuggestionUrl() {
      return process.env.VUE_APP_GEO_SUGGESTION_URL
    },
    type: (): HeaderType => {
      if (location.host.indexOf('magda-documentendienst') > -1) {
        return HeaderType.Documentendienst
      } else {
        return HeaderType.Mopro
      }
    },
    goBackRouteName: (state) => {
      return state.currentGoBackRouteName
    }
  },
  actions: {
    setPageTitle(title) {
      this.currentPageTitle = title
    },
    sessionDismissed(show) {
      this.showSessionTimeout = show
    },
    setPublic(value) {
      this.isPublic = value
    },
    setGoBackRouteName(value){
      this.currentGoBackRouteName = value
    }
  },
})
