import { Component, Prop, Vue } from 'vue-property-decorator'
import { Location } from 'vue-router'

@Component
export default class MoAlert extends Vue {
  @Prop(Boolean)
  modError: boolean

  @Prop(Boolean)
  scrollTo: boolean

  @Prop(Boolean)
  modWarning: boolean

  @Prop(Boolean)
  modSuccess: boolean

  @Prop(String)
  icon: string

  @Prop({ default: '' })
  title: string

  @Prop({ default: '' })
  content: string

  @Prop(Function)
  onClose: () => unknown

  @Prop(String)
  decorator: string

  @Prop(String)
  classes: string

  @Prop(Object)
  functionLink: { message: string; callBack: () => unknown }

  @Prop(Array)
  buttons: Array<{
    text: string
    function: () => unknown
  }>

  get closable() {
    if (this.onClose) {
      return true
    }
    return false
  }

  safeFunction() {
    if (this.closable) {
      this.onClose()
    }
  }
}
