import Vue from 'vue'
import VueI18n from 'vue-i18n'

import nl from '@/i18n/nl-BE.json'
import en from '@/i18n/en-GB.json'

Vue.use(VueI18n)

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: 'nl',
  fallbackLocale: 'nl',
  fallbackRoot: false,
  messages: {
    nl,
    en,
  },
})

export default i18n
