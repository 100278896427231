var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showHeader
    ? _c(
        "div",
        { staticClass: "mo-environment-header", class: _vm.environment },
        [_c("span", [_vm._v(_vm._s(_vm.title))])]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }