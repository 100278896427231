import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validationMixin from '@/mixins/validation'
import { MoUploadFile } from './mo-upload-wrapper.model'
import Dropzone from 'dropzone'

@Component
export default class MoUploadWrapper extends mixins(validationMixin) {
  localValue = []
  @Prop(Object)
  dropzoneOptions: Record<string, unknown>

  @Prop()
  herzendingFiles: MoUploadFile[]

  get dropZoneRef(): Dropzone | any {
    return (this.$refs.upload as Vue).$refs.dropzoneRef
  }

  get dropzone() {
    return (this.dropZoneRef as any).dropzone
  }

  public filesChanged() {
    this.$nextTick(() => {
      this.localValue = this.dropzone.getAcceptedFiles()
      if (this.localValue?.length > 0) {
        this.validateInputFields()
      } else {
        this.reset()
      }
    })
  }

  public processQueue() {
    if (this.dropzone.getQueuedFiles().length > 0) {
      this.dropzone.processQueue()
    } else {
      const blob = new Blob()
      blob['upload'] = { chunked: this.dropzone.defaultOptions.chunking }
      this.dropzone.uploadFile(blob)
    }
  }

  @Watch('$attrs', { immediate: false, deep: true })
  onAttributesChanged() {}

  @Watch('herzendingFiles', { immediate: false, deep: true })
  onHerzendigAdded(val: MoUploadFile[]) {
    val.forEach((file) => {
      const f = {
        accepted: true,
        name: file.title,
        size: file.fileSize,
        herzending: true,
      }

      if (f.size) {
        this.dropzone.emit('addedfile', f)
        this.dropzone.files.push(f)
      }
    })
  }
  mounted() {
    if (!this.localValue) {
      this.localValue = []
      this.dropZoneRef.setOption('uploadMultiple', true)
      this.dropZoneRef.setOption('autoProcessQueue', false)
    }

    this.$nextTick(() => {
      if (this.modDisabled) {
        this.dropZoneRef.disable()
      } else {
        this.dropZoneRef.enable()
      }
    })
  }
}
