import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validationMixin from '@/mixins/validation'

@Component
export default class MoInputWrapper extends mixins(validationMixin) {
  @Prop({ default: 'normal' })
  type: string

  @Prop({ default: 'text' })
  inputType: string

  @Prop({ default: null })
  name: string

  @Prop({ default: null })
  id: string

  @Prop({ default: '' })
  value: string | string[]

  @Prop({ default: true })
  guide: boolean

  @Prop(String)
  placeholderChar: string

  @Prop(String)
  autocomplete: string

  @Prop({ default: false })
  showMask: boolean

  @Prop({ default: false })
  modDisabledKeep: boolean

  @Prop({ default: false })
  modBlock: boolean

  @Prop()
  fields: string[]

  @Watch('modDisabled')
  onDisabledChange(newValue, _oldValue) {
    if (newValue) {
      this.localValue = ''
    }
  }
}
