import { getLocale, ISupportedLocale } from '@/infrastructure/i18n/locales'
import { NavigationGuardNext, Route } from 'vue-router'
import router from '@/infrastructure/router'
import { localeStore } from '@/store/locale'
import { userStore } from '@/store/user'
export default function (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const app = router.app
  const locale = resolveLocale(to)
  const localeParam = to?.params?.locale ? to.params.locale : ''
  const store = localeStore()
  store.setLocale({ locale, app })
  store.setLocaleParam(localeParam)

  next()
}

function resolveLocale(to: Route): ISupportedLocale {
  let locale: ISupportedLocale = null
  const store = userStore()
  // gets the locale based on the user language
  const userLanguage = store.language
  if (userLanguage) {
    locale = getLocale(userLanguage)
  }
  
  // gets the locale based on the route parameter
  if (to.params.locale) {
    locale = getLocale(to.params.locale)
  }

  // gets the default locale as fallback
  if (!locale) {
    locale = getLocale()
  }

  return locale
}
