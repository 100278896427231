import { AxiosError } from 'axios'
import { IWarning } from '@/declarations/references.models'
import { FriendlyErrorCodes } from '@/libs/constants'
import { BuildGeneralErrorObject } from '@/libs/errorhandling'
import ErrorMessageMapper from '../libs/errorMessageMapper'
import { sendLog } from '../plugins/api'
import { pageStore } from '@/store/page'
export interface AlertButton {
  function: () => any
  text: string
}

export interface AlertParams {
  title?: string
  content?: string
  closeable?: boolean
  type?: string
  onClose?: () => any
  buttons?: AlertButton[]
  id?: string | number
  pageName: string
  errorObject?: any
  warnings?: IWarning[]
  correlationId?: string
  scrollTo?: boolean
  sourceMethod: string
  sourceFile: string
}

export interface ValidationError {
  code: number
  message: string
  errors?: Array<{
    code: number
    description: string
    message: string
  }>
}

export interface MagdaError {
  code: number
  message: string
  errors?: Array<{
    code: number
    description: string
    externalCode: string
    message: string
    serviceDeskUrl?: string
  }>
  description?: string
  serviceDeskUrl?: string
}

export class Alert {
  private axios
  private store
  public constructor(app) {
    this.axios = app.$axios
    this.store = pageStore()
  }

  private setAlertId(pageName: string): number {
    const currentAlerts = this.store.alerts(pageName)
    if (!currentAlerts.length) {
      return 1
    } else {
      return Math.max(...currentAlerts.map((alert) => alert.id)) + 1
    }
  }

  public updateWarning(params: AlertParams) {
    params.id = this.setAlertId(params.pageName)
    this.removeAlert({
      pageName: params.pageName,
      id: parseInt(params.id.toString()) - 1,
    })
    params.type = 'warning'
    params = this.parseError(params)
    this.baseAlert(params)
  }

  public updateError(params: AlertParams, removeAlerts = true) {
    params.id = this.setAlertId(params.pageName)
    if (removeAlerts) {
      this.removeAlert({
        pageName: params.pageName,
        id: parseInt(params.id.toString()) - 1,
      })
    }
    params.type = 'error'
    params = this.parseError(params)
    this.baseAlert(params)
  }

  public updateSuccess(params: AlertParams, removeAlerts = true) {
    params.id = this.setAlertId(params.pageName)
    if (removeAlerts) {
      this.removeAlert({
        pageName: params.pageName,
        id: parseInt(params.id.toString()) - 1,
      })
    }
    params.type = 'success'
    this.baseAlert(params)
  }

  public updateNormal(params: AlertParams) {
    this.baseAlert(params)
  }

  public removeAlert(params: { pageName?: string; id: string | number }) {
    this.store.removeAlerts(params)
  }

  public removeAllAlerts(params: { pageName?: string }) {
    this.store.removeAllAlerts(params)
  }

  public getMagdaError(error: AxiosError<any>): {
    code?: number
    message?: string
    description?: string
    externalCode: string
  }[] {
    if (error.response && error.response.data && error.response.data.code === 4) {
      return error.response.data.errors
    } else {
      return []
    }
  }

  private baseAlert(params: AlertParams) {
    params.scrollTo = true
    if (params.closeable === undefined || params.closeable === null) {
      params.closeable = true
    }
    if (params.closeable) {
      const onCloseExtend = params.onClose
      params.onClose = () => {
        this.removeAlert({ pageName: params.pageName, id: params.id })
        if (onCloseExtend) {
          onCloseExtend()
        }
      }
    }

    params.errorObject = null

    sendLog(this.axios, {
      context: 'Alert',
      error: params,
      file: '/plugins/mo-alert.ts',
      method: 'baseAlert',
      impact: 'INFO',
      severity: 'MEDIUM',
      transactionId: this.store.sub,
    })

    this.store.setAlerts(params)
  }

  public parseError(params: AlertParams): AlertParams {
    if (params.errorObject) {
      const error = params.errorObject

      if (error.response && error.response.data) {
        switch (error.response.data.code) {
          case 1: {
            // eslint-disable-next-line
            const validationError: ValidationError = error.response.data
            if (validationError.errors) {
              params.content = validationError.errors.map((subError) => subError.message).join('<br/>')
            } else {
              params.content = validationError.message
            }
            if (!params.title) {
              params.title = 'Validatiefout'
            }
            break
          }
          case 4: {
            const magdaError: MagdaError = error.response.data
            const correlationId = error.response.headers['x-correlation-id']
            let content: string

            if (magdaError.errors && magdaError.errors.length > 0) {
              if (magdaError.errors.length == 1 && magdaError.errors[0].serviceDeskUrl) {
                const generalError = BuildGeneralErrorObject({
                  correlationId,
                  serviceDeskUrl: magdaError.errors[0].serviceDeskUrl,
                  errorTitle: magdaError.message,
                  message: magdaError.errors[0].message,
                  errorBodyCode: magdaError.errors[0].code,
                })
                content = `${generalError.description}`
              } else {
                const messages = magdaError.errors
                  .map((subError) => {
                    if (subError.description && subError.description !== subError.message) {
                      return subError.description
                    }
                    return subError.message
                  })
                  .filter((message, index, self) => {
                    return index === self.indexOf(message)
                  })
                  .join('<br/>')
                content = `${messages}`
              }
            } else {
              const generalError = BuildGeneralErrorObject({
                correlationId,
                serviceDeskUrl: magdaError.serviceDeskUrl,
              })
              content = `${generalError.description}`
            }

            if (!params.title) {
              params.title = magdaError.message
            }

            params.content = `${content}${this.getReferentieString(correlationId)}`
            break
          }
          default: {
            const { title, content } = ErrorMessageMapper.getError(error.response)
            if (!params.title) {
              params.title = title
            }
            params.content = content
            break
          }
        }
      } else if (error.response) {
        const { title, content } = ErrorMessageMapper.getError(error.response)
        if (!params.title) {
          params.title = title
        }
        params.content = content
      } else {
        if (!params.title) {
          params.title = error.name
        }
        params.content = `${error.message}<br>${error.stack ? '<br>' + error.stack : ''}`
      }
    } else if (params.warnings && params.warnings.length > 0) {
      const correlationId = params.correlationId

      if (params.warnings.length === 1) {
        let msg
        const waarschuwing = params.warnings[0]
        if (this.doesWarningNeedServiceDeskUrl(waarschuwing)) {
          const generalError = BuildGeneralErrorObject({
            correlationId: waarschuwing.correlationId,
            errorTitle: 'Magda waarschuwing',
            message: waarschuwing.message,
          })
          msg = generalError.description
        } else {
          msg = waarschuwing.description
        }
        params.content = msg
      } else {
        params.content =
          '<ul>' +
          params.warnings
            .map((waarschuwing) => {
              let msg
              if (this.doesWarningNeedServiceDeskUrl(waarschuwing)) {
                const generalError = BuildGeneralErrorObject({
                  correlationId: waarschuwing.correlationId,
                  errorTitle: 'Magda waarschuwing',
                  message: waarschuwing.message,
                })
                msg = generalError.description
              } else {
                msg = waarschuwing.description
              }
              return `<li>Fout ${waarschuwing.externalCode}/${waarschuwing.code}: "${msg}"</li>`
            })
            .join('') +
          '</ul>'
      }

      if (correlationId) {
        params.content += this.getReferentieString(correlationId)
      }
    }

    return params
  }

  private getReferentieString(correlationId) {
    return correlationId ? `<br /> referentie: ${correlationId}` : ''
  }

  private doesWarningNeedServiceDeskUrl(warning: IWarning) {
    const codes = [
      FriendlyErrorCodes.MagdaSysteemFout,
      FriendlyErrorCodes.MagdaExtraInformatieWaarschuwing,
      FriendlyErrorCodes.MagdaFoutToegangTotBron,
      FriendlyErrorCodes.MagdaOnverwachteFout,
    ]
    return warning && warning.correlationId && codes.some((code) => warning.code === code)
  }
}

export default ({ app }, inject) => {
  inject('alert', new Alert(app))
}
