var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vl-form-grid",
        { staticClass: "vl-u-spacer--medium", attrs: { "mod-stacked": "" } },
        [
          _c(
            "vl-column",
            { attrs: { width: "2", "width-m": "3", "width-s": "5" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Aanspreking" } },
                [
                  _c("mo-input-wrapper", {
                    attrs: {
                      options: _vm.options.prefix,
                      autocomplete: _vm.modAutocomplete
                        ? "honorific-prefix"
                        : null,
                      "mod-block": "",
                    },
                    model: {
                      value: _vm.prefix,
                      callback: function ($$v) {
                        _vm.prefix = $$v
                      },
                      expression: "prefix",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("vl-column", {
            attrs: { width: "10", "width-m": "9", "width-s": "7" },
          }),
          _c(
            "vl-column",
            { attrs: { width: "5", "width-m": "8", "width-s": "12" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Naam" } },
                [
                  _c("mo-input-wrapper", {
                    attrs: {
                      id: _vm.fieldRef("lastName"),
                      name: _vm.fieldRef("lastName"),
                      options: _vm.options.name,
                      autocomplete: _vm.modAutocomplete ? "family-name" : null,
                      "mod-block": "",
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("vl-column", {
            staticClass: "vl-u-hidden--xs",
            attrs: { width: "7", "width-m": "4" },
          }),
          _c(
            "vl-column",
            { attrs: { width: "5", "width-m": "8", "width-s": "12" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Voornamen" } },
                [
                  _c("mo-input-wrapper", {
                    attrs: {
                      id: _vm.fieldRef("firstName"),
                      name: _vm.fieldRef("firstName"),
                      options: _vm.options.firstnames,
                      autocomplete: _vm.modAutocomplete ? "given-name" : null,
                      "mod-block": "",
                    },
                    model: {
                      value: _vm.firstnames,
                      callback: function ($$v) {
                        _vm.firstnames = $$v
                      },
                      expression: "firstnames",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("vl-column", {
            staticClass: "vl-u-hidden--xs",
            attrs: { width: "7", "width-m": "4" },
          }),
          _c(
            "vl-column",
            { attrs: { width: "5", "width-m": "8", "width-s": "12" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Organisatie" } },
                [
                  _c("mo-input-wrapper", {
                    attrs: {
                      id: _vm.fieldRef("organisation"),
                      name: _vm.fieldRef("organisation"),
                      options: _vm.options.organisation,
                      autocomplete: _vm.modAutocomplete ? "organization" : null,
                      "mod-block": "",
                    },
                    model: {
                      value: _vm.organisation,
                      callback: function ($$v) {
                        _vm.organisation = $$v
                      },
                      expression: "organisation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("vl-column", {
            staticClass: "vl-u-hidden--xs",
            attrs: { width: "7", "width-m": "4" },
          }),
        ],
        1
      ),
      _c(
        "vl-form-grid",
        { attrs: { "mod-stacked": "" } },
        [
          _c(
            "vl-column",
            { attrs: { width: "2", "width-m": "6", "width-xs": "12" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Land" } },
                [
                  _c(
                    "mo-select",
                    {
                      attrs: {
                        items: _vm.countryList,
                        options: _vm.options.country,
                        disabled: "",
                      },
                      model: {
                        value: _vm.country,
                        callback: function ($$v) {
                          _vm.country = $$v
                        },
                        expression: "country",
                      },
                    },
                    _vm._l(_vm.countryList, function (country) {
                      return _c(
                        "option",
                        { key: country.val, domProps: { value: country.val } },
                        [_vm._v(" " + _vm._s(country.label) + " ")]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vl-column",
            { attrs: { width: "6", "width-m": "6", "width-xs": "12" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Gemeente" } },
                [
                  _c("mo-select-wrapper", {
                    ref: "focusElement",
                    attrs: {
                      options: _vm.options.town,
                      "mod-disabled-keep": _vm.searching,
                      items: _vm.townList,
                      "empty-message":
                        "De lijst wordt geladen na invoer van 2 karakters",
                    },
                    on: {
                      filterChange: function ($event) {
                        return _vm.updateTownList($event)
                      },
                    },
                    model: {
                      value: _vm.townObject,
                      callback: function ($$v) {
                        _vm.townObject = $$v
                      },
                      expression: "townObject",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("vl-column", {
            staticClass: "vl-u-hidden--xs vl-u-hidden--s vl-u-hidden--m",
            attrs: { width: "4" },
          }),
          _c(
            "vl-column",
            { attrs: { width: "4", "width-m": "6", "width-xs": "12" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Straat" } },
                [
                  _c("mo-select-wrapper", {
                    attrs: {
                      options: _vm.options.street,
                      items: _vm.streetList,
                      "empty-message":
                        "De lijst wordt geladen na invoer van 2 karakters",
                      "mod-disabled": !_vm.townObject || !_vm.townObject.val,
                      "timeout-length": 250,
                      "mod-disabled-keep": _vm.searching,
                    },
                    on: {
                      filterChange: function ($event) {
                        return _vm.updateStreetList($event)
                      },
                    },
                    model: {
                      value: _vm.streetObject,
                      callback: function ($$v) {
                        _vm.streetObject = $$v
                      },
                      expression: "streetObject",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vl-column",
            { attrs: { width: "2", "width-m": "3", "width-xs": "6" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Nummer" } },
                [
                  _c("mo-input-wrapper", {
                    attrs: {
                      options: _vm.options.streetNumber,
                      "mod-disabled": !_vm.streetObject,
                      "mod-disabled-keep": _vm.searching,
                      "mod-block": "",
                    },
                    model: {
                      value: _vm.streetNumber,
                      callback: function ($$v) {
                        _vm.streetNumber = $$v
                      },
                      expression: "streetNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "vl-column",
            { attrs: { width: "2", "width-m": "3", "width-xs": "6" } },
            [
              _c(
                "mo-formfield-decorator",
                { attrs: { label: "Bus" } },
                [
                  _c("mo-input-wrapper", {
                    attrs: {
                      options: _vm.options.streetNumberBox,
                      "mod-disabled": !_vm.streetNumber,
                      "mod-disabled-keep": _vm.searching,
                      "mod-block": "",
                    },
                    model: {
                      value: _vm.streetNumberBox,
                      callback: function ($$v) {
                        _vm.streetNumberBox = $$v
                      },
                      expression: "streetNumberBox",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("vl-column", {
            staticClass: "vl-u-hidden--xs vl-u-hidden--s vl-u-hidden--m",
            attrs: { width: "6" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }