import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import store from '@/infrastructure/store'
import { featureToggleStore } from '@/store/feature-toggle'

interface IMultiGuardOptions {
  enableLogging: boolean
}
interface IGuardDictionary {
  [key: string]: NavigationGuard
}

type AfterNavigationGuard = (to: Route, from: Route) => any

interface IAfterGuardDictionary {
  [key: string]: AfterNavigationGuard
}

function isUndefined(value: unknown) {
  return value === undefined
}

function evaluateGuards(guards: IGuardDictionary, opts: IMultiGuardOptions, to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const guardsLeft = { ...guards } // clone the array so we do not accidentally modify it
  const name = Object.keys(guardsLeft)[0] // gets the name of the guard
  const nextGuard = name ? guardsLeft[name] : undefined // take the first guard from the dictionary to execute
  delete guardsLeft[name] // remove the item from the dictionary to pass on to the next guard

  const options = opts || createDefaultOptions()

  if (isUndefined(nextGuard)) {
    next()
    return
  }

  if (options.enableLogging) {
    console.log(`entering before guard '${name}' for route '${to.name}: ${to.path}'`)
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, options, to, from, next)
      return
    }

    if (options.enableLogging) {
      console.log(`leaving before guard '${name}' redirecting to '${nextArg}'`)
    }

    next(nextArg)
  })
}

export function multiBeforeGuard(to: Route, from: Route, next: NavigationGuardNext<Vue>, guards: IGuardDictionary, options: IMultiGuardOptions = null) {
  if (!guards || Object.keys(guards).length <= 0) {
    throw new Error('You must specify an object of guards')
  }
  return evaluateGuards(guards, options, to, from, next)
}

export function multiAfterGuard(to: Route, from: Route, afterGuards: IAfterGuardDictionary) {
  const options = createDefaultOptions()

  Object.keys(afterGuards).forEach((key) => {
    const guard = afterGuards[key]
    if (options.enableLogging) {
      console.log(`entering after guard '${key}' for route '${to.name}: ${to.path}'`)
    }
    guard(to, from)
  })
}

export function createDefaultOptions(): IMultiGuardOptions {
  const isRouteGuardLoggingActive = featureToggleStore().isRouteGuardLoggingActive
  return {
    enableLogging: isRouteGuardLoggingActive ? true : false,
  }
}
