var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "vl-u-align-center" }, [
      _c("div", {
        staticClass: "vl-loader",
        attrs: { role: "alert", "aria-busy": "true" },
      }),
      _c("p", [
        _vm._v(" " + _vm._s(_vm.title) + " "),
        _c("strong", [_vm._v(_vm._s(_vm.searchValue))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }