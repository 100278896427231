import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { regularExpression, textStrict } from '@/assets/validations'
import { setPostcodeGemeente, setNiscodeGemeente } from './mo-address-autoform.mappings'
import MoAutocompleteWrapper from '@/components/shared/mo-autocomplete-wrapper/mo-autocomplete-wrapper.vue'
import { IMoAddress } from './mo-address-auto-form.model'
import { IZoekGemeentenItem } from '@/api/mopro-api.dto'
import { debounce } from 'lodash'
import { IList, IValidationOptions } from '@/declarations/references.models'

@Component({
  components: {
    MoAutocompleteWrapper,
  },
})
export default class MoAddressAutoForm extends Vue {
  address: IMoAddress = {
    organisatie: '',
    aanspreking: '',
    voornaam: '',
    naam: '',
    postcode: '',
    gemeente: '',
    straat: '',
    nummer: '',
    bus: '',
    lijn2: '',
    regio: '',
    land: '',
  }
  minAutocompleteLength: number = 3

  land: IList = null
  landenList: IList[] = []

  straatDataFetching: boolean = false
  straatData = []
  debounceStraatSearch = null
  straatSelected = false
  postcode_gemeente: string = ''
  gemeenteDataFetching: boolean = false
  gemeenteData = []
  debounceGemeenteSearch = null
  gemeenteSelected = false
  hasMounted = false
  niscode_gemeente: string = ''

  options: IValidationOptions = {
    aanspreking: {
      mandatory: false,
    },
    naam: {
      mandatory: false,
      validations: [
        textStrict,
        {
          func: regularExpression,
          params: [/^.{0,128}$/, 'Achternaam mag maximum 128 karakters lang zijn'],
        },
      ],
    },
    voornaam: {
      mandatory: false,
      validations: [
        textStrict,
        {
          func: regularExpression,
          params: [/^.{0,48}$/, 'Voornaam mag maximum 48 karakters lang zijn'],
        },
      ],
    },
    organisatie: {
      mandatory: false,
    },
    land: {
      mandatory: false,
    },
    postcode_gemeente: {
      mandatory: true,
    },
    straat: {
      mandatory: true,
    },
    nummer: {
      mandatory: false,
    },
    bus: {
      mandatory: false,
    },
    regio: {
      mandatory: false,
    },
    lijn2: {
      mandatory: false,
    },
    postcode: {
      mandatory: false,
    },
    gemeente: {
      mandatory: true,
    },
  }

  async mounted() {
    if (this.$attrs.value) {
      this.address = this.$attrs.value as unknown as IMoAddress
    } else {
      this.address = this.createEmptyAddress()
    }

    this.initDebouncers()
    await this.loadLanden()

    if (this.prefillValue) {
      this.setPrefillData(this.prefillValue)
    }

    this.hasMounted = true
  }

  get isForeignCountry(): boolean {
    return this.land && this.land.val !== 'BE'
  }
  fieldRef(value: string) {
    return `${this.namespace}_${value}`
  }

  @Prop(Boolean)
  modAutocomplete: boolean

  @Prop({ default: false, type: Boolean, required: false })
  readOnly: boolean

  @Prop({ default: '', type: String, required: true })
  namespace: string

  @Prop({ required: false })
  prefillValue: IMoAddress

  @Prop({ required: false, default: true })
  showPreview: boolean

  @Watch('address', { immediate: true, deep: true })
  public onAddressChanged(value: Record<string, unknown>) {
    this.$emit('input', value)
  }

  @Watch('land', { immediate: true, deep: true })
  public onLandChanged() {
    if (this.address) {
      this.address.land = this.land?.val
    }
  }

  @Watch('$attrs.value')
  public onValueChanged(val: Record<string, unknown>) {
    if (val) {
      this.address = val as unknown as IMoAddress
      this.postcode_gemeente = !this.isForeignCountry ? this.setPostcodeGemeenteFromAddress() : null
    }
  }

  @Watch('prefillValue', { immediate: true, deep: true })
  public onPrefill(val: IMoAddress) {
    // when mounting occurs the countries list is fetched from the server, and the prefillData is set automatically.
    // when the prefillValue changes after mount the form will be updated here.
    if (val && this.hasMounted) {
      this.setPrefillData(val)
    }
  }

  @Watch('landenList')
  public landenListChanged() {
    if (this.landenList && this.prefillValue) {
      this.setSelectedLandenList()
    }
  }

  get autocompleteActive() {
    return this.land && this.land.val === 'BE'
  }

  get volledigeNaam(): string {
    let naam = `${this.address?.aanspreking ? `${this.address.aanspreking} ` : ''}`
    if (this.address.voornaam) {
      naam += `${this.address?.voornaam ? `${this.address.voornaam} ` : ''}`
    }
    if (this.address.naam) {
      naam += `${this.address?.naam ? `${this.address.naam}` : ''}`
    }
    return naam.trim()
  }

  get formWidth() {
    return this.showPreview ? '8' : '12'
  }

  async onGemeenteInputChange() {
    if (!this.isForeignCountry) {
      const postcode_gemeente = this.postcode_gemeente.trim()
      const postcode = postcode_gemeente.substr(0, postcode_gemeente.indexOf(' ')).trim()
      const gemeente = postcode_gemeente.substr(postcode_gemeente.indexOf(' ') + 1).trim()
      this.address = setPostcodeGemeente(postcode + '|' + gemeente, this.address)

      if (this.autocompleteActive && !this.gemeenteSelected && postcode_gemeente.length >= this.minAutocompleteLength) {
        this.debounceGemeenteSearch()
      }
      this.gemeenteSelected = false
    }
  }

  onGemeenteSelect(gemeente: string) {
    if (!this.isForeignCountry) {
      this.gemeenteSelected = true
      this.address = setPostcodeGemeente(gemeente, this.address)
      this.niscode_gemeente = setNiscodeGemeente(gemeente)
      this.postcode_gemeente = `${this.address?.postcode ? `${this.address.postcode}` : ''}${this.address?.gemeente ? ` ${this.address.gemeente}` : ''} `.trim()
      ;(((this.$refs.straat as Vue).$refs.inputField as Vue).$el as HTMLElement).focus()
    }
  }

  async onStraatInputChange() {
    if (this.autocompleteActive && !this.straatSelected && this.address.straat.length >= this.minAutocompleteLength) {
      this.debounceStraatSearch()
    }
    this.straatSelected = false
  }

  onStraatSelect(adres: IMoAddress) {
    if (this.address) {
      this.straatSelected = true
      this.address.straat = adres.straat
      this.address.nummer = adres.nummer
      if (!adres.nummer) {
        ;((this.$refs.nummer as Vue).$el as HTMLElement).focus()
      } else if (!adres.bus) {
        ;((this.$refs.bus as Vue).$el as HTMLElement).focus()
      }
    }
  }

  private setPrefillData(prefillValue: IMoAddress) {
    this.gemeenteSelected = true
    this.straatSelected = true
    this.address = this.setAddressFromPrefillValue(prefillValue)
    this.land = this.landenList.filter((l) => l.val.toLowerCase() === this.setLandFromAddress().toLowerCase())[0]

    if (!this.isForeignCountry) this.postcode_gemeente = this.setPostcodeGemeenteFromAddress()
  }

  private createEmptyAddress(): IMoAddress {
    const address = {
      organisatie: '',
      aanspreking: '',
      voornaam: '',
      naam: '',
      postcode: '',
      gemeente: '',
      straat: '',
      nummer: '',
      bus: '',
      lijn2: '',
      regio: '',
      land: '',
    }
    return address
  }

  private async loadLanden() {
    const selectedCountry = this.address?.land
    const countries = await this.$api.getIsoCountries()
    this.landenList = countries.map((l) => {
      return {
        val: l.isoCode,
        label: l.land,
        selected: selectedCountry ? l.isoCode === selectedCountry : l.isoCode === 'BE',
      }
    })
    this.land = this.landenList.filter((l) => l.selected)[0]
  }

  private initDebouncers() {
    this.debounceGemeenteSearch = debounce(async () => {
      const postcode_gemeente = this.postcode_gemeente.trim()
      if (postcode_gemeente) {
        this.gemeenteDataFetching = true
        const gemeentes = await this.$api.searchGemeentes(postcode_gemeente)
        this.gemeenteData = gemeentes.map((gemeente: IZoekGemeentenItem) => {
          return {
            title: `${gemeente.postCode ? `${gemeente.postCode}` : ''}${gemeente.gemeente ? ` ${gemeente.gemeente}` : ''} `,
            value: `${gemeente.postCode ? `${gemeente.postCode}|` : ''}${gemeente.gemeente ? `${gemeente.gemeente}|` : ''}${
              gemeente.nisCode ? `${gemeente.nisCode}|` : ''
            } `,
          }
        })
        this.gemeenteDataFetching = false
      }
    }, 600)

    this.debounceStraatSearch = debounce(async () => {
      const postcode_gemeente = this.postcode_gemeente.trim()
      const straat = this.address.straat.trim()
      const locatie = `${straat} ${postcode_gemeente}`.trim()
      const gemeenteNis = this.niscode_gemeente?.trim()

      if (locatie && gemeenteNis) {
        this.straatDataFetching = true
        const response = await this.$api.searchLocation(locatie, gemeenteNis)
        this.straatData = response.map((s) => {
          return {
            title: `${s.adres.straat} ${s.adres.nummer ? ` ${s.adres.nummer}` : ''} ${s.adres.bus ? ` ${s.adres.bus}` : ''} `,
            subtitle: s.adres.gemeente,
            value: s.adres,
          }
        })
        this.straatDataFetching = false
      }
    }, 600)
  }

  private setAddressFromPrefillValue(address: IMoAddress) {
    return {
      organisatie: address.organisatie ? address.organisatie : this.address?.organisatie,
      aanspreking: address.organisatie ? address.aanspreking : this.address?.aanspreking,
      voornaam: address.voornaam ? address.voornaam : this.address?.voornaam,
      naam: address.naam ? address.naam : this.address?.naam,
      postcode: address.postcode ? address.postcode : this.address?.postcode,
      gemeente: address.gemeente ? address.gemeente : this.address?.gemeente,
      straat: address.straat ? address.straat : this.address?.straat,
      nummer: address.nummer ? address.nummer : this.address?.nummer,
      bus: address.bus ? address.bus : this.address?.bus,
      regio: address.regio ? address.regio : this.address?.regio,
      land: address.land ? address.land : 'BE',
      lijn2: address.lijn2 ? address.lijn2 : this.address?.lijn2,
    }
  }

  private setPostcodeGemeenteFromAddress() {
    return `${this.address?.postcode ? `${this.address.postcode}` : ''}${this.address?.gemeente ? ` ${this.address.gemeente}` : ''}`.trim()
  }

  private setLandFromAddress() {
    return `${this.address?.land ? `${this.address.land}` : 'BE'}`
  }

  private setSelectedLandenList() {
    if (!this.landenList) {
      return
    }

    const landCode = this.prefillValue.land
    if (!landCode) {
      return
    }

    const land = this.landenList.filter((l) => l.label.toLowerCase() === landCode.toLowerCase())[0]
    if (!land) {
      return
    }

    this.land = land
  }
}
