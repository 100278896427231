import { configureGlobalHeaderAndFooter } from '@/infrastructure/widgets/global-header-and-footer'
import { userStore } from '@/store/user'
import { NavigationGuardNext, Route } from 'vue-router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default async function (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const isPublic = false
  const store = userStore()

  const isAuthenticated = store.exists

  if (!isPublic && !isAuthenticated) {
    await store.init()
  }

  await configureGlobalHeaderAndFooter()

  next()
}
