import Vue from 'vue'
import MoClickOutside from './mo-click-outside'

Vue.directive('scroll', {
  inserted(el, binding) {
    if (binding.value !== false) {
      el.scrollIntoView(true)
    }
  },
})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.directive('MoClickOutside', MoClickOutside)