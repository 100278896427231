export interface ISupportedLocale {
  code: string
  base: string
  flag: string
  name: string
}

export const SUPPORTED_LOCALES: ISupportedLocale[] = [
  {
    code: 'nl',
    base: '',
    flag: 'nl',
    name: 'Nederlands',
  },
  {
    code: 'en',
    base: '',
    flag: 'en',
    name: 'English',
  },
]

export function getLocale(locale = defaultLocale) {
  return SUPPORTED_LOCALES.find((loc) => loc.code === locale?.toLowerCase())
}

export function getLocaleRegex() {
  let reg = ''
  SUPPORTED_LOCALES.forEach((locale, index) => {
    reg = `${reg}${locale.code}${index !== SUPPORTED_LOCALES.length - 1 ? '|' : ''}`
  })
  return `(${reg})`
}

export const defaultLocale = 'nl'
