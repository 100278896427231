import { Component, Prop } from 'vue-property-decorator'
import Quill from 'quill'
import { mixins } from 'vue-class-component'
import validationMixin from '@/mixins/validation'

@Component
export default class MoTextEditorWrapper extends mixins(validationMixin) {
  @Prop(String)
  value: string

  get customToolbar() {
    return [[{ header: [false, 1, 2, 3, 4, 5, 6] }], ['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']]
  }

  get inputValue(): string {
    return this.value
  }

  set inputValue(newValue: string) {
    this.$emit('input', newValue)
  }

  onTextChange() {
    const quill = (this.$refs.notificationEditor as any).quill as Quill

    //Prevent the copy and paste in the editor for image|video|script tags. Remove the inputted text when typing manually
    const inputtedText = quill.getText()
    const imgRegex = new RegExp(/(?:<img[^>]+src=\")([^"]+)/g)
    const videoRegex = new RegExp(/(?:<video[^>]+src=\")([^"]+)/g)
    const scriptRegex = new RegExp(/<script.*?>[\s\S]*<\/script>/i)
    const matchImg = imgRegex.test(inputtedText)
    const matchVideo = videoRegex.test(inputtedText)
    const matchScript = scriptRegex.test(inputtedText)
    if (matchImg) {
      quill.deleteText(0, inputtedText.length)
    } else if (matchVideo) {
      quill.deleteText(0, inputtedText.length)
    } else if (matchScript) {
      quill.deleteText(0, inputtedText.length)
    }

    const inputLen = quill.getLength()
    const limit = 993 /*limit minus 7 characters of standard <p></p> tag*/
    if (inputLen >= limit) {
      quill.deleteText(limit, inputLen)
    }
  }
}
