import { userStore } from '@/store/user'

export default class UserLocalStorage {
  constructor() {}
  public getItem(key: string, isObject: boolean = false) {
    const value = localStorage.getItem(this.unique(key))
    if (!isObject) {
      return value
    }
    return JSON.parse(value)
  }

  public setItem(key: string, value: any, isObject: boolean = false): void {
    let serialized = value
    if (isObject) {
      serialized = JSON.stringify(value)
    }
    localStorage.setItem(this.unique(key), serialized)
  }

  public appendItem(key: string, value: any, isObject: boolean = false): void {
    let serialized = value
    if (isObject) {
      const newValue = Object.assign(this.getItem(key, true) || {}, value)
      serialized = JSON.stringify(newValue)
    }
    localStorage.setItem(this.unique(key), serialized)
  }

  public appendItemToArray(key: string, value: any): void {
    let storedValue: string[] = this.getItem(key, true) as []
    if (!storedValue) {
      storedValue = []
    }
    storedValue.push(value)

    const serialized = JSON.stringify(storedValue)
    localStorage.setItem(this.unique(key), serialized)
  }

  public removeItem(key: string): void {
    localStorage.removeItem(this.unique(key))
  }

  private unique(key: string) {
    return `${key}_${this.getHashedRrn()}`
  }

  private getHashedRrn(): string {
    const id = userStore().hashedRrn
    if (id) {
      return id
    }
    return ''
  }
}
