import flatpickr from 'flatpickr'
import Dutch from 'flatpickr/dist/l10n/nl.js'
import { Instance } from 'flatpickr/dist/types/instance'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { defaultLocale } from '@/libs/translate'

@Component
export default class MoDatePicker extends Vue {
  @Prop({ default: 'text' })
  type: string

  @Prop({ default: undefined })
  mask: string[]

  @Prop({ default: true })
  showMask: boolean

  @Prop({ default: false })
  guide: boolean

  @Prop({ default: ' ' })
  placeholderChar: string

  @Prop(String)
  placeholder: string

  @Prop({ default: 'div' })
  tagName: string

  @Prop({ default: 'd.m.Y' })
  format: string

  @Prop(String)
  minDate: string

  @Prop(String)
  maxDate: string

  @Prop({ default: defaultLocale })
  locale: string

  @Prop(Boolean)
  disableDate: boolean

  @Prop(Boolean)
  noCalendar: boolean

  @Prop(String)
  defaultDate: string

  @Prop(String)
  defaultTime: string

  @Prop({ type: Number, required: false, default: 0 })
  defaultHour: number

  @Prop({ type: Number, required: false, default: 0 })
  defaultMinute: number

  @Prop({ type: Boolean, required: false, default: false })
  enableTime: boolean

  @Prop({ default: true })
  timeTwentyfour: boolean

  @Prop(String)
  maxTime: string

  @Prop(String)
  minTime: string

  @Prop({ default: '' })
  addonText: string

  @Prop({ default: '' })
  addonTooltip: string

  @Prop(String)
  value: string

  @Prop(Boolean)
  modError: boolean

  @Prop(Boolean)
  modDisabled: boolean

  @Prop(Boolean)
  modSmall: boolean

  @Prop(Boolean)
  modInline: boolean

  @Prop(Boolean)
  modBlock: boolean

  flatpickr: Instance
  selectedDates: Date[] = []
  selectedDate: string = ''

  settings = {
    allowInput: true,
    clickOpens: false,
    dateFormat: '',
    defaultDate: '',
    defaultHour: 0,
    defaultMinute: 59,
    defaultTime: '',
    disableDate: false,
    locale: Dutch.nl,
    maxDate: '',
    maxTime: '',
    minDate: '',
    minTime: '',
    enableTime: false,
    noCalendar: false,
    onChange: (selectedDates: Date[], selectedDate: string) => {
      this.selectedDates = selectedDates
      this.selectedDate = selectedDate
    },
  }

  @Watch('value', { immediate: true })
  onValueChange(newValue: string) {
    this.selectedDate = newValue
  }

  @Watch('selectedDate')
  onSelectedDateChange(newValue: string) {
    this.$emit('input', newValue)
    this.flatpickr.setDate(newValue)
  }

  initProperties() {
    this.settings.maxDate = this.maxDate
    this.settings.maxTime = this.maxTime
    this.settings.minDate = this.minDate
    this.settings.minTime = this.minTime
    this.settings.noCalendar = this.disableDate
    this.settings.dateFormat = this.format
    this.settings.defaultDate = this.defaultDate
    this.settings.defaultHour = this.defaultHour
    this.settings.defaultMinute = this.defaultMinute
    this.settings.defaultTime = this.defaultTime
    this.settings.disableDate = this.disableDate
    this.settings.enableTime = this.enableTime
    this.settings.locale = this.locale === 'nl' ? Dutch.nl : Dutch.en
  }

  mounted() {
    this.initProperties()
    const datepickerEl = (this.$refs.input as Vue).$el as HTMLFormElement
    this.flatpickr = flatpickr(datepickerEl, this.settings) as Instance

    if (this.placeholder) {
      datepickerEl.placeholder = this.placeholder
    }
  }

  beforeDestroy() {
    if (this.flatpickr) {
      this.flatpickr.destroy()
    }
  }

  openDatepicker() {
    if (this.flatpickr) {
      this.flatpickr.open()
    }
  }

  @Watch('minDate', { immediate: false })
  minDateChanged(value: Date) {
    this.flatpickr.set('minDate', value)
  }

  @Watch('maxDate', { immediate: false })
  maxDateChanged(value: Date) {
    this.flatpickr.set('maxDate', value)
  }

  get classes() {
    return [
      'vl-input-field',
      { 'vl-input-field--error': this.modError },
      { 'vl-input-field--disabled': this.modDisabled },
      { 'vl-input-field--small': this.modSmall },
      { 'vl-input-field--inline': this.modInline },
      { 'vl-input-field--block': this.modBlock },
    ]
  }
}
