var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mo-loading-bar" }, [
    _c(
      "div",
      {
        staticClass: "mo-loading-bar__progress",
        style: { width: _vm.progress + "%" },
        attrs: {
          role: "progressbar",
          "aria-valuenow": _vm.progress,
          "aria-valuemin": "0",
          "aria-valuemax": "100",
        },
      },
      [
        _c("span", { staticClass: "vl-u-visually-hidden" }, [
          _vm._v(_vm._s(_vm.progress) + "% volledig"),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }