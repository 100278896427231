import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class MoTab extends Vue {
  isActive = false
  isVisible = true

  @Prop({ default: '' })
  prefix: string

  @Prop({ required: true })
  label: string

  @Prop({ default: '' })
  suffix: string

  @Prop({ default: null })
  id: string

  @Prop({ default: false })
  isDisabled: boolean

  @Watch('isActive')
  onIsActiveChange(newValue: boolean) {
    this.$emit('isActive', newValue)
  }

  get header() {
    return this.prefix + this.label + this.suffix
  }

  get hash() {
    if (this.isDisabled) {
      return '#'
    }

    return `#${this.uid}`
  }

  get uid() {
    return this.id ? `${this.id}` : `${this.label.toLowerCase().replace(/ /g, '-')}`
  }
}
