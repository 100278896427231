var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vl-input-group",
    [
      _vm.mask
        ? _c(
            "masked-input",
            _vm._b(
              {
                ref: "input",
                class: _vm.classes,
                attrs: {
                  type: _vm.type,
                  disabled: _vm.modDisabled,
                  mask: _vm.mask,
                  "show-mask": _vm.showMask,
                  guide: _vm.guide,
                  "data-lpignore": "true",
                  "keep-char-positions": true,
                  "placeholder-char": _vm.placeholderChar,
                  placeholder: _vm.placeholder,
                },
                on: {
                  blur: function ($event) {
                    return _vm.$emit("blur", $event)
                  },
                },
                model: {
                  value: _vm.selectedDate,
                  callback: function ($$v) {
                    _vm.selectedDate = $$v
                  },
                  expression: "selectedDate",
                },
              },
              "masked-input",
              _vm.$attrs,
              false
            )
          )
        : _c(
            "vl-input-field",
            _vm._b(
              {
                ref: "input",
                attrs: {
                  "mod-error": _vm.modError,
                  "mod-block": "",
                  "data-lpignore": "true",
                },
                model: {
                  value: _vm.selectedDate,
                  callback: function ($$v) {
                    _vm.selectedDate = $$v
                  },
                  expression: "selectedDate",
                },
              },
              "vl-input-field",
              _vm.$attrs,
              false
            )
          ),
      _c("vl-input-addon", {
        ref: "addon",
        attrs: {
          type: "button",
          "tag-name": "button",
          icon: "calendar",
          tooltip: _vm.addonTooltip,
          text: _vm.addonText,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.openDatepicker.apply(null, arguments)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }