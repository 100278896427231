var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isJobFound
        ? _c(
            "vl-column",
            {
              directives: [
                {
                  name: "vl-spacer",
                  rawName: "v-vl-spacer:bottom.small",
                  arg: "bottom",
                  modifiers: { small: true },
                },
                {
                  name: "scroll",
                  rawName: "v-scroll",
                  value: _vm.scroll,
                  expression: "scroll",
                },
              ],
              staticClass: "mo-box-padding mo-box-bordered",
            },
            [
              _c(
                "vl-grid",
                { attrs: { "mod-stacked": "" } },
                [
                  _c(
                    "vl-column",
                    { attrs: { width: "6", "width-s": "6", "width-xs": "12" } },
                    [
                      _c("span", [
                        _c("strong", [_vm._v(_vm._s(_vm.jobTitle))]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("documents.simpleBulk.started", "nl")
                            ) +
                            " "
                        ),
                        _c("strong", [_vm._v(_vm._s(_vm.startDate))]),
                      ]),
                    ]
                  ),
                  !_vm.isFinished
                    ? _c(
                        "vl-column",
                        {
                          attrs: {
                            width: "6",
                            "width-s": "6",
                            "width-xs": "12",
                          },
                        },
                        [
                          _c("mo-loading-bar", {
                            attrs: { progress: _vm.progress },
                          }),
                          _c("br"),
                          _c(
                            "div",
                            { staticClass: "loader-container" },
                            [
                              _c("vl-loader"),
                              _vm._v("     "),
                              _c("span", [
                                _vm._v(" " + _vm._s(_vm.progressLabel) + " "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFinished
                    ? _c(
                        "vl-column",
                        {
                          attrs: {
                            width: "6",
                            "width-s": "12",
                            "width-xs": "12",
                          },
                        },
                        [
                          _vm.uploadLabel
                            ? _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "vl-spacer",
                                      rawName: "v-vl-spacer:bottom.small",
                                      arg: "bottom",
                                      modifiers: { small: true },
                                    },
                                  ],
                                },
                                [
                                  _c("span", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.uploadLabel),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "vl-spacer",
                                  rawName: "v-vl-spacer:bottom.small",
                                  arg: "bottom",
                                  modifiers: { small: true },
                                },
                              ],
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.jobItem) + " "),
                              _c(
                                "span",
                                { staticClass: "vl-u-text--success" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "documents.simpleBulk.documentsAdded",
                                          "nl",
                                          { countAdded: _vm.amountSuccess }
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("general.label.and", "nl")) +
                                  " "
                              ),
                              _c("span", { staticClass: "vl-u-text--error" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "documents.simpleBulk.documentsError",
                                        "nl",
                                        { countError: _vm.amountError }
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _vm.replaceUpload
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "vl-button",
                                    {
                                      attrs: { "mod-secondary": "" },
                                      on: { click: _vm.clear },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "vl-button__icon vl-button__icon--before vl-vi vl-vi-text-undo",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                      _vm._v(" Bestand vervangen "),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.closeable
                            ? _c("vl-button", {
                                staticClass: "vl-sidebar__btn-close",
                                attrs: {
                                  icon: "cross",
                                  "mod-naked-action": "",
                                  "mod-icon-after": "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.close.apply(null, arguments)
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFinished && _vm.canDownload
                    ? _c(
                        "vl-column",
                        { attrs: { width: "12", "width-xs": "12" } },
                        [
                          !_vm.downloading
                            ? _c(
                                "vl-link",
                                {
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.download.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "vl-vi vl-vi-download-harddisk",
                                    },
                                    [
                                      _vm._v(
                                        "Download " + _vm._s(_vm.nameOfFile)
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c("vl-loader", {
                                attrs: { message: "Resultaat exporteren" },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }