import { IMoAddress } from './mo-address-auto-form.model'

export function setPostcodeGemeente(postcodeGemeente: string, address: IMoAddress): IMoAddress {
  if (!postcodeGemeente) {
    return
  }
  if (!address) {
    address = {
      aanspreking: '',
      voornaam: '',
      naam: '',
      organisatie: '',
      postcode: '',
      gemeente: '',
      straat: '',
      nummer: '',
      bus: '',
      lijn2: '',
      land: '',
      regio: '',
    }
  }

  const gemeenteInfo = postcodeGemeente.split('|')
  if (gemeenteInfo.length > 1) {
    address.postcode = gemeenteInfo[0] ? gemeenteInfo[0] : ''
    address.gemeente = gemeenteInfo[1] ? gemeenteInfo[1] : ''
  } else {
    address.gemeente = postcodeGemeente
    address.postcode = ''
  }

  return address
}

export function setNiscodeGemeente(postcodeGemeente: string): string {
  if (!postcodeGemeente) {
    return null
  }
  const gemeenteInfo = postcodeGemeente.split('|')
  if (gemeenteInfo.length > 1) {
    const niscode = gemeenteInfo[2] ? gemeenteInfo[2] : ''
    return niscode
  }
  return null
}

export function setAddress(storageAdres): IMoAddress {
  if (!storageAdres) {
    return
  }
  const formAdres = {
    organisatie: storageAdres.organisatie ? storageAdres.organisatie : '',
    aanspreking: storageAdres.aanspreking ? storageAdres.aanspreking : '',
    voornaam: storageAdres.voornaam ? storageAdres.voornaam : '',
    naam: storageAdres.naam ? storageAdres.naam : '',
    postcode: storageAdres.postcode ? storageAdres.postcode : '',
    gemeente: storageAdres.gemeente ? storageAdres.gemeente : '',
    straat: storageAdres.straat ? storageAdres.straat : '',
    nummer: storageAdres.nummer ? storageAdres.nummer : '',
    bus: storageAdres.bus ? storageAdres.bus : '',
    lijn2: storageAdres.lijn2 ? storageAdres.lijn2 : '',
    regio: storageAdres.regio ? storageAdres.regio : '',
    land: storageAdres.land ? storageAdres.land : '',
  }

  return formAdres
}

export function setAdres(adres): IMoAddress {
  if (!adres) {
    return
  }
  const formAdres = {
    organisatie: adres.organisatie,
    aanspreking: '',
    voornaam: '',
    naam: '',
    postcode: adres.postcode ? adres.postcode : '',
    gemeente: adres.gemeente ? adres.gemeente : '',
    straat: adres.straat ? adres.straat : '',
    nummer: adres.huisnummer ? adres.huisnummer : '',
    bus: adres.busnummer ? adres.busnummer : '',
    regio: '',
    land: adres.land ? adres.land : '',
    lijn2: '',
  }

  return formAdres
}
