var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.classes,
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          _vm.isVisible = false
        },
      },
    },
    [
      _vm.isVisible
        ? _c("div", {
            staticClass: "vl-modal__backdrop",
            on: {
              click: function ($event) {
                _vm.isVisible = false
              },
            },
          })
        : _vm._e(),
      _c(
        "dialog",
        {
          staticClass: "vl-modal-dialog",
          attrs: {
            "aria-hidden": !_vm.isVisible,
            "aria-labelledby": _vm.labelId,
            "aria-describedby": _vm.describedId,
            open: _vm.isVisible,
            role: "dialog",
            "aria-modal": "true",
          },
        },
        [
          _vm.closable
            ? _c(
                "button",
                {
                  staticClass: "vl-modal-dialog__close",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      _vm.isVisible = false
                    },
                  },
                },
                [
                  _c("vl-icon", {
                    staticClass: "vl-modal-dialog__close__icon",
                    attrs: { icon: "cross" },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "vl-visually-hidden",
                          rawName: "v-vl-visually-hidden",
                        },
                      ],
                    },
                    [_vm._v("Sluiten")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.title
            ? _c(
                "vl-title",
                {
                  staticClass: "vl-modal-dialog__title",
                  attrs: { id: _vm.labelId, "tag-name": "h2" },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "vl-modal-dialog__content",
              attrs: { id: _vm.describedId },
            },
            [_vm._t("default")],
            2
          ),
          _vm._t("modal-footer"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }