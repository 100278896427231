var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mo-formfield-decorator",
    { attrs: { label: _vm.label } },
    [
      _c("mo-min-max-wrapper", {
        attrs: { unit: _vm.unit, options: _vm.options.min_max },
        model: {
          value: _vm.min_max,
          callback: function ($$v) {
            _vm.min_max = $$v
          },
          expression: "min_max",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }