import { DomainType } from '@/declarations/references.models'
import { localeStore } from '@/store/locale'
import { RawLocation, Route } from 'vue-router'
import i18n from '../i18n'
import { defaultLocale } from '../i18n/locales'
import router from './index'

export function IsLocalizedDomain(domain: string) {
  const localized = [DomainType.Messages, DomainType.HealthAttestations, DomainType.PushNotifications]
  return localized.map((x) => x.toString()).indexOf(DomainType[domain].toString()) > -1
}

export const localePath = (to: RawLocation): string => {
  const route = resolveLocalizedRoute(to)
  if (!route) {
    throw new Error(`route could not be resolved for '${to}'`)
  }
  return route.fullPath
}

export const localeLocation = (to: RawLocation): Route => {
  const route = resolveLocalizedRoute(to)
  if (!route) {
    throw new Error(`route could not be resolved for '${to}'`)
  }
  return route
}

function resolveLocalizedRoute(to: RawLocation): Route {
  let resolved: Route = null
  if (typeof to === 'object') {
    const locale = i18n.locale
    if (!routeExists(to.name)) {
      const routeNameSuffix = locale ? locale : defaultLocale
      to.name += `___${routeNameSuffix}`
      to.params = { ...to.params, locale }
    }
    resolved = router.resolve(to).resolved
  } else if (to === '/') {
    const store = localeStore()
    const localizedUriPrefix = store.localizedUriPrefix
    resolved = router.resolve({ path: `/${localizedUriPrefix}` }).resolved
  }
  return resolved
}

function routeExists(routeName: string) {
  const link = router.resolve({
    name: routeName,
  })
  if (link && link.href !== '/') {
    return true
  }
  return false
}
