import { pageStore } from '@/store/page'
import { NavigationGuardNext, Route } from 'vue-router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function startLoading(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const store = pageStore()
  await store.setLoading(true)
  next()
}

export async function endLoading(to: Route, from: Route) {
  const store = pageStore()

  await store.setLoading(false)
}
