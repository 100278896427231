var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vl-checkbox__wrapper" },
    [
      _vm._l(_vm.keyValues, function (keyValue) {
        return [
          _c(
            "vl-checkbox",
            {
              key: keyValue.key,
              attrs: {
                id: _vm.name + "_" + keyValue.key,
                "mod-error": _vm.showError,
                name: _vm.name,
                "mod-disabled": _vm.modDisabled || _vm.modDisabledKeep,
                "mod-block": _vm.modBlock,
                "mod-switch": _vm.modSwitch,
              },
              on: { input: _vm.inputHandler },
              model: {
                value: keyValue.checked,
                callback: function ($$v) {
                  _vm.$set(keyValue, "checked", $$v)
                },
                expression: "keyValue.checked",
              },
            },
            [_vm._v(" " + _vm._s(keyValue.val) + " ")]
          ),
          keyValue.options && keyValue.options.length && keyValue.checked
            ? [
                _c(
                  "div",
                  {
                    key: "d" + keyValue.key,
                    staticClass: "vl-checkbox__wrapper__indent",
                  },
                  _vm._l(keyValue.options, function (keyValue) {
                    return _c(
                      "vl-checkbox",
                      {
                        key: keyValue.key,
                        attrs: {
                          id: _vm.name + "_" + keyValue.key,
                          "mod-error": _vm.showError,
                          name: _vm.name,
                          "mod-disabled":
                            _vm.modDisabled || _vm.modDisabledKeep,
                          "mod-block": _vm.modBlock,
                          "mod-switch": _vm.modSwitch,
                        },
                        on: { input: _vm.inputHandler },
                        model: {
                          value: keyValue.checked,
                          callback: function ($$v) {
                            _vm.$set(keyValue, "checked", $$v)
                          },
                          expression: "keyValue.checked",
                        },
                      },
                      [_vm._v(" " + _vm._s(keyValue.val) + " ")]
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }