import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { MinMax, UnitEnum } from '../mo-min-max-wrapper/mo-min-max-wrapper'
import MoMinMaxWrapper from '../mo-min-max-wrapper/mo-min-max-wrapper.vue'
import { IValidationOptions } from '@/declarations/references.models'

@Component({
  components: {
    MoMinMaxWrapper,
  },
})
export default class MoMinMax extends Vue {
  internalMin = null
  internalMax = null

  @Prop({ default: UnitEnum[UnitEnum.Currency] })
  public unit: UnitEnum

  @Prop({ default: '' })
  public label: string

  @Prop({ required: true, default: [] })
  public value: number[]

  @Watch('values', { immediate: true })
  minChanged() {
    this.internalMin = this.value[0]
    this.internalMax = this.value[1]
  }

  get min_max() {
    return {
      min: this.internalMin,
      max: this.internalMax,
    }
  }

  set min_max(value: MinMax) {
    this.internalMin = value?.min
    this.internalMax = value?.max
    this.$emit('input', [this.internalMin, this.internalMax])
  }

  get options(): IValidationOptions {
    return {
      min_max: {
        locale: this.$i18n.locale,
        validations: [
          (value) => {
            if ((value.min != null && value.max == null) || (value.max != null && value.min == null)) {
              return 'De twee waarden moeten ingevuld zijn'
            }
            return null
          },
          (value) => {
            if (value.max < value.min) {
              return 'Het maximum moet hoger liggen dan het minimum'
            }
            return null
          },
          (value) => {
            if (value.min && value.max && this.unit.toString() === UnitEnum[UnitEnum.Year]) {
              const regex = new RegExp('([0-9]{4})')
              const match = regex.test(value.min) && regex.test(value.max)
              if (!match) {
                return 'Het jaar is niet geldig'
              }
            }
            return null
          },
        ],
      },
    }
  }
}
