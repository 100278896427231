var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c(
        "vl-title",
        { staticClass: "vl-title--editable", attrs: { "tag-name": _vm.tag } },
        [
          _vm._t("default"),
          _c("span", {
            staticClass:
              "vl-icon vl-vi vl-vi-pencil vl-link__icon vl-link__icon--after",
            attrs: { "aria-hidden": "true" },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }