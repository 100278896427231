var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("vl-pill-input", {
        class: _vm.classes,
        attrs: { id: _vm.id, name: _vm.name, disabled: _vm.modDisabled },
        on: { input: _vm.inputPill },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }