import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validationMixin from '@/mixins/validation'

@Component
export default class MoTextareaWrapper extends mixins(validationMixin) {
  @Prop({ default: null })
  name: string

  @Prop({ default: null })
  id: string

  @Prop({ default: '' })
  value: string

  @Prop({ default: false })
  modBlock: boolean

  @Prop({ default: ' ' })
  placeholder: string

  @Watch('modDisabled')
  onDisabledChange(newValue, _oldValue) {
    if (newValue) {
      this.localValue = ''
    }
  }
}
