import { routeMap } from '@/assets/routeMap'
import { Route, NavigationGuardNext } from 'vue-router'
import { decryptBase64 } from '@/libs/base64'
import { isSameRoute } from './router-utils'
import { localePath } from '../router.utils'
import { DomainType } from '@/declarations/references.models'
import { userStore } from '@/store/user'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default async function (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const isPublic = !!to.meta?.public
  const store = userStore()
  if (!isPublic && to.path === '/') {
    if (store.roles?.length === 1) {
      await store.setActiveRole(store.roles[0])
    }
    if (store.activeRoleMap?.length === 1) {
      if (to.query.redirect) {
        const path = `${decryptBase64(to.query.redirect)}`
        if (!isSameRoute(to.path, path)) {
          return next(path)
        }
      } else {
        const domain = store.activeRoleMap[0].domain.toLowerCase() as keyof typeof routeMap
        const routeName = routeMap[domain]
        if (routeName) {
          const path = localePath({ name: routeName })
          if (!isSameRoute(to.path, path) && !isSameRoute(from.path, path)) {
            return next(path)
          }
        }
      }
    }
  }
  next()
}
