var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-editor", {
    ref: "notificationEditor",
    attrs: { editorToolbar: _vm.customToolbar },
    on: { "text-change": _vm.onTextChange },
    model: {
      value: _vm.inputValue,
      callback: function ($$v) {
        _vm.inputValue = $$v
      },
      expression: "inputValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }