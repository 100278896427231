var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "container",
      class: { "vl-input-field--error": _vm.modError },
      on: {
        click: function ($event) {
          return _vm.setFocus()
        },
      },
    },
    [
      _c(
        "div",
        {
          ref: "focusable",
          staticClass: "js-vl-select",
          class: { "is-open is-focused": _vm.open },
          attrs: { tabindex: "0", "data-type": "select-one" },
          on: {
            focus: function ($event) {
              return _vm.expand()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "vl-select__inner",
              class: { "vl-input-field--disabled": _vm.modDisabled },
              attrs: { disabled: _vm.modDisabled },
            },
            [
              _c("div", { staticClass: "vl-input-field" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vl-select__item vl-select__item--selectable content",
                  },
                  [_vm._v(" " + _vm._s(_vm.mappedValue.label) + " ")]
                ),
              ]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "vl-select__list vl-select__list--dropdown",
              class: { "is-active": _vm.open },
              on: {
                "!keydown": function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  $event.stopPropagation()
                  _vm.open = false
                },
              },
            },
            [
              _vm.enableSearch
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter,
                        expression: "filter",
                      },
                    ],
                    ref: "search",
                    staticClass: "vl-input-field",
                    attrs: { type: "text", placeholder: "Zoek item" },
                    domProps: { value: _vm.filter },
                    on: {
                      keydown: function ($event) {
                        return _vm.keyPressHandler($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.filter = $event.target.value
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vl-select__list" },
                _vm._l(_vm.filtered, function (entry, index) {
                  return _c(
                    "div",
                    {
                      key:
                        _vm.keyId +
                        index +
                        (entry.key !== undefined ? entry.key : entry.val),
                      ref: "select_" + index,
                      refInFor: true,
                      staticClass:
                        "vl-select__item vl-select__item--choice vl-select__item--selectable",
                      class: {
                        "vl-select__placeholder": !entry.val,
                        "is-highlighted": index == _vm.selectPosition,
                      },
                      attrs: { tabindex: "999" },
                      on: {
                        mouseover: function ($event) {
                          return _vm.mouseOver(index)
                        },
                        click: function ($event) {
                          return _vm.selectValue()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.itemText(entry.label)) + " ")]
                  )
                }),
                0
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }