var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vl-accordion",
        _vm._b(
          {
            attrs: { open: _vm.isOpen },
            on: {
              open: function ($event) {
                _vm.isOpen = true
              },
              close: function ($event) {
                _vm.isOpen = false
              },
            },
          },
          "vl-accordion",
          _vm.$attrs,
          false
        )
      ),
      _c(
        "div",
        { staticClass: "mo-accordeon-content" },
        [_vm.isOpen ? _vm._t("default") : _vm._e()],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }