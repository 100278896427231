import router from '../router/index'
import { buildPageTitle } from '../router/pagetitle.utils'
import { Route } from 'vue-router'
import { moproStore } from '@/store/mopro'

export const updatePageTitle = (routeName: string, title: string) => {
  if (!title) {
    return
  }

  const route = router.resolve({ name: routeName }).route
  setPageTitle(route, title)
}

export const setPageTitle = (route: Route, title: string = null) => {
  if (!route) {
    return
  }

  const pageTitle = buildPageTitle(route.name, title)

  moproStore().setPageTitle(pageTitle)
  
  document.title = pageTitle
}
