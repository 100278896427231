import { defineStore } from 'pinia'

export const pageStore = defineStore('page', {
  state: () => {
    return { isLoading: false, pageObj: {}, global: [] }
  },
  getters: {
    loading(state) {
      return state.isLoading
    },
    page(state) {
      return (name) => {
        if (!state.pageObj || !state.pageObj[name] || !state.pageObj[name].params) {
          return null
        } else {
          return JSON.parse(JSON.stringify(state.pageObj[name].params))
        }
      }
    },
    alerts(state) {
      return (name) => {
        const alerts = []
        alerts.push(...state.global.filter((i) => !i.isToast))
        if (state.pageObj[name] && state.pageObj[name].alerts) {
          alerts.push(...state.pageObj[name].alerts)
        }

        return alerts
      }
    },

    toasts(state) {
      return (name) => {
        const toasts = []
        toasts.push(...state.global.filter((i) => i.isToast))

        return toasts
      }
    },
  },
  actions: {
    setLoading(loading) {
      this.isLoading = loading
    },
    setPage(page) {
      if (!this.pageObj[page.name]) {
        this.pageObj = {
          ...this.pageObj,
          [page.name]: {},
        }
      }
      if (this.pageObj[page.name].params) {
        this.pageObj[page.name].params = {
          ...this.pageObj[page.name].params,
          ...page.params,
        }
      } else {
        this.pageObj[page.name] = {
          ...this.pageObj[page.name],
          params: page.params,
        }
      }
    },

    clear(name = null) {
      if (name) {
        this.pageObj[name] = null
      } else {
        this.pageObj = {}
      }
    },

    setAlerts(alert) {
      if (alert.pageName) {
        if (!this.pageObj[alert.pageName]) {
          this.pageObj = {
            ...this.pageObj,
            [alert.pageName]: {},
          }
        }
        if (!this.pageObj[alert.pageName].alerts) {
          this.pageObj[alert.pageName] = {
            ...this.pageObj[alert.pageName],
            alerts: [],
          }
        }

        const index = this.pageObj[alert.pageName].alerts.findIndex((currentAlert) => currentAlert.id === alert.id)
        if (index > -1) {
          Vue.set(this.pageObj[alert.pageName].alerts, index, alert)
        } else {
          this.pageObj[alert.pageName].alerts.push(alert)
        }
      } else {
        const index = this.global.findIndex((currentAlert) => currentAlert.id === alert.id)

        if (index > -1) {
          Vue.set(this.global, index, alert)
        } else {
          this.global.push(alert)
        }
      }
    },

    setToasts(toast) {
      toast.isToast = true
      const index = this.global.findIndex((current) => current.id === toast.id)

      if (index > -1) {
        Vue.set(this.global, index, toast)
      } else {
        this.global.push(toast)
      }

      setTimeout(() => {
        const index = this.global.findIndex((current) => current.id === toast.id)
        if (index > -1) {
          this.global.splice(index, 1)
        }
      }, 5000)
    },
    removeAlerts(alert) {
      if (alert.pageName) {
        if (this.pageObj[alert.pageName] && this.pageObj[alert.pageName].alerts) {
          if (!isNaN(alert.id)) {
            const index = this.pageObj[alert.pageName].alerts.findIndex((currentAlert) => currentAlert.id === alert.id)
            if (index > -1) {
              this.pageObj[alert.pageName].alerts.splice(index, 1)
            }
          } else {
            this.pageObj[alert.pageName].alerts = []
          }
        }
      } else {
        const index = this.global.findIndex((currentAlert) => currentAlert.id === alert.id)
        if (index > -1) {
          this.global.splice(index, 1)
        }
      }
    },

    removeAllAlerts(alert) {
      if (this.pageObj[alert.pageName] && this.pageObj[alert.pageName].alerts) {
        this.pageObj[alert.pageName].alerts = []
      }
    },
  },
})
