var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vl-form-grid",
        { staticClass: "vl-u-spacer--medium", attrs: { "mod-stacked": "" } },
        [
          _c(
            "vl-column",
            {
              attrs: { "width-m": "10", "width-s": "12", width: _vm.formWidth },
            },
            [
              _c(
                "vl-form-grid",
                {
                  staticClass: "vl-u-spacer--medium",
                  attrs: { "mod-stacked": "" },
                },
                [
                  _c(
                    "vl-column",
                    { attrs: { width: "12" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Organisatie" },
                        },
                        [
                          _c("mo-input-wrapper", {
                            attrs: {
                              id: _vm.fieldRef("organisatie"),
                              name: _vm.fieldRef("organisatie"),
                              options: _vm.options.organisatie,
                              "mod-disabled": _vm.readOnly,
                              autocomplete: _vm.modAutocomplete
                                ? "organization"
                                : null,
                              "mod-block": "",
                            },
                            model: {
                              value: _vm.address.organisatie,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "organisatie", $$v)
                              },
                              expression: "address.organisatie",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "2" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Aanspreking" },
                        },
                        [
                          _c("mo-input-wrapper", {
                            attrs: {
                              options: _vm.options.aanspreking,
                              "mod-disabled": _vm.readOnly,
                              autocomplete: _vm.modAutocomplete
                                ? "honorific-prefix"
                                : null,
                              "mod-block": "",
                            },
                            model: {
                              value: _vm.address.aanspreking,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "aanspreking", $$v)
                              },
                              expression: "address.aanspreking",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "5" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Voornamen" },
                        },
                        [
                          _c("mo-input-wrapper", {
                            attrs: {
                              id: _vm.fieldRef("voornaam"),
                              name: _vm.fieldRef("voornaam"),
                              options: _vm.options.voornaam,
                              autocomplete: _vm.modAutocomplete
                                ? "given-name"
                                : null,
                              "mod-block": "",
                              "mod-disabled": _vm.readOnly,
                            },
                            model: {
                              value: _vm.address.voornaam,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "voornaam", $$v)
                              },
                              expression: "address.voornaam",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "5" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Naam" },
                        },
                        [
                          _c("mo-input-wrapper", {
                            attrs: {
                              id: _vm.fieldRef("naam"),
                              name: _vm.fieldRef("naam"),
                              options: _vm.options.naam,
                              autocomplete: _vm.modAutocomplete
                                ? "family-name"
                                : null,
                              "mod-block": "",
                              "mod-disabled": _vm.readOnly,
                            },
                            model: {
                              value: _vm.address.naam,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "naam", $$v)
                              },
                              expression: "address.naam",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vl-form-grid",
                {
                  staticClass: "vl-u-spacer--small",
                  attrs: { "mod-stacked": "" },
                },
                [
                  _c(
                    "vl-column",
                    { attrs: { width: "3" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        { attrs: { label: "Land" } },
                        [
                          _c("mo-select-wrapper", {
                            staticClass: "mo-country-select",
                            attrs: {
                              items: _vm.landenList,
                              emptyField: false,
                              id: _vm.fieldRef("land"),
                              name: _vm.fieldRef("land"),
                              "mod-block": "",
                              "mod-disabled": _vm.readOnly,
                            },
                            model: {
                              value: _vm.land,
                              callback: function ($$v) {
                                _vm.land = $$v
                              },
                              expression: "land",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isForeignCountry
                    ? _c(
                        "vl-column",
                        { attrs: { width: "9" } },
                        [
                          _c(
                            "mo-formfield-decorator",
                            {
                              staticClass: "vl-u-spacer--small",
                              attrs: { label: "Postcode en gemeente" },
                            },
                            [
                              _c("mo-autocomplete-wrapper", {
                                ref: "gemeente",
                                attrs: {
                                  data: _vm.gemeenteData,
                                  fetching: _vm.gemeenteDataFetching,
                                  id: _vm.fieldRef("postcode_gemeente"),
                                  name: _vm.fieldRef("postcode_gemeente"),
                                  autocomplete: "off",
                                  options: _vm.options.postcode_gemeente,
                                  "mod-block": "",
                                  "mod-disabled": _vm.readOnly,
                                },
                                on: {
                                  input: _vm.onGemeenteInputChange,
                                  select: function ($event) {
                                    return _vm.onGemeenteSelect($event)
                                  },
                                },
                                model: {
                                  value: _vm.postcode_gemeente,
                                  callback: function ($$v) {
                                    _vm.postcode_gemeente = $$v
                                  },
                                  expression: "postcode_gemeente",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "vl-column",
                        { attrs: { width: "9" } },
                        [
                          _c(
                            "vl-form-grid",
                            [
                              _c(
                                "vl-column",
                                { attrs: { width: "3" } },
                                [
                                  _c(
                                    "mo-formfield-decorator",
                                    {
                                      staticClass: "vl-u-spacer--small",
                                      attrs: { label: "Postcode" },
                                    },
                                    [
                                      _c("mo-input-wrapper", {
                                        attrs: {
                                          id: _vm.fieldRef("postcode"),
                                          name: _vm.fieldRef("postcode"),
                                          options: _vm.options.postcode,
                                          "mod-block": "",
                                          "mod-disabled": _vm.readOnly,
                                        },
                                        model: {
                                          value: _vm.address.postcode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "postcode",
                                              $$v
                                            )
                                          },
                                          expression: "address.postcode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "vl-column",
                                { attrs: { width: "9" } },
                                [
                                  _c(
                                    "mo-formfield-decorator",
                                    {
                                      staticClass: "vl-u-spacer--small",
                                      attrs: { label: "Gemeente" },
                                    },
                                    [
                                      _c("mo-input-wrapper", {
                                        attrs: {
                                          id: _vm.fieldRef("gemeente"),
                                          name: _vm.fieldRef("gemeente"),
                                          options: _vm.options.gemeente,
                                          "mod-block": "",
                                          "mod-disabled": _vm.readOnly,
                                        },
                                        model: {
                                          value: _vm.address.gemeente,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.address,
                                              "gemeente",
                                              $$v
                                            )
                                          },
                                          expression: "address.gemeente",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                  _c(
                    "vl-column",
                    { attrs: { width: "8" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Straat" },
                        },
                        [
                          _c("mo-autocomplete-wrapper", {
                            ref: "straat",
                            attrs: {
                              data: _vm.straatData,
                              fetching: _vm.straatDataFetching,
                              id: _vm.fieldRef("straat"),
                              name: _vm.fieldRef("straat"),
                              autocomplete: "off",
                              options: _vm.options.straat,
                              "mod-disabled": _vm.readOnly,
                              "mod-block": "",
                            },
                            on: {
                              input: _vm.onStraatInputChange,
                              select: function ($event) {
                                return _vm.onStraatSelect($event)
                              },
                            },
                            model: {
                              value: _vm.address.straat,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "straat", $$v)
                              },
                              expression: "address.straat",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "2" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Nummer" },
                        },
                        [
                          _c("mo-input-wrapper", {
                            ref: "nummer",
                            attrs: {
                              id: _vm.fieldRef("nummer"),
                              name: _vm.fieldRef("nummer"),
                              options: _vm.options.nummer,
                              autocomplete: _vm.modAutocomplete
                                ? "straat-nummer"
                                : null,
                              "mod-block": "",
                              "mod-disabled": _vm.readOnly,
                            },
                            model: {
                              value: _vm.address.nummer,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "nummer", $$v)
                              },
                              expression: "address.nummer",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "2" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Bus" },
                        },
                        [
                          _c("mo-input-wrapper", {
                            ref: "bus",
                            attrs: {
                              id: _vm.fieldRef("bus"),
                              name: _vm.fieldRef("bus"),
                              options: _vm.options.bus,
                              autocomplete: _vm.modAutocomplete
                                ? "straat-bus"
                                : null,
                              "mod-block": "",
                              "mod-disabled": _vm.readOnly,
                            },
                            model: {
                              value: _vm.address.bus,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "bus", $$v)
                              },
                              expression: "address.bus",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "12" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        {
                          staticClass: "vl-u-spacer--small",
                          attrs: { label: "Extra adresregel" },
                        },
                        [
                          _c("mo-input-wrapper", {
                            attrs: {
                              "mod-block": "",
                              options: _vm.options.lijn2,
                              id: _vm.fieldRef("lijn2"),
                              name: _vm.fieldRef("lijn2"),
                              "mod-disabled": _vm.readOnly,
                            },
                            model: {
                              value: _vm.address.lijn2,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "lijn2", $$v)
                              },
                              expression: "address.lijn2",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vl-column",
                    { attrs: { width: "12" } },
                    [
                      _c(
                        "mo-formfield-decorator",
                        { attrs: { label: "Regio / Provincie" } },
                        [
                          _c("mo-input-wrapper", {
                            attrs: {
                              options: _vm.options.regio,
                              id: _vm.fieldRef("regio"),
                              name: _vm.fieldRef("regio"),
                              "mod-block": "",
                              "mod-disabled": _vm.readOnly,
                            },
                            model: {
                              value: _vm.address.regio,
                              callback: function ($$v) {
                                _vm.$set(_vm.address, "regio", $$v)
                              },
                              expression: "address.regio",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showPreview
            ? _c("vl-column", { attrs: { width: "4" } }, [
                _c("div", { staticClass: "mo-address-preview" }, [
                  _c("div", [
                    _c("span", { staticClass: "vl-form__label--light" }, [
                      _vm._v("Preview"),
                    ]),
                  ]),
                  _vm.address.organisatie
                    ? _c("div", { staticClass: "mo-address-preview--bold" }, [
                        _vm._v(_vm._s(_vm.address.organisatie)),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "mo-address-preview--bold" }, [
                    _vm._v(_vm._s(_vm.volledigeNaam)),
                  ]),
                  _c("div", [
                    _vm.address.straat
                      ? _c("span", [_vm._v(_vm._s(_vm.address.straat))])
                      : _vm._e(),
                    _vm.address.nummer
                      ? _c("span", [_vm._v(" " + _vm._s(_vm.address.nummer))])
                      : _vm._e(),
                    _vm.address.bus
                      ? _c("span", [_vm._v(" Bus " + _vm._s(_vm.address.bus))])
                      : _vm._e(),
                  ]),
                  _vm.address.lijn2
                    ? _c("div", [_vm._v(_vm._s(_vm.address.lijn2))])
                    : _vm._e(),
                  _vm.postcode_gemeente && !_vm.isForeignCountry
                    ? _c("div", [_vm._v(_vm._s(_vm.postcode_gemeente))])
                    : _vm._e(),
                  _vm.address && _vm.isForeignCountry
                    ? _c("div", [
                        _vm._v(
                          _vm._s(_vm.address.postcode) +
                            " " +
                            _vm._s(_vm.address.gemeente)
                        ),
                      ])
                    : _vm._e(),
                  _vm.address.regio
                    ? _c("div", [_vm._v(_vm._s(_vm.address.regio))])
                    : _vm._e(),
                  _c("div", [_vm._v(_vm._s(_vm.address.land))]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }