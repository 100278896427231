import { defineStore } from 'pinia'

export const featureToggleStore = defineStore('feature-toggle', {
  getters: {
    isRouteGuardLoggingActive: () => {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_ROUTE_GUARD_LOGGING_ENABLED)
    },
    isBuitenlandsVoertuigNummerplaat2Active() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_BUITENLANDSVOERTUIG_NUMMERPLAAT2_ENABLED)
    },
    isEmailActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_EMAIL_ENABLED)
    },
    isHerzendenEnkelDoor1700Active() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_HERVERZENDEN_ENKEL_DOOR_1700_ENABLED)
    },
    isHealthAttestationsSearchOnNameActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_HEALTHATTESTATIONS_SEARCH_NAME_ENABLED)
    },
    isHealthAttestationsSearchCTPCActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_HEALTHATTESTATIONS_CTPCATTESTATION_ENABLED)
    },
    isHealthAttestationsRecreateActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_HEALTHATTESTATIONS_RECREATE_ENABLED)
    },
    isHealthAttestationsOneDoseRequiredActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_HEALTHATTESTATIONS_ONE_DOSE_REQUIRED_ENABLED)
    },
    isPushNotificationsGroupsActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_PUSHNOTIFICATIONS_GROUPS)
    },
    isPushNotificationsSendToGroupsActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_PUSHNOTIFICATIONS_SEND_TO_GROUPS)
    },
    isKadasterZoekVerkoopPrijzenActive() {
      return parseEnv(process.env.VUE_APP_MO_KADASTER_VERKOOPPRIJZEN_ENABLED)
    },
    isOndernemingGeefOndernemingActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_GEEFONDERNEMING_ENABLED)
    },
    isOndernemingGeefPCenTWActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_GEEFPCENTW_ENABLED)
    },
    isBrief30MBActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_BRIEF30MB_ENABLED)
    },
    isOndernemingGeefDeelnemingenActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_GEEFDEELNEMINGEN_ENABLED)
    },
    isKadasterGeefHistoriekEigendomstoestandActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_KADASTER_HISTORIEK_ENABLED)
    },
    isLeefloonActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_LEEFLOON_ENABLED)
    },
    isKadasterDownloadEigenaarsActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_KADASTER_DOWNLOAD_EIGENAARS_ENABLED)
    },
    isZoekVerkoopprijzenDownloadActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_ZOEKVERKOOPPRIJZEN_DOWNLOAD_ENABLED)
    },
    isKadasterMultiselectPercelenActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_KADASTER_MULTISELECT_ENABLED)
    },
    isKadasterBulkEigendomstoenstandActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_KADASTER_DOWNLOAD_EIGENAARS_BULK_ENABLED)
    },
    isKadasterZoekPerceelBestandActive() {
      return parseEnv(process.env.VUE_APP_MO_FEATURETOGGLE_KADASTER_ZOEKPERCEEL_BESTAND_ENABLED)
    },
  },
})

const parseEnv = (value: string): boolean => {
  return value && value.toLowerCase ? value.toLowerCase() === 'true' : !!value
}
