var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "mo-click-outside",
          rawName: "v-mo-click-outside",
          value: _vm.hideResults,
          expression: "hideResults",
        },
      ],
      staticClass: "mo-autocomplete js-vl-autocomplete",
    },
    [
      _c("span", {
        directives: [
          { name: "vl-visually-hidden", rawName: "v-vl-visually-hidden" },
        ],
        domProps: { innerHTML: _vm._s(_vm.helpText) },
      }),
      _c(
        "mo-input-field",
        _vm._g(
          _vm._b(
            {
              ref: "inputField",
              attrs: {
                "mod-error": _vm.showError,
                id: _vm.id,
                name: _vm.name,
                "mod-disabled": _vm.modDisabled,
                autocomplete: _vm.autocomplete,
              },
              on: { focus: _vm.showResults },
              nativeOn: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                    )
                      return null
                    return _vm.hideResults.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.selectFocussedResult.apply(null, arguments)
                  },
                ],
                keyup: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    return _vm.hideResults.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown",
                      ])
                    )
                      return null
                    return _vm.arrowDown.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp",
                      ])
                    )
                      return null
                    return _vm.arrowUp.apply(null, arguments)
                  },
                ],
              },
              model: {
                value: _vm.localValue,
                callback: function ($$v) {
                  _vm.localValue = $$v
                },
                expression: "localValue",
              },
            },
            "mo-input-field",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _c("div", {
        staticClass: "vl-autocomplete__loader",
        attrs: {
          "data-vl-show": _vm.fetching.toString(),
          "data-vl-loader": "",
          "aria-hidden": !_vm.fetching.toString(),
        },
      }),
      _vm.hasResults
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.resultsVisible,
                  expression: "resultsVisible",
                },
              ],
              staticClass: "vl-autocomplete",
              attrs: {
                "data-vl-content": "",
                "aria-hidden": !_vm.hasResults.toString(),
                "data-vl-show": _vm.hasResults.toString(),
                "aria-labelledby": _vm.$attrs.id,
              },
            },
            [
              _c("div", { staticClass: "vl-autocomplete__list-wrapper" }, [
                _c(
                  "ul",
                  {
                    staticClass: "vl-autocomplete__list",
                    attrs: { "data-vl-records": "", role: "listbox" },
                  },
                  _vm._l(_vm.results, function (result, index) {
                    return _c(
                      "li",
                      {
                        staticClass: "vl-autocomplete__item",
                        attrs: { role: "option" },
                      },
                      [
                        _c(
                          "a",
                          {
                            class: {
                              "vl-autocomplete__cta": true,
                              "vl-autocomplete__cta--focus": result.selected,
                            },
                            attrs: {
                              href: "#",
                              tabindex: "-1",
                              "data-vl-index": index + 1,
                              "data-vl-record": "",
                              "data-vl-focus": "",
                              "data-vl-value": result.title,
                            },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.select(result.value)
                              },
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "vl-autocomplete__cta__title",
                              domProps: { innerHTML: _vm._s(result.title) },
                            }),
                            result.subtitle
                              ? _c(
                                  "span",
                                  { staticClass: "vl-autocomplete__cta__sub" },
                                  [_vm._v(_vm._s(result.subtitle))]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }