import { userStore } from '@/store/user'
import { AxiosResponse } from 'axios'
import moment from 'moment'
import axios from './index'

export function sendDuration(response: AxiosResponse<any, any>): void {
  if (isPublic(response)) {
    return
  }

  if (response.config) {
    response.config.metadata.endTime = new Date()
    const duration = response.config.metadata.endTime.valueOf() - response.config.metadata.startTime.valueOf()
    const url = response.config.url
    const metricName = response.headers['x-metric-name']
    const match = /api\/backend\/(?!metrics)(.*)/i.exec(url)
    if (match && metricName) {
      axios.post('/sss/api/backend/metrics', {
        metricName,
        timeStamp: moment().utc().format(),
        unit: 0,
        value: duration,
      })
    }
  }
}

export function storeUserSession(response: AxiosResponse<any, any>): void {
  const uStore = userStore()
  if (isPublic(response)) {
    return
  }

  if (response) {
    const expires = response.headers['x-user-expires']
    if (expires) {
      uStore.setExpires(expires)
    }
  }
}

export function isPublic(response: AxiosResponse<any, any>) {
  let isPublic = false
  if (response && response.config) {
    const url = response.config.url
    isPublic = url.startsWith('/sss/public/')
  }
  return isPublic
}

export const responseMiddleware = [sendDuration, storeUserSession]
