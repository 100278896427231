import VueGtag from 'vue-gtag'
import Vue from 'vue'

const moduleOptions = {
  id: process.env.VUE_APP_GA_ID,
  params: {
    anonymize_ip: true,
  },
}

const config = {
  config: moduleOptions,
  pageTrackerEnabled: false,
  pageTrackerTemplate(to) {
    return {
      page_title: to.name,
      page_path: to.name,
      page_location: to.name,
    }
  },
}

Vue.use(VueGtag, config)

const gTag = (Vue as any).$gtag

export default gTag
