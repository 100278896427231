import moment from 'moment'
import axios from '@/infrastructure/axios'

export interface ILog {
  file?: string
  method?: string
  severity?: string
  impact?: string
  transactionId?: string
  context?: string
  detailContext?: string
  error?: ILogError | string | unknown
  properties?: string
}

export interface ILogError {
  stack?: string
  message?: string
  req?: {
    originalUrl?: string
  }
  status?: string
  statusCode?: string
  status_code?: string
  output?: {
    statusCode: string
  }
}

export default function Log(log: ILog) {
  if (log) {
    const applicationName = 'MOPRO.Frontend'
    const datetime = moment().format('YYYY-MM-DD HH:mm:ss.SSS Z')
    const file = emptyToString(log.file)
    const method = emptyToString(log.method)
    const severity = emptyToString(log.severity)
    const impact = emptyToString(log.impact)
    const transactionId = emptyToString(log.transactionId)
    const context = sanitizeInsz(emptyToString(log.context))
    const detailContext = sanitizeInsz(emptyToString(log.detailContext))
    const error = sanitizeInsz(emptyToString(errorParser(log.error)))
    const properties = sanitizeInsz(emptyToString(log.properties))

    post(
      `[${file}] [${method}] - [${datetime}] [${severity}] [${impact}] [${transactionId}] [${applicationName}] [${context}] [${detailContext}] ${error} [${properties}]`,
    )
  }
}

function post(message: string) {
  axios.post('/log', { message })
}

function emptyToString(value: unknown) {
  return !value ? '' : value.toString()
}

function sanitizeInsz(value: unknown) {
  return !value ? '' : value.toString().replace(/([0-9]{11})/g, '???????????')
}

/*
    ERROR
        - stack
        - status
        - message
*/

function errorParser(error: ILogError | string): string {
  if (error instanceof Object && (error.stack || error.message || error.req)) {
    const mappedError = {
      path: '',
      status: '',
      message: '',
      stack: '',
    }

    if (error.stack) {
      mappedError.stack = error.stack
    }

    if (error.message) {
      mappedError.message = error.message
    }

    if (error.req) {
      mappedError.path = error.req.originalUrl
    }

    mappedError.status = error.status || error.statusCode || error.status_code || (error.output && error.output.statusCode)

    return JSON.stringify(mappedError)
  }
  return JSON.stringify(error)
}
