var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mask
    ? _c("masked-input", {
        class: _vm.classes,
        attrs: {
          type: _vm.type,
          disabled: _vm.modDisabled,
          mask: _vm.mask,
          "show-mask": _vm.showMask,
          "data-lpignore": "true",
          guide: _vm.guide,
          "keep-char-positions": false,
          "placeholder-char": _vm.placeholderChar,
        },
        on: {
          paste: function ($event) {
            return _vm.$emit("paste", $event)
          },
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
        },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      })
    : _vm.type === "checkbox"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        class: _vm.classes,
        attrs: {
          id: _vm.id,
          name: _vm.name,
          disabled: _vm.modDisabled,
          autocomplete: _vm.autocomplete,
          type: "checkbox",
        },
        domProps: {
          checked: Array.isArray(_vm.inputValue)
            ? _vm._i(_vm.inputValue, null) > -1
            : _vm.inputValue,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.inputValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.inputValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.inputValue = $$c
            }
          },
        },
      })
    : _vm.type === "radio"
    ? _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        class: _vm.classes,
        attrs: {
          id: _vm.id,
          name: _vm.name,
          disabled: _vm.modDisabled,
          autocomplete: _vm.autocomplete,
          type: "radio",
        },
        domProps: { checked: _vm._q(_vm.inputValue, null) },
        on: {
          change: function ($event) {
            _vm.inputValue = null
          },
        },
      })
    : _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        class: _vm.classes,
        attrs: {
          id: _vm.id,
          name: _vm.name,
          disabled: _vm.modDisabled,
          autocomplete: _vm.autocomplete,
          type: _vm.type,
        },
        domProps: { value: _vm.inputValue },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.inputValue = $event.target.value
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }