import i18n from '@/infrastructure/i18n'
import { Location, NavigationGuardNext, Route } from 'vue-router'
import { isSameRoute } from './router-utils'
import { redirectToLogin } from '@/businesslogic/user/login.functions'
import { localeLocation, localePath } from '../router.utils'
import { moproStore } from '@/store/mopro'
import { userStore } from '@/store/user'
import { pageStore } from '@/store/page'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const mpStore = moproStore()
  const uStore = userStore()
  const pStore = pageStore()

  if (to?.meta?.public) {
    mpStore.setPublic(true)
    return next()
  }
  mpStore.setPublic(false)

  const is404 = to?.name === '404'
  if (is404) {
    return next()
  }

  if (uStore.getUserError) {
    const redirectUrl = `${i18n.locale === 'en' ? '/profileselection/notavailable' : '/profielselectie/nietbeschikbaar'}`
    if (!isSameRoute(to.path, redirectUrl)) {
      return next(redirectUrl)
    }
  }

  if (!uStore.exists) {
    redirectToLogin(to)
    return false
  }

  if (uStore.activeRole) {
    const permissions = to.meta?.permissions || []
    if (
      permissions.length > 0 &&
      !permissions.some((permission) =>
        uStore.activeRolePermissions ? uStore.activeRolePermissions.filter((p: { naam: string }) => p.naam === permission).length > 0 : null,
      )
    ) {
      const redirectUrl = localeLocation({ name: 'profielselectie' }).path

      pStore.setAlerts({
        title: i18n.t('general.error.authentication.insufficientPermission.title', i18n.locale),
        content: i18n.t('general.error.authentication.insufficientPermission.description', i18n.locale),
        closeable: false,
        id: 1,
        type: 'warning',
        pageName: localeLocation({ name: 'profielselectie' }).name,
        scrollTo: true,
      })
      if (!isSameRoute(to.path, redirectUrl)) {
        return next(redirectUrl)
      }
    }
  } else {
    if (to.name.split('___')[0] !== 'profielselectie') {
      const options: Location = { name: 'profielselectie' }
      if (to.query.redirect) {
        options.query = { redirect: to.query.redirect }
      }
      const redirectUrl = localePath(options)
      return next(redirectUrl)
    }
  }
  next()
}
