import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class MoDialog extends Vue {
  title = ''
  message = ''
  resolve = () => {}
  reject = () => {}

  setParams(params) {
    this.title = params.title
    this.message = params.message
    this.resolve = params.resolve
    this.reject = params.reject
  }

  show() {
    this.$root.$emit('modal-toggle', 'leavePage')
  }
  close(shouldResolve: boolean) {
    shouldResolve ? this.resolve() : this.reject()
  }
}
