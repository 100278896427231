import Validation from '@/mixins/validation'

export function childValidator(parent): boolean {
  let valid = true
  parent.$children.forEach((child) => {
    if (/^mo-[a-z, -]+-wrapper$/.test(child.$options._componentTag)) {
      const childValid = (child as Validation).validateInputFields()
      if (!childValid) {
        valid = false
      }
    } else if (childValidator(child) === false) {
      valid = false
    }
  })
  return valid
}

export function clearObject(item: any): boolean {
  let clear = true
  Object.keys(item).forEach((key) => {
    if (item[key] !== null) {
      clear = false
    }
  })
  return clear
}

export function childResetter(parent, skip?: string[], resetLocalvalue = false) {
  parent.$children.forEach((child) => {
    if (skip && skip.length && skip.indexOf(child.$vnode.componentOptions.tag) >= 0) {
      return
    }
    if (/^mo-[a-z, -]+-wrapper$/.test(child.$options._componentTag)) {
      ;(child as Validation).reset()
      if (resetLocalvalue) (child as Validation).resetValue()
    } else {
      childResetter(child, skip, resetLocalvalue)
    }
  })
}

export function objectIsEmpty(objectToTest: HTMLFormElement): boolean {
  let isEmpty: boolean = objectToTest === null || Object.keys(objectToTest).length === 0
  if (!isEmpty) {
    isEmpty = true
    Object.keys(objectToTest).forEach((key) => {
      if (objectToTest[key] !== null && objectToTest[key] !== undefined && objectToTest[key] !== '') {
        isEmpty = false
      }
    })

    if (objectToTest.val === undefined || objectToTest.val === null || objectToTest.val === '') {
      isEmpty = true
    }
  }
  return isEmpty
}

export default {
  runValidations(parent) {
    return childValidator(parent)
  },

  resetAllInputErrorFields(parent, skip) {
    childResetter(parent, skip)
  },

  resetAllInputFields(parent, skip) {
    childResetter(parent, skip, true)
  },

  objectIsEmpty(objectToTest) {
    return Object.keys(objectToTest).length > 0
  },
}
