var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vl-column", { staticClass: "domainIcon" }, [
    _c("svg", [
      _c(
        "use",
        _vm._b(
          {},
          "use",
          { "xlink:href": "/svg/wwoom_icons.svg#" + _vm.icon },
          false
        )
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }