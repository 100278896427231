import { IKeyVal } from '@/declarations/location.models'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validation from '@/mixins/validation'

@Component
export default class MoRadioWrapper extends mixins(validation) {
  @Prop(String)
  name: string

  @Prop({ default: [] as IKeyVal[] })
  keyValues: IKeyVal[]

  @Prop({ default: false })
  modDisabledKeep: boolean

  @Prop()
  value: string

  @Watch('$attrs.value', { immediate: true })
  onValueChanged(val) {
    if (val) {
      this.localValue = val
    }
  }

  @Watch('value', { deep: true })
  valueChanged(value) {
    this.$emit('changed', value)
  }

  clickHandler(_value) {
    this.validateInputFields()
  }
}
