import { AxiosError } from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { stringToNumber } from '@/libs/StringTransformation'
import { isInszError, isValidDateInputFieldsError, noDateBeforeError, noFutureDateError, sanitizedInput } from '../../../assets/validations'
import moment from 'moment'
import UserLocalStorage from '@/localStorage/storage'
import { GACategory, Permission, VipParameters } from '@/libs/constants'
import { ISearchInszResult } from '@/declarations/references.models'
import MoPersonSearchSource from '@/components/persoon/mo-person-search-source/mo-person-search-source.vue'
import { pageStore } from '@/store/page'
import { userStore } from '@/store/user'

export enum InszSearchMode {
  Persoon = 0,
  LED = 1,
  Gezinssamenstelling = 2,
}

export const SEARCH_INSZ_MINIMUM_DATE = '1901-01-01'

class Option {
  key: string
  val: string
  checked: boolean
}

@Component({ components: { MoPersonSearchSource } })
export default class MoSearchInsz extends Vue {
  @Prop({ required: false, type: String })
  value: string = null

  insz: string = this.value || null

  storage: UserLocalStorage = new UserLocalStorage()

  TOGGLE_MODE_LOCAL_STORAGE_KEY = 'persoon-insz_toggle_mode'
  HISTORY_TOOGLE_KEY = 'historyToggle'
  HISTORY_FAMILY_TOOGLE_KEY = 'historyToggle_familyHistory'
  HISTORY_TOGGLE_DATE_KEY = 'historyDateToggle'
  HISTORY_TOGGLE_FAMILY_DATE_KEY = 'historyDateToggle_familyHistory'

  notFound = false
  searching = false

  showHistory: Array<Option> = null
  dateChoice: string = 'vanaf'
  historyDate = null

  source: any = null

  hasMounted: boolean = false
  reference: any = null

  historyOptions: Array<Option> = [
    {
      key: 'toonHistoriek',
      val: 'Toon Historiek',
      checked: false,
    },
  ]
  dateOptions: Array<Option> = [
    { key: 'vanaf', val: 'Vanaf', checked: true },
    { key: 'op', val: 'Op', checked: false },
  ]

  pageStore = pageStore()
  userStore = userStore()
  @Watch('dateChoice', { immediate: true, deep: false })
  onDateChoiceChanged(val: string) {
    if ((!this.historyDate || !this.historyDate.replace(/-/g, '')) && val === 'vanaf') {
      this.historyDate = SEARCH_INSZ_MINIMUM_DATE
    } else if ((!this.historyDate || !this.historyDate.replace(/-/g, '') || this.historyDate === SEARCH_INSZ_MINIMUM_DATE) && val === 'op') {
      this.historyDate = null
    }
    this.saveHistoryToLocalStorage()
  }

  @Watch('showHistory', { immediate: false, deep: true })
  onshowHistoryChanged() {
    this.saveHistoryToLocalStorage()
  }

  options = {
    insz: {
      mandatory: true,
      mask: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, '-', /\d/, /\d/, /\d/, '.', /\d/, /\d/],
      validations: [isInszError],
    },
    showHistory: {
      mandatory: false,
    },
    historyDate: {
      mandatory: true,
      validations: [
        isValidDateInputFieldsError,
        (value: string) => noFutureDateError(value, 'YYYY-MM-DD'),
        (value: string) => noDateBeforeError(value, SEARCH_INSZ_MINIMUM_DATE, 'YYYY-MM-DD'),
      ],
    },
    reference: {
      mandatory: true,
      validations: [sanitizedInput],
    },
  }

  get historyPerson(): boolean {
    return this.userStore.permissionCheckAny(Permission.persoon.geefHistoriekPersoon) && InszSearchMode[this.searchMode] === InszSearchMode.Persoon
  }

  get historyFamily(): boolean {
    return (
      this.userStore.permissionCheckAny(Permission.persoon.geefHistoriekGezinssamenstelling) &&
      InszSearchMode[this.searchMode] === InszSearchMode.Gezinssamenstelling
    )
  }

  get permissionSearchHistory() {
    return this.historyPerson || this.historyFamily
  }

  @Prop({ type: String, default: InszSearchMode[InszSearchMode.Persoon] })
  searchMode: string

  get historyChecked() {
    return this.showHistory && this.showHistory.length && this.showHistory[0].checked === true
  }

  get searchSource(): string {
    return this.historyChecked ? Permission.persoon.geefHistoriekPersoon : Permission.persoon.geefPersoon
  }

  get activeRole() {
    return this.userStore.activeRole ? this.userStore.activeRole?.full : ''
  }

  get clearFieldsLabel(): string {
    if (
      (InszSearchMode[this.searchMode] === InszSearchMode.LED || InszSearchMode[this.searchMode] === InszSearchMode.Gezinssamenstelling) &&
      !this.hasLegalLogReference
    ) {
      return 'Veld wissen'
    } else {
      return 'Velden wissen'
    }
  }

  get showHistoryToggle() {
    return (
      (InszSearchMode[this.searchMode] === InszSearchMode.Persoon || InszSearchMode[this.searchMode] === InszSearchMode.Gezinssamenstelling) &&
      this.permissionSearchHistory
    )
  }

  get userDienst() {
    if (InszSearchMode[this.searchMode] === InszSearchMode.Persoon) {
      if (this.historyChecked) {
        return this.userStore.activeRolePermissions.filter((p: { naam: string }) => p?.naam === Permission.persoon.geefHistoriekPersoon)[0]
      } else {
        return this.userStore.activeRolePermissions.filter((p: { naam: string }) => p?.naam === Permission.persoon.geefPersoon)[0]
      }
    }
    if (InszSearchMode[this.searchMode] === InszSearchMode.Gezinssamenstelling) {
      if (this.historyChecked) {
        return this.userStore.activeRolePermissions.filter((p: { naam: string }) => p?.naam === Permission.persoon.geefHistoriekGezinssamenstelling)[0]
      } else {
        return this.userStore.activeRolePermissions.filter((p: { naam: string }) => p?.naam === Permission.persoon.geefGezinssamenstelling)[0]
      }
    }
    if (InszSearchMode[this.searchMode] === InszSearchMode.LED) {
      return this.userStore.activeRolePermissions.filter((p: { naam: string }) => p?.naam === Permission.LED.geefBewijs)
    }
  }

  get hasLegalLogReference(): boolean {
    if (this.userDienst && this.userDienst.parameters?.length > 0) {
      if (
        this.userDienst.parameters?.filter((p: { naam: string; waarde: string }) => p?.naam === VipParameters.Logging.referentieLegalLog && p.waarde === '1')[0]
      ) {
        return true
      }
    }
    return false
  }

  get hasHistoriekOnly(): boolean {
    switch (InszSearchMode[this.searchMode]) {
      case InszSearchMode.Persoon:
        return !this.userStore.permissionCheckAny(Permission.persoon.geefPersoon) && this.userStore.permissionCheckAny(Permission.persoon.geefHistoriekPersoon)
      case InszSearchMode.Gezinssamenstelling:
        return (
          !this.userStore.permissionCheckAny(Permission.persoon.geefGezinssamenstelling) &&
          this.userStore.permissionCheckAny(Permission.persoon.geefHistoriekGezinssamenstelling)
        )
      default:
        return false
    }
  }

  mounted() {
    if (this.permissionSearchHistory) {
      this.getHistoryToLocalStorage()
    }
    if (this.$refs.focusElement) {
      ;((this.$refs.focusElement as Vue).$el as HTMLElement).focus()
    }
    if (this.hasHistoriekOnly) {
      this.showHistory = [
        {
          key: 'toonHistoriek',
          val: 'Toon Historiek',
          checked: true,
        },
      ]
    }
    this.hasMounted = true
  }

  searchInsz() {
    if (!this.$utils.runValidations(this)) {
      return
    }

    this.searching = true
    this.notFound = false

    if (InszSearchMode[this.searchMode] === InszSearchMode.Persoon) {
      this.$ga.sendEvent(
        GACategory.persoon.category,
        this.historyChecked ? GACategory.persoon.zoekPersoonOpINSZ_MetHistoriek : GACategory.persoon.zoekPersoonOpINSZ_ZonderHistoriek,
        this.activeRole,
        1,
      )
      const mode = this.storage.getItem(this.TOGGLE_MODE_LOCAL_STORAGE_KEY, false)
      const queryDate = this.historyChecked ? { [this.dateChoice]: moment(this.historyDate, 'YYYY-MM-DD').format('D.MM.YYYY') } : null

      let queryMode
      if (this.permissionSearchHistory) {
        queryMode = { variant: mode ? mode : 'uitgebreid' }
      }
      const query = { ...queryDate, ...queryMode }
      if (this.source) {
        query.bron = this.source
      }
      if (this.hasLegalLogReference) {
        query.ref = this.reference
      }

      if (this.historyChecked && this.permissionSearchHistory) {
        this.searchPersoonHistoriek(query)
        this.saveHistoryDateToLocalStorage(this.historyDate)
      } else {
        this.searchPersoon(query)
      }
    } else if (InszSearchMode[this.searchMode] === InszSearchMode.Gezinssamenstelling) {
      this.searchfamilyHistory()
    } else {
      this.$ga.sendEvent(GACategory.LED.category, GACategory.LED.geefBewijs_Overzicht)
      this.searchLED()
    }
  }

  clearAll() {
    this.$utils.resetAllInputErrorFields(this)
    this.notFound = false
    this.dateChoice = 'vanaf'
    this.historyDate = null
    this.historyOptions[0].checked = false
    this.showHistory = null
    this.insz = null
    this.reference = null
  }

  private searchPersoon(query) {
    this.$api
      .getPersonDetailsInsz(this.insz, { compact: query.variant && query.variant !== 'uitgebreid', bron: this.source, reference: query.ref ? query.ref : null })
      .then((person) => {
        this.searching = false
        this.$emit('insz-found', { person, query })
      })
      .catch((error: AxiosError) => {
        this.handleError(error)
      })
  }

  private async searchfamilyHistory() {
    this.$ga.sendEvent(
      GACategory.persoon.category,
      this.historyChecked ? GACategory.persoon.zoekPersoonGezinssamenstelling_MetHistoriek : GACategory.persoon.zoekPersoonGezinssamenstelling,
      this.activeRole,
      1,
    )

    try {
      let familyHistory = null
      const queryData = {}
      if (this.hasLegalLogReference) {
        queryData['ref'] = this.reference
      }
      const queryDate = this.historyChecked ? { [this.dateChoice]: moment(this.historyDate, 'YYYY-MM-DD').format('DD.MM.YYYY') } : null
      const query = { ...queryData, ...queryDate }
      if (this.historyChecked) {
        familyHistory = await this.$api.getPersonFamilyHistoryInsz(this.insz, {
          [this.dateChoice]: moment(this.historyDate, 'YYYY-MM-DD').toISOString(),
          reference: this.reference ? this.reference : null,
        })
        this.saveHistoryDateToLocalStorage(this.historyDate)
      } else {
        familyHistory = await this.$api.getPersonFamilyInsz(this.insz, { reference: this.reference ? this.reference : null })
      }
      this.$emit('insz-found', {
        person: { registration: { insz: this.insz }, familyHistory: familyHistory },
        query: query,
        familyHistory: true,
      })
    } catch (error) {
      this.handleError(error as AxiosError)
    } finally {
      this.searching = false
    }
  }

  private searchPersoonHistoriek(query: { variant: any; ref?: any }) {
    this.$api
      .getPersonHistoryInsz(this.insz, {
        [this.dateChoice]: moment.utc(this.historyDate, 'YYYY-MM-DD').toISOString(),
        compact: query.variant !== 'uitgebreid',
        bron: this.source,
        reference: query.ref ? query.ref : null,
      })
      .then((person) => {
        this.searching = false
        this.$emit('insz-found', { person, query, type: 'history' })
      })
      .catch((error: AxiosError) => {
        this.handleError(error)
      })
  }

  private searchLED() {
    const searchValue = stringToNumber(this.insz)
    this.$api
      .getLedBewijzen(searchValue)
      .then((response) => {
        this.searching = false
        if (response.bewijzen && response.bewijzen.length > 0) {
          this.$emit('insz-found', {
            insz: this.insz,
            data: response,
          } as unknown as ISearchInszResult)
        } else {
          const error: AxiosError = {
            response: {
              status: 404,
              config: null,
              data: null,
              headers: null,
              statusText: '',
            },
            config: null,
            name: '',
            message: '',
            isAxiosError: true,
            toJSON: null,
          }
          throw error
        }
      })
      .catch((error: AxiosError) => {
        this.handleError(error)
      })
  }

  private handleError(error: AxiosError) {
    this.notFound = true
    if (!(error.response && error.response.status === 404)) {
      this.$alert.updateError({
        errorObject: error,
        id: 0,
        pageName: this.$route.name,
        sourceFile: 'MoSearchInsz.vue',
        sourceMethod: 'searchInsz',
      })
    }

    this.pageStore.setPage({
      name: this.$route.name,
      params: {
        personSectionInsz: null,
      },
    })

    this.searching = false
  }

  private saveHistoryToLocalStorage() {
    if (this.hasMounted) {
      if (this.historyChecked) {
        this.storage.setItem(this.historyToggleKey, { choice: this.dateChoice }, true)
      } else {
        this.storage.removeItem(this.historyToggleKey)
      }
    }
  }
  private getHistoryToLocalStorage() {
    const historyToggle = this.storage.getItem(this.historyToggleKey, true)
    const historyDateToggle = this.storage.getItem(this.historyDateToggleKey)

    if (historyToggle) {
      this.dateChoice = historyToggle.choice
      this.showHistory = [
        {
          key: 'toonHistoriek',
          val: 'Toon Historiek',
          checked: true,
        },
      ]
      this.historyOptions[0].checked = true
    }

    if (historyDateToggle) {
      this.historyDate = historyDateToggle
    }
  }

  private saveHistoryDateToLocalStorage(historyDate: string) {
    this.storage.setItem(this.historyDateToggleKey, moment(historyDate).format('YYYY-MM-DD'))
  }

  get historyToggleKey() {
    return this.searchMode === InszSearchMode[InszSearchMode.Gezinssamenstelling] ? this.HISTORY_FAMILY_TOOGLE_KEY : this.HISTORY_TOOGLE_KEY
  }

  get historyDateToggleKey() {
    return this.searchMode === InszSearchMode[InszSearchMode.Gezinssamenstelling] ? this.HISTORY_TOGGLE_FAMILY_DATE_KEY : this.HISTORY_TOGGLE_DATE_KEY
  }
}
