import * as widgetClient from '@govflanders/vl-widget-client'
import { HeaderType, moproStore } from '@/store/mopro'
import { userStore } from '@/store/user'

export async function configureGlobalHeaderAndFooter() {
  if (moproStore().type === HeaderType.Mopro) {
    addWidgetHooks()
  }

  await configureGlobalHeader()
  await configureGlobalFooter()
}

export async function configureGlobalHeader() {
  const headerDefinition = getHeader()
  if (!headerDefinition) return
  try {
    const headerWidget = await widgetClient.bootstrap(headerDefinition)
    headerWidget.setMountElement(document.getElementById('global-header'))
    headerWidget.mount()
  } catch {

  }

}

export async function configureGlobalFooter() {
  const footerDefinition = getFooter()
  if (!footerDefinition ) return
  try{
    const footerWidget = await widgetClient.bootstrap(footerDefinition)
    footerWidget.setMountElement(document.getElementById('global-footer'))
    footerWidget.mount()
  }
  catch {

  }

}

function getHeader() {
  const store = moproStore()
  const headerType = store.type
  switch (headerType) {
    case HeaderType.Documentendienst:
      return store.documentendienstHeader
    default:
      return store.header
  }
}

function getFooter() {
  const store = moproStore()
  const headerType = store.type
  switch (headerType) {
    case HeaderType.Documentendienst:
      return store.documentendienstFooter
    default:
      return store.footer
  }
}

export function addWidgetHooks() {
  const store = userStore()

  widgetClient.capture(async (widget) => {
    if (widget.getPluginTypeId() === 'global_header') {
      widget.getExtension('citizen_profile.session').then(async (session) => {
        session.configure({
          active: store.exists,
          endpoints: {
            loginUrl: AUTH_URLS.loginUrl,
            loginRedirectUrl: AUTH_URLS.loginRedirectUrl,
            logoutUrl: AUTH_URLS.logoutUrl,
            switchCapacityUrl: AUTH_URLS.switchCapacityUrl,
          },
        })

        function activityEventHandler() {
          session.extend()
        }

        const eventNames = ['mousedown', 'mousemove', 'mousewheel', 'DOMMouseScroll', 'scroll', 'wheel', 'keydown', 'keypress', 'touchmove', 'touchstart']

        for (const eventName of eventNames) {
          window.addEventListener(eventName, activityEventHandler)
        }
      })

      widget.on('citizen_profile.session.logout.request', async (logoutRequest) => {
        logoutRequest.acknowledge()

        const reason = logoutRequest.getRequest().getReason()
        switch (reason) {
          case 'expired':
            store.hasActiveUserSession().then((isActive) => {
              if (isActive) {
                logoutRequest.reject()
              } else {
                logoutRequest.accept()
                handleLogout()
              }
            })
            break
          case 'user':
            logoutRequest.accept()
            handleLogout()
            break
          default:
            logoutRequest.reject()
            break
        }
      })
    }
  })
}

const handleLogout = () => {
  userStore().logout()
  configureGlobalHeaderWidgetSession(false) // this is a fix to prevent the global header from showing an incorrect error message.
}

const configureGlobalHeaderWidgetSession = (active: boolean) => {
  widgetClient.capture(
    (widget) => {
      widget.getExtension('citizen_profile.session').then((session) => {
        session.configure({
          active: active,
          endpoints: {
            loginUrl: AUTH_URLS.loginUrl,
            loginRedirectUrl: AUTH_URLS.loginRedirectUrl,
            logoutUrl: AUTH_URLS.logoutUrl,
            switchCapacityUrl: AUTH_URLS.switchCapacityUrl,
          },
        })
      })
    },
    (widget) => widget.getPluginTypeId() === 'global_header',
  )
}

export const AUTH_URLS = {
  loginUrl: '/auth/login',
  loginRedirectUrl: '/',
  logoutUrl: '/auth/signout',
  switchCapacityUrl: '/auth/switch',
}
