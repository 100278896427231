import { pageStore } from '@/store/page'
import { NavigationGuardNext, Route } from 'vue-router'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  const store = pageStore()
  if (from) {
    store.removeAlerts({ pageName: from.name })
  }
  next()
}
