import validation from '@/mixins/validation'
import { mixins } from 'vue-class-component'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class MoMultiselectWrapper extends mixins(validation) {
  @Prop({ required: true, default: [] })
  dropDownOptions: any

  @Prop()
  value: any
}
