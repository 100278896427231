import md5 from 'md5'
import { DomainType, DomainTypePermission, IRole, IState } from '@/declarations/references.models'
import moment from 'moment'
import { defineStore } from 'pinia'
import { API } from '@/plugins/api'
import axios from '@/infrastructure/axios'
import { Role } from '@/api/store'
export const namespaced = true

const api = new API({ $axios: axios }, false)
export const userStore = defineStore('user', {
  state: () => {
    return {
      user: null,
      isGetUserError: null,
      expiresIn: null,
      currentActiveRole: null,
    }
  },
  getters: {
    permissionCheckAny(state: IState) {
      return (permissionsToCheck: any) => {
        if (state.currentActiveRole) {
          const permissions = this.activeRolePermissions
          if (permissions) {
            const permissionsToCheckArray = Array.isArray(permissionsToCheck) ? permissionsToCheck : [permissionsToCheck]
            return permissions.some((permission: { naam: any }) => permissionsToCheckArray.includes(permission.naam))
          }
        }
        return false
      }
    },
    permissionCheckRole(state: IState) {
      return (role: Role, permissionsToCheck: any) => {
        if (role) {
          const permissions = this.permissions(role)
          if (permissions) {
            const permissionsToCheckArray = Array.isArray(permissionsToCheck) ? permissionsToCheck : [permissionsToCheck]
            return permissions.some((permission: { naam: any }) => permissionsToCheckArray.includes(permission.naam))
          }
        }
        return false
      }
    },

    activeRole: (state: IState) => {
      if (state.currentActiveRole) {
        return { ...state.currentActiveRole }
      }
      return null
    },

    givenName: (state: IState) => {
      return state.user?.givenName
    },

    familyName: (state: IState) => {
      return state.user?.familyName
    },

    language: (state: IState) => {
      return state.user?.taalkeuze
    },

    serviceDeskUrl: (state: IState) => {
      return state.user?.serviceDeskUrl
    },

    roles: (state: IState) => {
      return state.user?.roles
    },

    exists: (state: IState) => {
      return !!state.user
    },

    expires: (state: IState) => {
      return state.expiresIn
    },

    isExpired: (state: IState) => {
      return state.user && state.expiresIn ? calculateTimeBeforeExpirationInMilliseconds(state.expiresIn) <= 0 : null
    },

    timeBeforeExpirationInMilliseconds: (state: IState): number => {
      return calculateTimeBeforeExpirationInMilliseconds(state.expiresIn)
    },

    hashedRrn: (state: IState) => {
      if (state.user?.rrn) {
        return md5(state.user.rrn)
      }
      return ''
    },

    getUserError: (state: IState) => {
      return state?.isGetUserError
    },

    sub: (state: IState) => {
      return state.user?.sub
    },

    organisationCode: (state: IState) => {
      return state.user?.organisatieCode
    },

    nisCode: (state: IState) => {
      return state.user?.nisCode
    },

    targetGroupCode: (state: IState) => {
      return state.user?.doelgroepCode
    },

    domains: (state: IState) => {
      return (role: { full: string }) => {
        const domains = state.user?.roles.filter((stateRole) => stateRole.full === role.full)[0].domains.map((d) => DomainType[d]?.toLowerCase())
        return domains || []
      }
    },
    permissions: (state: IState) => (role: { full: string }) => {
      const permissions = state.user?.roles.filter((stateRole) => stateRole.full === role.full)[0].permissions
      return permissions || []
    },

    misconfiguredRoles: (state: IState) => {
      return state.user?.misconfiguredRoles
    },

    activeRoleMap: (state: IState) => {
      const maps: { domain: DomainType; permissions: any }[] = []
      if (state.user?.roles && state.currentActiveRole) {
        const role = state.user?.roles.filter((stateRole) => stateRole.full === state.currentActiveRole.full)[0]
        if (role?.domains) {
          const domains = role.domains.map((d) => DomainType[d])

          domains.forEach((domain) => {
            const permissions = role.permissions
              ? role.permissions
                  .filter((p: { naam: string }) => DomainTypePermission[p.naam.split('.')[0].toLowerCase()] === domain)
                  .map((p: { naam: any }) => p.naam)
              : []
            maps.push({ domain, permissions })
          })
        }
      }
      return maps
    },

    activeRolePermissions: (state: IState) => {
      if (state.currentActiveRole) {
        const role = state.user?.roles.filter((stateRole) => stateRole.full === state.currentActiveRole.full)[0]
        if (role && role.permissions && role.permissions.length) {
          return role.permissions
        }
        return []
      }
      return []
    },
  },
  actions: {
    async setActiveRole(activeRole: IRole) {
      if (activeRole) {
        const response = await api.setActiveRole(activeRole.full)
        this.currentActiveRole = { ...activeRole, ...response }
      } else {
        this.currentActiveRole = null
      }
    },
    async hasActiveUserSession(): Promise<boolean> {
      let user = null
      try {
        user = await api.getUser()
        return !!user
      } catch (error) {
        return false
      }
    },
    setExpires(expires: string) {
      this.expiresIn = expires
    },
    async init() {
      let user = null
      try {
        user = await api.getUser()
        if (user) {
          this.isGetUserError = false
          this.user = user
        }
      } catch (error: any) {
        const states = [401, 403]
        if (!states.some((status) => error?.response?.status === status)) {
          this.isGetUserError = true
          return Promise.resolve()
        }
      }
      try {
        const activeRole = await api.getActiveRole()
        if (activeRole) {
          this.currentActiveRole = { ...activeRole, full: activeRole.origineleMagdaRol }
        }
      } catch (error) {
        // do nothing
      }

      return Promise.resolve()
    },

    async logout() {
      try {
        this.currentActiveRole = { role: null }
        this.user = null
        return Promise.resolve()
      } catch (error) {
        Promise.reject(error)
      }
    },
  },
})

function calculateTimeBeforeExpirationInMilliseconds(expires: string) {
  if (expires) {
    const date = new Date(expires)
    if (!isNaN(date.valueOf())) {
      return moment.duration(moment(date).diff(moment())).asMilliseconds()
    }
  }
  return null
}
