import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MoSelect extends Vue {
  @Prop(String)
  placeholderText: string

  @Prop(String)
  id: string

  @Prop(Boolean)
  modBlock: boolean

  @Prop(String)
  value: string

  @Prop(Boolean)
  hidePlaceholder: boolean

  get classes() {
    return ['vl-select', this.modBlock ? 'vl-select--block' : '']
  }

  get inputValue() {
    return this.value
  }

  set inputValue(newValue) {
    this.$emit('input', newValue)
  }

  get placeholderLabel() {
    return this.placeholderText ? this.placeholderText : 'Selecteer een optie'
  }

  mounted() {
    window.setTimeout(() => {
      this.inputValue = this.value
    }, 0)
  }
}
