var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vl-modal",
    {
      directives: [
        {
          name: "vl-modal-toggle",
          rawName: "v-vl-modal-toggle",
          value: "leavePage",
          expression: "'leavePage'",
        },
      ],
      ref: "vlModal",
      attrs: { id: "leavePage", title: _vm.title },
    },
    [
      _c(
        "vl-grid",
        { attrs: { "mod-stacked": "" } },
        [
          _c("vl-column", [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
          ]),
          _c(
            "vl-column",
            [
              _c(
                "vl-action-group",
                [
                  _c(
                    "vl-button",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.close(true)
                        },
                      },
                    },
                    [_vm._v(" Verlaat pagina ")]
                  ),
                  _c(
                    "vl-button",
                    {
                      attrs: { "mod-secondary": "" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.close(false)
                        },
                      },
                    },
                    [_vm._v(" Blijf op deze pagina ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }