import Vue from 'vue'
import VueMeta from 'vue-meta'
import { createDecorator } from 'vue-class-component'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
})

export const Meta = createDecorator((options, key) => {
  if (!options.methods) return
  options['metaInfo'] = options.methods[key]
})
