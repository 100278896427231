import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class MoInputPill extends Vue {
  @Prop(Boolean)
  modError: boolean

  @Prop(Boolean)
  modDisabled: boolean

  @Prop(Boolean)
  modSmall: boolean

  @Prop(Boolean)
  modInline: boolean

  @Prop(Boolean)
  modBlock: boolean

  @Prop({ default: true })
  showMask: boolean

  @Prop({ default: [] })
  value: string[]

  @Prop({ default: 'text' })
  type: string

  @Prop({ default: null })
  name: string

  @Prop({ default: null })
  id: string

  @Prop({ default: undefined })
  mask: string[]

  @Prop({ default: false })
  guide: boolean

  @Prop({ default: ' ' })
  placeholderChar: string

  @Prop({ default: null })
  autocomplete: string

  @Prop(String)
  placeholder: string

  get classes() {
    return [
      'vl-input-field',
      { 'vl-input-field--error': this.modError },
      { 'vl-input-field--disabled': this.modDisabled },
      { 'vl-input-field--small': this.modSmall },
      { 'vl-input-field--inline': this.modInline },
      { 'vl-input-field--block': this.modBlock },
    ]
  }

  get inputValue(): string[] {
    return this.value ? this.value : []
  }

  set inputValue(newValue: string[]) {
    this.$emit('input', newValue)
  }

  inputPill(value: string[]) {
    this.$emit('input', value)
  }
}
