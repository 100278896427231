import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { regularExpression, textStrict } from '@/assets/validations'
import { IList, ITown, IValidationOptions } from '@/declarations/references.models'

export interface IAddress {
  prefix?: string
  name?: string
  firstnames?: string
  organisation?: string
  country?: string
  countryName?: string
  townObject: ITown
  town: string
  zip: string
  street?: string
  streetObject?: IList
  streetNumber?: string
  streetNumberBox?: string
}

@Component
export default class AddressForm extends Vue {
  attrsObject = JSON.parse(this.$attrs.value)

  prefix = this.attrsObject ? this.attrsObject.prefix : null
  name = this.attrsObject ? this.attrsObject.name : null
  firstnames = this.attrsObject ? this.attrsObject.firstnames : null
  organisation = this.attrsObject ? this.attrsObject.organisation : null
  countryList: IList[] = [
    {
      val: '150',
      label: 'België',
      selected: true,
    },
  ]
  country = this.attrsObject ? this.attrsObject.country : '150'
  countryName = this.attrsObject ? this.attrsObject.countryName : 'België'
  townList: ITown[] = []
  townObject: ITown = this.attrsObject ? this.attrsObject.townObject : null
  streetList: IList[] = []
  streetObject: IList = this.attrsObject ? this.attrsObject.streetObject : null
  streetNumber: string = this.attrsObject ? this.attrsObject.streetNumber : ''
  streetNumberBox: string = this.attrsObject ? this.attrsObject.streetNumberBox : ''
  searching = false

  options: IValidationOptions = {
    prefix: {
      mandatory: false,
    },
    name: {
      mandatory: false,
      validations: [
        textStrict,
        {
          func: regularExpression,
          params: [/^.{0,128}$/, 'Achternaam mag maximum 128 karakters lang zijn'],
        },
      ],
    },
    firstnames: {
      mandatory: false,
      validations: [
        textStrict,
        {
          func: regularExpression,
          params: [/^.{0,48}$/, 'Voornaam mag maximum 48 karakters lang zijn'],
        },
      ],
    },
    organisation: {
      mandatory: false,
    },
    country: {
      mandatory: false,
    },
    town: {
      mandatory: true,
    },
    street: {
      mandatory: true,
    },
    streetNumber: {
      mandatory: false,
      validations: [
        {
          func: regularExpression,
          params: [/^[0-9]{1,5}[a-zA-Z]?$/, 'Moet een geldig huisnummer zijn'],
        },
      ],
    },
    streetNumberBox: {
      mandatory: false,
    },
  }

  @Prop(Boolean)
  modAutocomplete: boolean

  @Prop({ default: '', type: String, required: true })
  namespace: string

  @Watch('townObject', { immediate: false, deep: true })
  public onTownChanged() {
    this.streetObject = null
    this.streetList = []
  }

  @Watch('address', { immediate: false, deep: true })
  public onAddressChanged(val: unknown) {
    this.$emit('input', val)
  }

  get address(): IAddress {
    return {
      prefix: this.prefix,
      name: this.name,
      firstnames: this.firstnames,
      organisation: this.organisation,
      country: this.country ? this.country : null,
      countryName: this.countryName ? this.countryName : null,
      townObject: this.townObject ? this.townObject : null,
      town: this.townObject ? this.townObject.name : null,
      zip: this.townObject ? this.townObject.zip : null,
      street: this.streetObject ? this.streetObject.val : null,
      streetObject: this.streetObject ? this.streetObject : null,
      streetNumber: this.streetNumber,
      streetNumberBox: this.streetNumberBox,
    }
  }

  updateTownList(filter: string) {
    if (filter.length >= 2) {
      this.$api.getTown(filter).then((townList) => {
        this.townList = townList
      })
    } else if (filter.length < 2) {
      this.townList = []
    }
  }

  updateStreetList(partOfStreetName: string) {
    if (partOfStreetName.length >= 2) {
      if (this.townObject && this.townObject.name) {
        this.$api.getStreet(this.townObject.zip, this.townObject.val, partOfStreetName).then((streetList) => {
          this.streetList = streetList.map((street) => {
            return { label: street.naam, val: street.naam }
          })
        })
      } else if (partOfStreetName.length < 2) {
        this.streetList = []
      }
    } else {
      this.streetList = []
    }
  }

  fieldRef(value: string) {
    return `${this.namespace}_${value}`
  }
}
