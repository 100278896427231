import { Component, Provide, Prop, Vue } from 'vue-property-decorator'

export enum MoFormfieldDecoratorLayout {
  horizontal = 'horizontal',
  vertical = 'vertical',
}
@Component
export default class MoFormfieldDecorator extends Vue {
  message: string = ''
  mandatory: boolean = false
  disabled: boolean = false

  @Provide()
  setMandatory = this.setMandatoryFunc
  @Provide()
  setDisabled = this.setDisabledFunc
  @Provide()
  updateErrorMessage = this.updateErrorMessageFunc

  updateErrorMessageFunc(message: string) {
    this.message = message
    if (this.message) {
      this.$el.scrollIntoView(false)
    }
  }

  setDisabledFunc(disabled: boolean) {
    this.disabled = disabled
  }

  setMandatoryFunc(mandatory: boolean) {
    this.mandatory = mandatory
  }

  @Prop({ default: '', type: String })
  label: string

  @Prop({ default: MoFormfieldDecoratorLayout.vertical })
  layout: MoFormfieldDecoratorLayout

  get required(): string[] {
    const classes: string[] = []
    if (this.mandatory) {
      classes.push('mandatory')
      if (this.disabled) {
        classes.push('mandatory--disabled')
      } else {
        classes.push('mandatory--enabled')
      }
    }
    return classes
  }

  get maxLengthClasses(): string[] {
    const classes: string[] = ['vl-u-text--small']
    if (this.remainingChars < 0) {
      classes.push('over-limit')
    }
    return classes
  }

  @Prop({ required: false, type: Number })
  maxLength: number

  @Prop({ required: false, type: String, default: '' })
  value: string

  get remainingChars(): number {
    return this.value ? this.maxLength - this.value.length : this.maxLength
  }
}
