import { IBewijsToevoegenData, IBewijsMetadataResult, IDownloadMetadataRequest } from './../declarations/leer_en_ervaring.models'
import { AxiosResponse, AxiosStatic, CancelToken } from 'axios'
import moment from 'moment'
import * as MoProDto from '@/api/mopro-api.dto'
import { IVerstuurDocumentResponse } from '@/declarations/documentendienst/bulk.models'
import { IGeefPersoonHistoriek } from '@/components/persoon/mo-person-data/Person.models'
import {
  IActiveRole,
  IEboxActief,
  IPersonNameRequest,
  IResponsePersonAddress,
  IResponsePersonName,
  ISearchParamsMessages,
  ITown,
} from '@/declarations/references.models'
import { IPersonToSave } from '@/declarations/persoon/persoon.models'
import {
  SearchHealthAttestationData,
  SearchHealthAttestationsResponse,
  SendAttestationDocumentRequestData,
} from '@/components/attesten/healthAttestation.mappings'
import { IGeefOndernemingResponse } from '@/declarations/documentendienst/onderneming.models'
import moLogger, { ILog } from '@/libs/MoLogger'
import { MagdaDate } from '@/declarations/persoon/persoon.mappers'
import { IDownloadVerkoopprijzenRequest } from '@/declarations/kadaster/kadaster.models'
;(moment as any).defaultFormat = moment.defaultFormatUtc

export function sendLog(axios: unknown, log: ILog) {
  moLogger(log)
}

interface Nationality {
  selected: boolean
  text: string
  val: string
}

interface Nationalities {
  key: number
  nationality: Nationality
  since: string
  until: string
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function isHiddenPermision(permission) {
  return false
}

class API {
  headers: any
  $axios: AxiosStatic
  redirect

  constructor(app, redirect) {
    this.$axios = app.$axios
    this.redirect = redirect
  }

  sendLog(log: ILog) {
    sendLog(this.$axios, log)
  }

  getUser(): Promise<any> {
    return this.$axios.get('/sss/api/backend/user').then((response) => {
      return Promise.resolve(response.data)
    })
  }

  async getActiveRole(): Promise<IActiveRole> {
    return this.$axios.get('/sss/api/backend/user/activeRole').then((response) => {
      return Promise.resolve(response.data)
    })
  }

  async setActiveRole(uri: string): Promise<IActiveRole> {
    try {
      await this.$axios.post('/account/activeRole', { uri })
      return await this.getActiveRole()
    } catch (error) {
      return null
    }
  }

  refreshSession(): Promise<any> {
    return this.$axios.get('/sss/api/refresh').then((response) => {
      return Promise.resolve(response)
    })
  }

  getServices(): Promise<{ diensten: string[] }> {
    return this.$axios.get('/sss/api/backend/codetype/magdadiensten').then((response) => {
      return response.data
    })
  }

  getMaritalStatus(): Promise<Array<{ code: string; naam: string }>> {
    return this.$axios.get('/sss/api/backend/codetype/burgerlijkestaten').then((response) => {
      return response.data.burgerlijkeStaten
    })
  }

  getCountryList(date: string, type: 'woonplaats' | 'nationaliteiten' | 'geboorteplaats' = 'woonplaats'): Promise<Array<{ code: string; naam: string }>> {
    return this.$axios
      .get(`/sss/api/backend/codetype/landen/${type}`, {
        params: { datum: date },
      })
      .then((response) => {
        return response.data.landen
      })
  }

  getContactadressType(): Promise<Array<{ code: string; naam: string }>> {
    return this.$axios.get(`/sss/api/backend/codetype/contactadrestype`).then((response) => {
      return response.data.contactadresTypes
    })
  }

  getNationality(date: string = null): Promise<Array<{ code: string; naam: string }>> {
    return this.$axios
      .get('/sss/api/backend/codetype/landen/nationaliteit', {
        params: { datum: date },
      })
      .then((response) => {
        return response.data.nationaliteiten
      })
  }

  getTown(town: string, emptyEnabled: boolean = false): Promise<ITown[]> {
    const query = town || emptyEnabled ? town.trim() : null
    if (!query && !emptyEnabled) {
      return new Promise((resolve) => resolve([]))
    }

    return this.$axios
      .get('/sss/api/backend/gemeente/' + query, {
        headers: this.headers,
      })
      .then((response) => {
        return response.data.gemeentes.map((townApi: { gemeente: string; nisCode: string; postCode: string }) => ({
          key: townApi.nisCode + townApi.gemeente + townApi.postCode,
          label: `${townApi.gemeente} (${townApi.postCode})`,
          name: townApi.gemeente,
          val: townApi.nisCode,
          zip: townApi.postCode,
        }))
      })
  }

  getStreet(townZip: string, townNis: string, street: string, params?: any): Promise<Array<MoProDto.Straat3>> {
    const url = `/sss/api/backend/gemeente/${townZip}/${townNis}/straat/${street}`
    return this.$axios.get(url, { params: params }).then((response: AxiosResponse<MoProDto.ZoekStratenOpGemeenteResponse>) => {
      return response.data.straten
    })
  }

  getPersonInsz(insz: string, params: Record<string, unknown>): Promise<MoProDto.IGeefPersoonResponse> {
    return this.$axios
      .get('/sss/api/backend/person/' + insz.match(/\d+/g).join(''), {
        params: params,
      })
      .then((response) => {
        return response.data
      })
  }

  getPersonDetailsInsz(insz: string, params: Record<string, unknown>): Promise<IGeefPersoonHistoriek> {
    return this.$axios.get('/sss/api/backend/person/' + insz.match(/\d+/g).join('') + '/details', { params: params }).then((response) => {
      return response.data
    })
  }

  getPersonAddressesInsz(insz: string, params: Record<string, unknown>): Promise<MoProDto.IGeefPersoonAdresResponse> {
    return this.$axios.get('/sss/api/backend/person/' + insz.match(/\d+/g).join('') + '/adres', { params: params }).then((response) => {
      return response.data
    })
  }

  getPersonHistoryInsz(insz: string, params: Record<string, unknown>): Promise<IGeefPersoonHistoriek> {
    return this.$axios.get('/sss/api/backend/person/' + insz.match(/\d+/g).join('') + '/historiek', { params: params }).then((response) => {
      return response.data
    })
  }

  getPersonFamilyHistoryInsz(insz: string, params: Record<string, unknown>): Promise<any> {
    return this.$axios.get('/sss/api/backend/person/' + insz.match(/\d+/g).join('') + '/gezinssamenstelling/historiek', { params: params }).then((response) => {
      return response.data
    })
  }

  getPersonFamilyInsz(insz: string, params?: Record<string, unknown>): Promise<any> {
    return this.$axios.get('/sss/api/backend/person/' + insz.match(/\d+/g).join('') + '/gezinssamenstelling', { params: params }).then((response) => {
      return response.data
    })
  }

  getOrganisationKBO(kbo: string): Promise<IGeefOndernemingResponse> {
    return this.$axios.get('/sss/api/backend/onderneming/' + kbo.match(/\d+/g).join('')).then((response) => {
      return response.data
    })
  }

  /**
   *
   * @param lastname
   * @param firstname
   * @param birthDate6
   * @param gender
   */
  getPersonName(person: IPersonNameRequest, orderBy, order): Promise<IResponsePersonName> {
    orderBy = orderBy ? orderBy : 'ACHTERNAAM'
    order = order ? order : 'Desc'
    return this.$axios
      .get(`/sss/api/backend/person/${person.lastname}/${MagdaDate(person.birthdate)}`, {
        params: {
          reference: person.reference ? person.reference : null,
          geslacht: person.gender ? person.gender : null,
          niscode: person.pResidenceTownObject ? person.pResidenceTownObject.val : null,
          tolerantie: person.tolerance,
          voornaam: person.firstname,
          bron: person.source,
          orderBy,
          order,
        },
      })
      .then((response) => {
        return response.data
      })
  }

  getPersonByAddress(
    townNis: string,
    townZip: string,
    street: string,
    streetNumber: string,
    busNumber: string,
    source: string,
    reference: string,
  ): Promise<IResponsePersonAddress> {
    return this.$axios
      .get(`/sss/api/backend/person/${townNis}/${townZip}/${street}/${streetNumber}`, {
        params: {
          busnummer: busNumber,
          bron: source,
          reference: reference,
        },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  postPerson(person: IPersonToSave): Promise<any> {
    return this.$axios.post('/sss/api/backend/person', person).then((response) => {
      return Promise.resolve(response)
    })
  }

  verstuurEmail(form): Promise<any> {
    return this.$axios
      .post('/sss/api/backend/document/verstuur/email', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  getIsEboxActive(isEboxActief: IEboxActief, cancelConsentToken: CancelToken = null): Promise<boolean> {
    return this.$axios
      .get(`/sss/api/backend/document/ebox/actief/${isEboxActief.eboxType}/${isEboxActief.nummer}`, {
        cancelToken: cancelConsentToken,
      })
      .then((response: AxiosResponse<boolean>) => {
        return response.data
      })
  }

  getIsoCountries(): Promise<MoProDto.IIsoLand[]> {
    return this.$axios.get('/sss/api/backend/codetype/landen/iso/brief').then((response: AxiosResponse<MoProDto.GetIsoLandenResponse>) => {
      return response.data.landen
    })
  }

  searchLocation(
    query: string,
    townNis: string,
  ): Promise<
    [
      {
        suggestie: string
        adres: {
          straat: string
          nummer: string
          postCode: string
          bus: string
          gemeente: string
        }
      },
    ]
  > {
    return this.$axios.get(`/sss/api/backend/locatie/${query}/${townNis}`).then((response) => {
      return response.data.suggesties
    })
  }

  getDIVIsoCountries(): Promise<MoProDto.IIsoLand[]> {
    return this.$axios.get('/sss/api/backend/codetype/landen/iso/mobiliteit').then((response: AxiosResponse<MoProDto.GetIsoLandenResponse>) => {
      return response.data.landen
    })
  }

  searchGemeentes(query: string): Promise<MoProDto.IZoekGemeentenItem[]> {
    query = query ? query.trim() : query
    if (!query) {
      return new Promise((resolve) => resolve([]))
    }

    return this.$axios.get(`/sss/api/backend/gemeente/${query}`).then((response: AxiosResponse<MoProDto.IZoekGemeentenResponse>) => {
      return response.data.gemeentes
    })
  }

  searchMessages(params: ISearchParamsMessages, cancelToken: CancelToken = null): Promise<MoProDto.IZoekDocumentResponse> {
    return this.$axios.post(`/sss/api/backend/document/zoek`, params, { cancelToken }).then((response) => {
      return response.data
    })
  }

  searchPostalCode(street: string, city: string): Promise<[{ postCode: string }]> {
    return this.$axios.get(`/sss/api/backend/gemeente/${city}/straat/${street}`).then((response) => {
      return response.data.postcodes
    })
  }

  downloadDocuments(id: string, token: string, recaptchaToken: string): Promise<any> {
    return this.$axios
      .get(`/sss/public/api/backend/document/download/bestand/${id}?token=${token}`, {
        responseType: 'arraybuffer',
        headers: {
          'document-nonce': token,
          'g-recaptcha-response': recaptchaToken,
        },
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  searchHealthAttestations(params: SearchHealthAttestationData): Promise<SearchHealthAttestationsResponse> {
    return this.$axios
      .post(`/sss/api/backend/healthattestation/search/`, {
        searchMode: params.searchMode,
        issuedAfter: params.issuedAfter,
        issuedBefore: params.issuedBefore,
        changedAfter: params.changedAfter,
        attestationTypes: params.attestationTypes,
        activeOnly: params.activeOnly,
        insz: params.insz ? params.insz.match(/\d+/g).join('') : params.insz,
        firstName: params.firstName,
        lastName: params.lastName,
        birthdate: params.birthdate,
        attestationsStates: params.attestationsStates,
        orderBy: params.orderBy,
        order: params.order,
        take: params.take,
        skip: params.skip,
        attestationIdBase64urlEncoded: params.attestationIdBase64urlEncoded,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  searchCTPC(params: MoProDto.ISearchCTPCAttestationData): Promise<MoProDto.ISearchCTPCAttestationsResponse> {
    return this.$axios
      .post(`/sss/api/backend/ctpcattestation/search/`, {
        firstName: params.firstName,
        lastName: params.lastName,
        birthdate: params.birthdate,
        covidTestPrescriptionCode: params.covidTestPrescriptionCode,
        phoneNumber: params.phoneNumber,
        take: params.take,
        skip: params.skip,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  sendAttestationDocumentRequest(id: string, params: SendAttestationDocumentRequestData): Promise<void> {
    return this.$axios.post(`/sss/api/backend/healthattestation/${id}/sendDocumentRequest`, params).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getDocumentById(id: string, params = null): Promise<MoProDto.IGeefDocumentResponse> {
    const config = params ? { params } : null
    return this.$axios.get(`/sss/api/backend/document/${id}`, config).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  downloadAttachment(id: string, fileName: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/document/${id}/downloadAttachment?attachmentName=${encodeURIComponent(fileName)}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  getDeliveryAttest(id: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/document/${id}/attest`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  getDocumentStatusById(documentId: string): Promise<AxiosResponse> {
    return this.$axios.post(`/sss/api/backend/document/${documentId}/status`).then((response) => {
      return Promise.resolve(response)
    })
  }

  validateFile(data): Promise<MoProDto.IValidateBulkResponse> {
    return this.$axios
      .post(`/sss/api/backend/document/ontvanger/upload`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  downloadResult(request): Promise<any> {
    return this.$axios
      .post(
        `/sss/api/backend/document/ontvanger/download`,
        { ontvangers: request },
        {
          responseType: 'arraybuffer',
        },
      )
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  downloadBulkOntvangerValidation(jobId: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/document/bulk/${jobId}/validatie/download`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  downloadSimpleBulkResult(jobId: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/document/bulk/${jobId}/result/download`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  verstuurAutomatic(data): Promise<IVerstuurDocumentResponse> {
    return this.$axios
      .post(`/sss/api/backend/document/verstuur/automatic`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  verstuurBrief(data): Promise<IVerstuurDocumentResponse> {
    return this.$axios
      .post(`/sss/api/backend/document/verstuur/brief`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  verstuurEbox(data): Promise<IVerstuurDocumentResponse> {
    return this.$axios
      .post(`/sss/api/backend/document/verstuur/ebox`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }
  verstuurPaperAndEbox(data): Promise<any> {
    return this.$axios
      .post(`/sss/api/backend/document/verstuur/paperAndEbox`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  verstuurBulk(data, fileName: string): Promise<MoProDto.VerstuurBulkResponse> {
    return this.$axios
      .post(`/sss/api/backend/document/verstuur/bulk/${fileName}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  verstuurBulkResultaat(jobId: string, params: Record<string, unknown>): Promise<MoProDto.VerstuurBulkResultaatResponse> {
    return this.$axios.get(`/sss/api/backend/document/verstuur/bulk/${jobId}/resultaat`, { params: params }).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getLedBewijzen(insz: string): Promise<MoProDto.IGeefBewijsResponse> {
    return this.$axios.get(`/sss/api/backend/led/${insz}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getDomesticVehicle(params: Record<string, unknown>): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/mobiliteit/voertuig/binnenland`, {
        params: params,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  getForeignVehicle(params: Record<string, unknown>): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/mobiliteit/voertuig/buitenland`, {
        params: params,
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  sendAttestation(id: string, data): Promise<any> {
    return this.$axios.post(`/sss/api/backend/healthattestation/${id}/sendDocumentRequest`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  recreateAttestations(insz: string, revokeCertificates: boolean): Promise<any> {
    return this.$axios
      .post('/sss/api/backend/healthattestation/' + insz.match(/\d+/g).join('') + '/recreate', { revokeCertificates: revokeCertificates })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  getAttestMetaData(id: string): Promise<any> {
    return this.$axios.get(`/sss/api/backend/healthattestation/${id}/metadata/blocks`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  generateAttestDocument(id: string): Promise<any> {
    return this.$axios.post(`/sss/api/backend/healthattestation/${id}/generateDocument`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  downloadAttestDocument(id: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/healthattestation/${id}/downloadDocument`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  getCTPCMetaData(id: string): Promise<any> {
    return this.$axios.get(`/sss/api/backend/ctpcattestation/${id}/metadata/blocks`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  generateCTPCDocument(id: string): Promise<any> {
    return this.$axios.post(`/sss/api/backend/ctpcattestation/${id}/generateDocument`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  downloadCTPCDocument(id: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/ctpcattestation/${id}/downloadDocument`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  sendLatestAttestation(data): Promise<any> {
    return this.$axios.post(`/sss/api/backend/healthattestation/sendLatestAttestation`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getBewijsMetaData(instantieId: string, doelgroep: string): Promise<IBewijsMetadataResult> {
    return this.$axios.get(`/sss/api/backend/led/${instantieId}/${doelgroep}/metadata`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  bewijsToevoegen(data: IBewijsToevoegenData): Promise<MoProDto.IBewijsToevoegenResponse> {
    return this.$axios.post(`/sss/api/backend/led/toevoegen`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  annuleerBewijs(id: string, insz: string, leverancierNaam: string): Promise<MoProDto.IAnnuleerBewijsResponse> {
    return this.$axios.delete(`/sss/api/backend/led/${id}/${insz}/${leverancierNaam}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  validateBulkBewijs(data): Promise<MoProDto.IValidateBulkResponse> {
    return this.$axios
      .post(`/sss/api/backend/led/bulk/validate`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  downloadBulkBewijsResult(key: string): Promise<any> {
    return this.$axios

      .get(`/sss/api/backend/led/bulk/${key}/result/download`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  downloadBulkBewijsValidation(jobId: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/led/bulk/${jobId}/validatie/download`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  downloadMetadata(request: IDownloadMetadataRequest): Promise<any> {
    return this.$axios
      .post(`/sss/api/backend/led/bulk/metadata/download`, request, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  toevoegenBulkBewijs(data, fileName: string): Promise<MoProDto.BulkToevoegenResponse> {
    return this.$axios
      .post(`/sss/api/backend/led/bulk/toevoegen/${fileName}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  toevoegenBulkBewijsResultaat(jobId: string, params: Record<string, unknown>): Promise<MoProDto.IBulkToevoegenResultaatResponse> {
    return this.$axios.get(`/sss/api/backend/led/bulk/toevoegen/${jobId}/resultaat`, { params: params }).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  bulkJobOverview(params: Record<string, unknown>): Promise<MoProDto.IJobOverviewResponse> {
    return this.$axios.get(`/sss/api/backend/led/bulk/job/overview`, { params: params }).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  bulkDocumentJobOverview(params: Record<string, unknown>): Promise<MoProDto.IJobOverviewResponse> {
    return this.$axios.get(`/sss/api/backend/document/bulk/job/overview`, { params: params }).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getRapporten(): Promise<any> {
    return this.$axios.get(`/sss/api/backend/rapportering`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getRapport(workspaceId: string, reportId: string): Promise<any> {
    return this.$axios.get(`/sss/api/backend/rapportering/rapport/${workspaceId}/${reportId}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  addOrganisation(data: MoProDto.ICreateOrganisationData): Promise<MoProDto.ICreateOrganisationResponse> {
    return this.$axios.post(`/sss/api/backend/docprovider/organisations`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  updateOrganisation(organisationId: string, data: MoProDto.IUpdateOrganisationData): Promise<MoProDto.IUpdateOrganisationResponse> {
    return this.$axios.put(`/sss/api/backend/docprovider/organisations/${organisationId}`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getOrganisations(): Promise<MoProDto.IGetAllOrganisationsResponse> {
    return this.$axios.get(`/sss/api/backend/docprovider/organisations`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getOrganisation(organisationId: string): Promise<MoProDto.IGetOrganisationResponse> {
    return this.$axios.get(`/sss/api/backend/docprovider/organisations/${organisationId}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  deleteOrganisation(organisationId: string): Promise<MoProDto.IDeleteOrganisationResponse> {
    return this.$axios.delete(`/sss/api/backend/docprovider/organisations/${organisationId}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getMessageTypes(): Promise<MoProDto.IGetMessageTypesResponse> {
    return this.$axios.get(`/sss/api/backend/docprovider/messagetypes`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  createMessageTypes(data): Promise<any> {
    return this.$axios.post(`/sss/api/backend/docprovider/messagetypes`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  updateMessageTypes(id, data): Promise<any> {
    return this.$axios.put(`/sss/api/backend/docprovider/messagetypes/${id}`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  removeMessageType(id): Promise<any> {
    return this.$axios.delete(`/sss/api/backend/docprovider/messagetypes/${id}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getApplications(orgId: string): Promise<any> {
    return this.$axios.get(`/sss/api/backend/docprovider/applications?organisationId=${orgId}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getApplicationLogo(applicationId: string, imageId: string): Promise<any> {
    return this.$axios.get(`/sss/api/backend/docprovider/applications/${applicationId}/images/${imageId}/content`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  createApplication(data): Promise<any> {
    return this.$axios
      .post(`/sss/api/backend/docprovider/applications`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  updateApplication(id, data): Promise<any> {
    return this.$axios
      .put(`/sss/api/backend/docprovider/applications/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  updateApplicationMessageTypes(id, data) {
    return this.$axios.put(`/sss/api/backend/docprovider/applications/${id}/messageTypes`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  removeApplication(id): Promise<any> {
    return this.$axios.delete(`/sss/api/backend/docprovider/applications/${id}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getSocialStatusConfiguration(): Promise<MoProDto.IGetSocialStatusConfigurationResponse> {
    return this.$axios.get(`/sss/api/backend/socialsecurity/socialstatus/configuration`).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getSocialStatus(insz: string, data: MoProDto.IGetSocialStatusData): Promise<MoProDto.IGetSocialStatusResponse> {
    return this.$axios.post(`/sss/api/backend/socialsecurity/socialstatus/${insz}`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  addOneDoseRequired(insz: string): Promise<any> {
    return this.$axios.put('/sss/api/backend/healthattestation/' + insz.match(/\d+/g).join('') + '/oneDoseRequired', { isRequired: true }).then((response) => {
      return response.data
    })
  }

  removeOneDoseRequired(insz: string): Promise<any> {
    return this.$axios.put('/sss/api/backend/healthattestation/' + insz.match(/\d+/g).join('') + '/oneDoseRequired', { isRequired: false }).then((response) => {
      return response.data
    })
  }

  searchCapakey(params: any): Promise<MoProDto.IZoekPerceelResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/zoekperceel', { params }).then((response) => {
      return response.data
    })
  }

  getEigendomstoestand(params: any): Promise<MoProDto.GeefEigendomstoestandResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/geefEigendomstoestand', { params }).then((response) => {
      return response.data
    })
  }

  getNotificationReceivers(params: any): Promise<MoProDto.GetNotificationReceiversResponse> {
    return this.$axios.get('/sss/api/backend/pushnotifications/receivers', { params }).then((response) => {
      return response.data
    })
  }

  deleteNotificationReceiver(id: string): Promise<MoProDto.DeleteNotificationReceiverResponse> {
    return this.$axios.delete('/sss/api/backend/pushnotifications/receiver/' + id).then((response) => {
      return response.data
    })
  }
  getPhoneCountryCodes(): Promise<MoProDto.GetPhoneCountryCodesResponse> {
    return this.$axios.get('/sss/api/backend/codetype/phone/countrycodes').then((response) => {
      return response.data
    })
  }
  zoekEigendomsToestand(params: any): Promise<MoProDto.ZoekEigendomstoestandenResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/zoekEigendomstoestand', { params }).then((response) => {
      return response.data
    })
  }

  getEigendomstoestandDetail(params: any): Promise<MoProDto.GeefEigendomstoestandDetailResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/geefEigendomstoestandDetail', { params }).then((response) => {
      return response.data
    })
  }
  notifyReceiver(request): Promise<MoProDto.NotifyReceiverResponse> {
    return this.$axios.post('/sss/api/backend/pushnotifications/notify/receiver', request).then((response) => {
      return response.data
    })
  }

  downloadBulkPushNotificationsValidation(jobId: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/pushnotifications/bulk/${jobId}/download`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  validateBulkPushNotifications(data): Promise<any> {
    return this.$axios
      .post(`/sss/api/backend/pushnotifications/bulk/validate`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  createBulkPushNotifications(data): Promise<any> {
    return this.$axios.post(`/sss/api/backend/pushnotifications/bulk/create`, data).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  bulkNotificationOverview(params: Record<string, unknown>): Promise<MoProDto.IJobOverviewResponse> {
    return this.$axios.get(`/sss/api/backend/pushnotifications/bulk/job/overview`, { params: params }).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  getBulkNotificationResult(jobId: string, params: Record<string, unknown>): Promise<MoProDto.VerstuurBulkResultaatResponse> {
    return this.$axios.get(`/sss/api/backend/pushnotifications/bulk/${jobId}/result`, { params: params }).then((response) => {
      return Promise.resolve(response.data)
    })
  }

  downloadBulkPushNotificationsResult(jobId: string): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/pushnotifications/bulk/${jobId}/result/download`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  getNotificationMembers(params: Record<string, unknown>): Promise<MoProDto.GetNotificationMembersResponse> {
    return this.$axios.get('/sss/api/backend/pushnotifications/members', { params }).then((response) => {
      return response.data
    })
  }

  addMember(member: MoProDto.ICreateMemberRequest): Promise<MoProDto.CreateMemberResponse> {
    return this.$axios.post('/sss/api/backend/pushnotifications/members', member).then((response) => {
      return response.data
    })
  }

  getMember(id: string): Promise<MoProDto.GetNotificationMemberResponse> {
    return this.$axios.get(`/sss/api/backend/pushnotifications/members/${id}`).then((response) => {
      return response.data
    })
  }

  deleteMember(id: string): Promise<MoProDto.DeleteNotificationMemberResponse> {
    return this.$axios.delete(`/sss/api/backend/pushnotifications/members/${id}`).then((response) => {
      return response.data
    })
  }

  editMember(member: MoProDto.IEditMemberRequest): Promise<MoProDto.EditMemberResponse> {
    return this.$axios.put(`/sss/api/backend/pushnotifications/members/${member.id}`, member).then((response) => {
      return response.data
    })
  }

  getNotificationGroups(): Promise<MoProDto.GetNotificationGroupsResponse> {
    return this.$axios.get('/sss/api/backend/pushnotifications/groups').then((response) => {
      return response.data
    })
  }
  notifyAll(notification: MoProDto.IPostNotification): Promise<MoProDto.NotifyAllResponse> {
    return this.$axios.post('/sss/api/backend/pushnotifications/notify/all', { notification }).then((response) => {
      return response.data
    })
  }
  notifyTest(phoneNumbers: string[], notification: MoProDto.IPostNotification): Promise<MoProDto.NotifyAllResponse> {
    return this.$axios.post('/sss/api/backend/pushnotifications/notify/test', { notification, phoneNumbers }).then((response) => {
      return response.data
    })
  }

  buildEigendomBlocks(eigendom): Promise<MoProDto.GeefEigendomstoestandDetailResponse> {
    return this.$axios.post('/sss/api/backend/kadaster/geefEigendomstoestandDetail', eigendom).then((response) => {
      return response.data
    })
  }

  getProvinces(): Promise<MoProDto.GeefProvinciesResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefProvincies').then((response) => {
      return response.data
    })
  }

  getTowns(provinceNIS: string): Promise<MoProDto.GeefGemeentesResponse> {
    return this.$axios.get(`/sss/api/backend/kadaster/GeefGemeentes?provincieNIS=${provinceNIS}`).then((response) => {
      return response.data
    })
  }

  getInscriptionTypesData(params): Promise<MoProDto.GeefTypeInschijvingResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefInschrijvingTypes', { params: params }).then((response) => {
      return response.data
    })
  }

  getCadastralNaturesData(): Promise<MoProDto.GeefKadastraleAardResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefKadastraleAarden').then((response) => {
      return response.data
    })
  }

  getBuildingIndicationsData(): Promise<MoProDto.GeefIndicatieGebouwResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefGebouwIndicaties').then((response) => {
      return response.data
    })
  }
  getconstructionYearData(): Promise<MoProDto.GeefBouwjaarResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefBouwJaren').then((response) => {
      return response.data
    })
  }
  getconstructionTypesData(): Promise<MoProDto.GeefBouwTypeResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefBouwTypes').then((response) => {
      return response.data
    })
  }
  getqualitiesBuildingData(): Promise<MoProDto.GeefGebouwKwaliteitenResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefGebouwKwaliteiten').then((response) => {
      return response.data
    })
  }
  getcadastralNaturesCodesData(): Promise<MoProDto.GeefKadastraleAardCodesResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefKadastraleAardCodes').then((response) => {
      return response.data
    })
  }
  getlocoNumbersData(): Promise<MoProDto.GeefLoconummersResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefLocoNummers').then((response) => {
      return response.data
    })
  }
  getVerkoopPrijzen(data: MoProDto.IVerkoopPrijzenRequestPayload): Promise<MoProDto.ZoekVerkoopPrijzenResponse> {
    return this.$axios.post('/sss/api/backend/kadaster/zoekVerkoopPrijzen', data).then((response) => {
      return response.data
    })
  }
  getHistoriekEigendomstoestand(params): Promise<MoProDto.GeefHistoriekEigendomstoestandResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/geefHistoriekfEigendomstoestand', { params: params }).then((response) => {
      return response.data
    })
  }
  getMunicipalities(): Promise<MoProDto.GetMunicipalitiesResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/getMunicipalities').then((response) => {
      return response.data
    })
  }
  getDepartments(params): Promise<MoProDto.GetDepartmentsResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/getDepartments', { params: params }).then((response) => {
      return response.data
    })
  }
  getSections(params): Promise<MoProDto.GetSectionsResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/getSections', { params: params }).then((response) => {
      return response.data
    })
  }
  getPlots(params): Promise<MoProDto.GetPlotsResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/getPlots', { params: params }).then((response) => {
      return response.data
    })
  }
  downloadOwners(params: MoProDto.IDownloadOwnersData): Promise<any> {
    return this.$axios
      .post(`/sss/api/backend/kadaster/downloadEigendomstoestand`, params, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }
  getVerkoopprijzenTypes(): Promise<MoProDto.GeefVerkoopprijzenTypesResponse> {
    return this.$axios.get('/sss/api/backend/kadaster/GeefVerkoopprijzenTypes').then((response) => {
      return response.data
    })
  }

  downloadVerkoopprijzen(request: IDownloadVerkoopprijzenRequest): Promise<any> {
    return this.$axios
      .post(`/sss/api/backend/kadaster/downloadVerkoopprijzen`, request, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  bulkOwners(request: MoProDto.IGeefEigendomstoestandBulkRequestItem[]): Promise<MoProDto.IGeefEigendomstoestandBulkResponse> {
    return this.$axios.post(`/sss/api/backend/kadaster/eigendomstoestand/bulk`, request).then((response) => {
      return response.data
    })
  }

  downloadBulkOwners(jobId: string, count: number): Promise<any> {
    return this.$axios
      .get(`/sss/api/backend/kadaster/eigendomstoestand/bulk/${jobId}/download?count=${count}`, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        return Promise.resolve(response)
      })
  }

  jobProgress(jobId, jobType): Promise<MoProDto.IJobProgressResponse> {
    return this.$axios.get(`/sss/api/backend/jobs/progress/${jobId}?jobType=${jobType}`).then((response) => {
      return response.data
    })
  }

  bulkSearhParcel(data): Promise<MoProDto.IZoekPerceelBulkResponse> {
    return this.$axios
      .post(`/sss/api/backend/kadaster/zoekperceel/bulk`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        return Promise.resolve(response.data)
      })
  }

  bulkParcelResult(jobId): Promise<MoProDto.IZoekPerceelBulkResultaatResponse> {
    return this.$axios.get(`/sss/api/backend/kadaster/zoekperceel/bulk/resultaat/${jobId}`).then((response) => {
      return Promise.resolve(response.data)
    })
  }
}

export { API, Nationalities, Nationality }

export default ({ app, redirect }, inject) => {
  inject('api', new API(app, redirect))
}
