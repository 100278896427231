var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mo-contact-card" }, [
    _c(
      "header",
      { staticClass: "mo-contact-card__header", attrs: { role: "none" } },
      [
        _vm.title
          ? [
              _c(
                "vl-title",
                {
                  staticClass: "mo-contact-card__title",
                  attrs: { "tag-name": _vm.titleTag, "tag-style": "h6" },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
            ]
          : _vm._e(),
        _vm.subtitle
          ? _c(
              "vl-title",
              {
                staticClass: "mo-contact-card__subtitle",
                attrs: { "tag-name": _vm.titleTag, "tag-style": "h6" },
              },
              [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
            )
          : _vm._e(),
        _vm.insz
          ? _c("span", [
              _vm._v("INSZ: "),
              _c("strong", [_vm._v(_vm._s(_vm.insz))]),
            ])
          : _vm._e(),
        _vm.organisation
          ? _c("address", {
              staticClass: "mo-contact-card__address",
              domProps: { innerHTML: _vm._s(_vm.organisation) },
            })
          : _vm._e(),
      ],
      2
    ),
    _vm.address
      ? _c("address", {
          staticClass: "mo-contact-card__address",
          domProps: { innerHTML: _vm._s(_vm.address) },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }