import router from './index'

export interface IPage {
  name: string
  title: string
}

export const rootRoute: IPage = {
  name: '',
  title: 'MAGDA Online',
}

export function buildBreadcrumbs(routeName: string, pageTitle: string = null): IPage[] {
  const res: IPage[] = []
  const route = router.resolve({ name: routeName }).route
  if (route) {
    const matchedRoutes = route.matched
    if (matchedRoutes.length) {
      res.push(rootRoute)
      matchedRoutes.forEach((r) => {
        const parentName = r.meta?.breadcrumb?.parent
        if (parentName) {
          const parentRoute = router.resolve({ name: parentName }).route
          if (parentRoute) {
            const title = parentRoute?.meta?.breadcrumb?.label
            if (title) {
              res.push({ name: parentRoute.name, title: title })
            }
          }
        }
      })

      const title = pageTitle ? pageTitle : route.meta?.breadcrumb?.label
      if (title) {
        res.push({ name: route.name, title })
      }
    }
  }
  if (res.length === 0) {
    res.push(rootRoute)
  }
  return res
}

export function buildPageTitle(routeName: string, pageTitle: string = null): string {
  const breadcrumbs = buildBreadcrumbs(routeName, pageTitle)
    .map((x) => x.title)
    .reverse()
  return breadcrumbs.join(' | ')
}

export function getPageHeaderInformation(routeName: string) {
  return {
    pageTitle: buildPageTitle(routeName) || rootRoute.title,
  }
}
