import { Component, Vue, Prop } from 'vue-property-decorator'
import MoTab from '@/components/shared/mo-tab/mo-tab'
import MoLanguageSwitcher from '@/components/shared/mo-language-switcher/index.vue'

@Component({
  components: {
    MoLanguageSwitcher,
  },
})
export default class FunctionalHeader extends Vue {
  @Prop({ default: 'header' })
  tagName: string

  @Prop({ required: true })
  title: string

  @Prop({
    default() {
      return []
    },
  })
  tabs: MoTab[]

  @Prop({ default: false })
  modSearch: boolean

  get classes() {
    return [
      'vl-functional-header',
      {
        'vl-functional-header--has-actions': this.$slots.actions,
      },
    ]
  }
  get hasTabs() {
    return this.tabs.length > 0
  }
  get hasActions() {
    return !!this.$slots.actions
  }
  goHome() {
    this.$router.push({ name: 'index' })
  }
}
