import { VueGtag as GTag } from 'vue-gtag'
import { userStore } from '@/store/user'
class GA {
  ga: GTag
  constructor(app) {
    this.ga = app.$gtag
  }

  sendEvent = (category: string, action: string, value?: number) => {
    const activeRole = userStore().activeRole?.full

    this.ga.event(action, { event_category: category, event_label: activeRole, value: value })
  }
  event = (category: string, action: string, activeRole: string, value?: number) => {
    this.ga.event(action, { event_category: category, event_label: activeRole, value: value })
  }
}

export default GA
