import { Component, Vue, Watch } from 'vue-property-decorator'
import { GACategory } from '@/libs/constants'
import { redirectToLogin } from '@/businesslogic/user/login.functions'
import { Meta } from '@/infrastructure/meta/meta'
import { HeaderType, moproStore } from '@/store/mopro'
import { pageStore } from '@/store/page'
import { userStore } from '@/store/user'
import { featureToggleStore } from '@/store/feature-toggle'
interface Alert {
  type: string
  title: string
  content: string
}

@Component
export default class DefaultLayout extends Vue {
  isLoggedOut = false
  pageDescription = ''
  title = 'Uw beveiligde sessie is verlopen'
  message = 'Geen probleem, meld opnieuw aan om verder te werken.'
  expirationTimer = null
  metaInfo = null

  moproStore = moproStore()
  pageStore = pageStore()
  userStore = userStore()
  toggleStore = featureToggleStore()
  get showPageContent(): boolean {
    if (this.pageLoading) {
      return false
    }

    return this.userExists || this.userError || this.isPublic || this.is404
  }

  get headerType(): string {
    return this.moproStore.type
  }

  get userExists(): boolean {
    return this.userStore.exists
  }

  get pageLoading(): boolean {
    return this.pageStore.loading
  }

  get userError(): boolean {
    return this.userStore.getUserError
  }

  get isPublic(): boolean {
    return !!this.moproStore.public
  }

  get is404(): boolean {
    return this.$route.name === '404'
  }

  get moproHeader() {
    return this.headerType === HeaderType.Mopro
  }

  get timeBeforeExpirationInMilliseconds(): number {
    return this.userStore.timeBeforeExpirationInMilliseconds
  }

  get header() {
    switch (this.headerType) {
      case HeaderType.Documentendienst:
        return this.moproStore.documentendienstHeader
      case HeaderType.Mopro:
        return this.moproStore.header
      default:
        return this.moproStore.header
    }
  }

  get footer() {
    switch (this.headerType) {
      case HeaderType.Documentendienst:
        return this.moproStore.documentendienstFooter
      case HeaderType.Mopro:
        return this.moproStore.footer
      default:
        return this.moproStore.footer
    }
  }

  get alerts(): Alert[] {
    const alerts = this.pageStore.alerts(this.$route.name)
    return alerts
  }

  get toasts() {
    const toasts = this.pageStore.toasts(this.$route.name)
    return toasts
  }

  get isSessionTimeoutVisible(): boolean {
    return !this.isPublic && this.isLoggedOut
  }

  get functionalTitle(): string {
    return ''
  }

  get pageTitle(): string {
    return this.moproStore.pageTitle
  }

  get versionNumber(): string {
    return this.moproStore.versionNumber
  }

  get environment(): string {
    return this.moproStore.environment
  }

  @Watch('timeBeforeExpirationInMilliseconds')
  onTimeBeforeExpirationInMillisecondsChange() {
    if (this.timeBeforeExpirationInMilliseconds !== undefined) {
      this.startExpirationTimer()
    } else {
      this.stopExpirationTimer()
    }
  }

  alertIcon(alert: Alert) {
    switch (alert.type) {
      case 'error':
        return 'cross'
      case 'warning':
        return 'warning'
      case 'success':
        return 'check'
      default:
        return ''
    }
  }

  redirectToLogin() {
    redirectToLogin()
  }

  async mounted() {
    if (this.userStore.activeRole && this.userStore.activeRole?.full) {
      this.$ga.sendEvent(GACategory.profiel.category, GACategory.profiel.selectNewRole)
    }
  }

  @Watch('$metaInfo.htmlAttrs.lang', { immediate: true, deep: false })
  onMetaLangChanged() {
    setTimeout(() => {
      if (global['WidgetApi']) {
        global['WidgetApi'].Core.getInstances().forEach((widget) => widget.reload())
      }
    }, 300)
  }

  @Meta
  head() {
    return {
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageDescription,
          mo_version: this.versionNumber,
          mo_environment: this.environment,
        },
      ],
      htmlAttrs: {
        lang: this['$i18n'].locale,
      },
      title: this.pageTitle,
    }
  }

  private startExpirationTimer() {
    this.stopExpirationTimer()

    this.expirationTimer = window.setTimeout(() => {
      this.isLoggedOut = true
    }, this.timeBeforeExpirationInMilliseconds)
  }

  private stopExpirationTimer() {
    window.clearTimeout(this.expirationTimer)
    this.expirationTimer = null
  }
}
