import { userStore } from '@/store/user'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class MoPersonSearchSource extends Vue {
  @Prop()
  selectedSource: string

  @Prop({ default: null })
  dienst: string

  internalSelectedSource: string = null

  userStore = userStore()

  get activeRole() {
    return this.userStore.activeRole
  }

  get permissions() {
    if (this.activeRole) {
      return this.userStore.permissions(this.activeRole)
    }
  }

  get sources(): { label: string; value: string }[] {
    if (this.permissions && this.dienst) {
      const selectedDienst = this.permissions.filter((p: { naam: string }) => p.naam === this.dienst)[0]
      return selectedDienst?.bronnen?.length > 0 ? selectedDienst.bronnen : []
    }
  }

  get visible() {
    return this.sources && this.sources.length > 1
  }

  mounted() {
    if (this.selectedSource) {
      this.internalSelectedSource = this.selectedSource
    } else {
      this.sources ? (this.internalSelectedSource = this.sources[0]?.value) : (this.internalSelectedSource = null)
      this.$emit('update:selectedSource', this.internalSelectedSource)
    }
  }

  @Watch('dienst', { immediate: true })
  onDienstChanged() {
    if (this.selectedSource) {
      this.internalSelectedSource = this.selectedSource
    } else {
      this.sources ? (this.internalSelectedSource = this.sources[0]?.value) : (this.internalSelectedSource = null)
      this.$emit('update:selectedSource', this.internalSelectedSource)
    }
  }
}
