var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.layout === "horizontal"
        ? [
            _c("div", { staticClass: "formfield-decorator__flex" }, [
              _c(
                "label",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.label,
                      expression: "label",
                    },
                  ],
                  staticClass: "vl-form__label",
                  class: _vm.required,
                },
                [
                  _vm._v(_vm._s(_vm.label) + " "),
                  _c(
                    "vl-annotation",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.maxLength,
                          expression: "maxLength",
                        },
                      ],
                    },
                    [
                      _vm._v("- "),
                      _c("span", { class: _vm.maxLengthClasses }, [
                        _vm._v(
                          _vm._s(_vm.remainingChars) +
                            " " +
                            _vm._s(_vm.$t("general.label.remaining"))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vl-form__input" },
                [
                  _vm._t("default"),
                  _c("div", { staticClass: "vl-form__error" }, [
                    _vm._v(_vm._s(_vm.message)),
                  ]),
                ],
                2
              ),
            ]),
          ]
        : [
            _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.label,
                    expression: "label",
                  },
                ],
                staticClass: "vl-form__label",
                class: _vm.required,
              },
              [
                _vm._v(_vm._s(_vm.label) + " "),
                _c(
                  "vl-annotation",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.maxLength,
                        expression: "maxLength",
                      },
                    ],
                  },
                  [
                    _vm._v("- "),
                    _c("span", { class: _vm.maxLengthClasses }, [
                      _vm._v(
                        _vm._s(_vm.remainingChars) +
                          " " +
                          _vm._s(_vm.$t("general.label.remaining"))
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vl-form__input" },
              [_vm._t("default")],
              2
            ),
            _c("div", { staticClass: "vl-form__error" }, [
              _vm._v(_vm._s(_vm.message)),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }