var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "normal"
    ? _c("mo-input-field", {
        class: "vl-input-field--block",
        attrs: {
          id: _vm.id,
          type: _vm.inputType,
          name: _vm.name,
          "mod-error": _vm.showError,
          "mod-disabled": _vm.modDisabled || _vm.modDisabledKeep,
          mask: _vm.mask,
          "show-mask": _vm.showMask,
          guide: _vm.guide,
          "placeholder-char": _vm.placeholderChar,
          autocomplete: _vm.autocomplete,
          "data-lpignore": "true",
        },
        on: {
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          paste: function ($event) {
            return _vm.$emit("paste", $event)
          },
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      })
    : _vm.type === "pill"
    ? _c("mo-input-pill", {
        class: "vl-input-field--block",
        attrs: {
          id: _vm.id,
          name: _vm.name,
          "mod-error": _vm.showError,
          "mod-disabled": _vm.modDisabled || _vm.modDisabledKeep,
          mask: _vm.mask,
          "show-mask": _vm.showMask,
          "placeholder-char": _vm.placeholderChar,
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      })
    : _vm.type === "date"
    ? _c("mo-date-input-field", {
        attrs: {
          "mod-error": _vm.showError,
          "data-lpignore": "true",
          "mod-disabled": _vm.modDisabled || _vm.modDisabledKeep,
          "placeholder-char": _vm.placeholderChar,
          fields: _vm.fields,
        },
        on: {
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }