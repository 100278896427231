import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MoLoadingPage extends Vue {
  @Prop({ default: '' })
  public title: string

  @Prop({ default: '' })
  public searchValue: string
}
