import { userStore } from "@/store/user"

export interface IGeneralErrorOptions {
  correlationId: string
  serviceDeskUrl?: string
  errorTitle?: string
  message?: string
  errorBodyCode?: number | string
}

export function MapXhrToErrorObject(xhr: XMLHttpRequest) {
  let data
  try {
    if (xhr.response) {
      data = JSON.parse(xhr.response)
    }
  } catch {}

  const errorObject = {
    response: {
      status: xhr.status,
      headers: { 'x-correlation-id': xhr.getResponseHeader('x-correlation-id') },
      data: data ? data : BuildGeneralErrorObject({ correlationId: xhr['x-correlation-id'] }),
    },
  }

  return errorObject
}

export function BuildGeneralErrorObject({
  correlationId,
  serviceDeskUrl = null,
  errorTitle = null,
  message = null,
  errorBodyCode = null,
}: IGeneralErrorOptions) {
  message = message || 'Er is een systeemfout opgetreden.'
  serviceDeskUrl = serviceDeskUrl || MapServicedeskUri(correlationId, errorTitle, message)

  return {
    description: serviceDeskUrl
      ? `${message} ${
          errorBodyCode === 259
            ? `Probeer later opnieuw of meld ons dit <a href="${serviceDeskUrl}" target="_blank">hier</a> als het probleem zich blijft voordoen.`
            : `U kan ons dit <a href="${serviceDeskUrl}" target="_blank">hier</a> melden.`
        }`
      : 'Gelieve een mail te versturen (zie "contacteer ons") met de vermelding van de foutcode en het tijdstip van de melding.',
  }
}

export function MapServicedeskUri(correlationId: string, errorTitle: string, message: string) {
  const store = userStore()

  if (!correlationId) {
    return ''
  }

  if (!store) {
    return ''
  }

  let serviceDeskUrl = store.serviceDeskUrl
  if (!serviceDeskUrl) {
    return ''
  }
  serviceDeskUrl = serviceDeskUrl.replace(new RegExp('\\{' + 0 + '\\}', 'g'), correlationId)

  if (errorTitle) {
    serviceDeskUrl = serviceDeskUrl.replace(new RegExp('\\{' + 1 + '\\}', 'g'), errorTitle)
  } else {
    serviceDeskUrl = serviceDeskUrl.replace('-%20{1}', '')
  }
  if (message) {
    serviceDeskUrl = serviceDeskUrl.replace(new RegExp('\\{' + 2 + '\\}', 'g'), message)
  } else {
    serviceDeskUrl = serviceDeskUrl.replace('-%20{2}', '')
  }

  return serviceDeskUrl
}

export function mapApiExceptionToErrorObject(apiException): IErrorObject {
  return {
    response: {
      data: apiException.response,
      headers: apiException.headers,
      status: apiException.status,
    },
  }
}

interface IErrorObject {
  response: {
    data: unknown
    headers: unknown
    status: unknown
  }
}
