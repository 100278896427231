import axios, { InternalAxiosRequestConfig } from 'axios'
import i18n from '@/infrastructure/i18n'
import { isPublic, responseMiddleware } from '@/infrastructure/axios/utils'
import { redirectToLogin } from '@/businesslogic/user/login.functions'
import router from '@/infrastructure/router'
import { userStore } from '@/store/user'

declare module 'axios' {
  export interface InternalAxiosRequestConfig {
    metadata?: { startTime?: Date; endTime?: Date }
  }
}

axios.interceptors.request.use((config: InternalAxiosRequestConfig<any>) => {
  const locale = i18n.locale

  if (locale && config.headers?.common) {
    config.headers.common['Accept-Language'] = locale
  }

  config.metadata = { startTime: new Date() }
  return config
})

axios.interceptors.response.use(
  (response) => {
    responseMiddleware.forEach((middleware) => middleware(response))
    return response
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401 && !isPublic(error.response)) {
        redirectToLogin(router.currentRoute)
      } else if (error.response.status === 403 && !isPublic(error.response) && userStore().isExpired) {
        redirectToLogin(router.currentRoute)
      } else {
        responseMiddleware.forEach((middleware) => middleware(error.response))
      }
    }
    return Promise.reject(error)
  },
)

export default axios
