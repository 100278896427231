var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c(
        "vl-alert",
        {
          directives: [
            {
              name: "scroll",
              rawName: "v-scroll",
              value: _vm.scrollTo,
              expression: "scrollTo",
            },
          ],
          attrs: {
            title: _vm.title,
            "mod-error": _vm.modError,
            "mod-warning": _vm.modWarning,
            "mod-success": _vm.modSuccess,
            icon: _vm.icon,
            closable: _vm.closable,
          },
          on: {
            close: function ($event) {
              return _vm.safeFunction()
            },
          },
        },
        [
          _c("vl-typography", [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
          ]),
          _vm.functionLink
            ? _c("div", [
                _c("br"),
                _c("a", {
                  attrs: { href: "#" },
                  domProps: { innerHTML: _vm._s(_vm.functionLink.message) },
                  on: {
                    click: function ($event) {
                      return _vm.functionLink.callBack()
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.buttons
            ? [
                _c("div", { staticClass: "vl-u-spacer" }),
                _vm._l(_vm.buttons, function (button) {
                  return _c(
                    "vl-button",
                    { key: button.text, on: { click: button.function } },
                    [_vm._v(" " + _vm._s(button.text) + " ")]
                  )
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.decorator ? _c("div", { class: _vm.decorator }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }