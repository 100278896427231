var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mo-functional-header",
    { ref: "headertitle", attrs: { title: "MAGDA Online" } },
    [
      _c(
        "template",
        { slot: "actions" },
        [
          _c("vl-functional-header-actions", [
            _c(
              "div",
              { staticClass: "vl-functional-header__profile" },
              [
                _vm.userExists
                  ? _c(
                      "vl-functional-header-action",
                      { ref: "accountPanel" },
                      [_c("mo-account-panel")],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "breadcrumbs" },
        [
          _c(
            "vl-functional-header-sub-actions",
            { attrs: { "tag-name": "ul" } },
            _vm._l(_vm.breadcrumbs, function (crumb, index) {
              return _c(
                "vl-functional-header-sub-action",
                { key: index },
                [
                  index !== _vm.breadcrumbs.length - 1 ||
                  _vm.breadcrumbs.length === 1
                    ? [
                        _c(
                          "router-link",
                          {
                            staticClass: "vl-link",
                            attrs: { to: { name: crumb.name } },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.breadcrumbLabel(crumb)) + " "
                            ),
                          ]
                        ),
                        _c("vl-icon", { attrs: { icon: "arrow-right-fat" } }),
                      ]
                    : [_vm._v(" " + _vm._s(_vm.breadcrumbLabel(crumb)) + " ")],
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }