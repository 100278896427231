import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator'
import { IValidation } from '@/declarations/references.models'
import { defaultLocale } from '@/libs/translate'

@Component
export default class Validation extends Vue {
  error = ''
  localValue: any = null

  @Inject()
  updateErrorMessage: any
  @Inject()
  setMandatory: (v: boolean) => void
  @Inject()
  setDisabled: (v: boolean) => void

  @Prop()
  validation: IValidation

  @Prop({ default: '' })
  fieldname: string

  @Prop(Boolean)
  modDisabled: boolean

  @Prop(Object)
  options: { mandatory?: boolean | string; locale?: string; validations?: any[]; mask?: any[] }

  @Watch('value', { immediate: true })
  onValueChange(val: any) {
    this.localValue = val
  }

  @Watch('localValue', { immediate: true })
  onLocalValueChange(val: any) {
    this.$emit('input', val)
    if (this.error !== '') {
      this.validateInputFields()
    }
  }

  @Watch('options.mandatory', { immediate: true })
  onMandatoryChange(isMandatory: boolean) {
    if (this.setMandatory) {
      this.setMandatory(isMandatory)
    }
  }

  @Watch('modDisabled', { immediate: true })
  onModDisabledChange(val: boolean) {
    if (this.setDisabled) {
      this.setDisabled(val)
    }
  }

  get showError() {
    return this.error !== '' && this.error !== null && this.error !== undefined
  }

  get mask() {
    if (this.options && this.options.mask) {
      return this.options.mask
    } else {
      return null
    }
  }

  validateInputFields() {
    this.error = ''
    
    if (this.modDisabled) {
      this.reset()
    } else if (this.options) {
      if (
        this.options.mandatory &&
        (!this.localValue ||
          this.localValue === '--' ||
          (typeof this.localValue === 'string' && !this.localValue.toString().trim()) ||
          !this.objectHasValue(this.localValue))
      ) {
        if (this.isDateFieldsObject(this.localValue)) {
          this.error = this.getDateFieldsMandatoryFieldsError(this.localValue)
        } else {
          this.error =
            typeof this.options.mandatory === 'boolean'
              ? this.$t('general.validation.mandatory', this.options.locale ? this.options.locale : defaultLocale)
              : this.options.mandatory
        }
      } else if (this.localValue && this.options.validations) {
        for (const validation of this.options.validations) {
          if (validation instanceof Function) {
            this.error = validation(this.localValue, this.$el)
          } else {
            this.error = validation.func(this.localValue, ...validation.params, this.$el)
          }
          if (this.error) {
            break
          }
        }
      }
    }

    this.updateErrorMessage(this.error)
    return !this.error
  }

  getDateFieldsMandatoryFieldsError(value) {
    let error = ''
    if (this['requiredFields']) {
      Object.keys(value).forEach((key) => {
        if (this['requiredFields'].indexOf(key.toUpperCase()) > -1) {
          if (!value[key]) {
            error += `${this.$t('general.validation.fieldRequired', this.options.locale ? this.options.locale : defaultLocale, {
              field: this.$t('general.label.' + key),
            })} `
          }
        }
      })
    }
    return error.trimEnd()
  }

  isDateFieldsObject(value: unknown) {
    if (value && typeof value === 'object' && 'day' in value && 'month' in value && 'year' in value) {
      return true
    }
    return false
  }

  objectHasValue(value) {
    if (typeof value === 'object') {
      if (this.isDateFieldsObject(value)) {
        return (
          !(this['requiredFields'].indexOf('DAY') > -1 && !value.day) &&
          !(this['requiredFields'].indexOf('MONTH') > -1 && !value.maand) &&
          !(this['requiredFields'].indexOf('YEAR') > -1 && !value.year)
        )
      } else if (this.localValue.val) {
        return true
      } else if (Array.isArray(this.localValue) && this.localValue.length > 0) {
        return true
      } else if (Object.keys(value).filter((k) => value[k] !== null && value[k] !== undefined && value[k] !== '').length === Object.keys(value).length) {
        return true
      } else {
        return false
      }
    }
    return true
  }

  reset() {
    if ((this.localValue && this.localValue.allowReset !== false) || !this.localValue) {
      this.error = ''
      this.updateErrorMessage(this.error)
    }
  }
  resetValue() {
    if (this.localValue && this.localValue.allowReset !== false) {
      this.localValue = null
    }
  }
}
