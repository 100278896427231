var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tagName,
    { tag: "component", class: _vm.classes, attrs: { role: "banner" } },
    [
      _c("div", { staticClass: "vl-functional-header__container" }, [
        _c(
          "div",
          { staticClass: "vl-functional-header__row" },
          [
            _c(
              "div",
              { staticClass: "vl-functional-header__content" },
              [_vm._t("breadcrumbs")],
              2
            ),
            _c("mo-language-switcher"),
            _vm.hasActions
              ? _c(
                  "div",
                  { staticClass: "vl-functional-header__actions" },
                  [_vm._t("actions")],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }