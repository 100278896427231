import moment from 'moment'

export function stringToInsz(value: string) {
  if (value) {
    const valueOnlyNumbers = stringToNumber(value)
    if (valueOnlyNumbers.length === 11) {
      return `${valueOnlyNumbers.substring(0, 2)}.${valueOnlyNumbers.substring(2, 4)}.${valueOnlyNumbers.substring(4, 6)}-${valueOnlyNumbers.substring(
        6,
        9,
      )}.${valueOnlyNumbers.substring(9, 11)}`
    } else {
      return value
    }
  } else {
    return value
  }
}

export function stringToNumber(value: string) {
  if (value) {
    return value.replace(/\D/g, '')
  }
  return value
}

export function titleCase(value: string) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
  return value
}

export function startsWithCapital(value: string) {
  if (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  return value
}

export function stringToDate(value: string) {
  if (value) {
    return moment(value).format('DD.MM.YYYY')
  }
  return value
}
