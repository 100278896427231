import { moproStore } from '@/store/mopro'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class MoEnvironmentHeader extends Vue {
  moproStore = moproStore()
  get title(): string {
    return `${this.environmentLabel} ${this.versionNumber}`
  }

  get versionNumber(): string {
    return this.moproStore.versionNumber
  }

  get environment(): string {
    return this.moproStore.environment
  }

  get environmentLabel(): string {
    switch (this.environment) {
      case 'dev':
        return 'Dev'
      case 'test':
        return 'Test'
      case 'testat':
        return 'Test Automation'
      case 'beta':
        return 'Beta'
      case 'stg':
        return 'Staging'
      default:
        return null
    }
  }

  get showHeader(): boolean {
    return !!this.environmentLabel
  }
}
