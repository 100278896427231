var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vl-radio__wrapper" },
    _vm._l(_vm.keyValues, function (keyValue) {
      return _c(
        "vl-radio",
        {
          key: keyValue.key + "_" + _vm.name,
          attrs: {
            id: _vm.name + "_" + keyValue.key + "_" + _vm.name,
            "mod-error": _vm.showError,
            value: keyValue.key,
            name: _vm.name,
            "mod-disabled": _vm.modDisabled || _vm.modDisabledKeep,
          },
          on: {
            input: function ($event) {
              return _vm.clickHandler(keyValue.key)
            },
          },
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        [_vm._v(" " + _vm._s(keyValue.val) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }