import Vue from 'vue'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'

const requireComponent = require.context('@/components/shared/', true, /[A-Za-z]+\.vue$/)
requireComponent.keys().forEach((component) => {
  const componentConfig = requireComponent(component)

  const filename = component.split('/')[component.split('/').length - 1]
  const componentName = upperFirst(camelCase(filename.replace(/(\w+)\.vue$/, '$1')))
  Vue.component(componentName, componentConfig.default || componentConfig)
})
