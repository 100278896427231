var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "vl-side-navigation__content__block" },
        [
          _c(
            "mo-formfield-decorator",
            { attrs: { label: "Bron" } },
            [
              _c(
                "mo-select",
                {
                  attrs: {
                    name: "selectedSource",
                    id: "selectedSource",
                    "mod-block": "",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$emit(
                        "update:selectedSource",
                        _vm.internalSelectedSource
                      )
                    },
                  },
                  model: {
                    value: _vm.internalSelectedSource,
                    callback: function ($$v) {
                      _vm.internalSelectedSource = $$v
                    },
                    expression: "internalSelectedSource",
                  },
                },
                _vm._l(_vm.sources, function (s) {
                  return _c("option", { domProps: { value: s.value } }, [
                    _vm._v(_vm._s(s.label)),
                  ])
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }