var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mo-month-year-selector", {
    attrs: { localValue: _vm.localValue, "mod-error": _vm.showError },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }