var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mo-accountpanel-dropdown",
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.close.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "focus",
              rawName: "v-focus",
              value: _vm.toggleFocus,
              expression: "toggleFocus",
            },
          ],
          staticClass: "mo-accountpanel-dropdown__toggle",
          attrs: { "aria-expanded": _vm.isOpen.toString() },
          on: { click: _vm.buttonClicked },
        },
        [
          _c(
            "span",
            { staticClass: "mo-accountpanel-dropdown__toggle__label" },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.getActiveRoleDisplayName(_vm.activeRole))),
              ]),
            ]
          ),
          _c("vl-icon", { attrs: { icon: "up-down-arrows", "mod-small": "" } }),
        ],
        1
      ),
      _vm.isOpen
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "focus",
                  rawName: "v-focus",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
                {
                  name: "mo-click-outside",
                  rawName: "v-mo-click-outside",
                  value: _vm.close,
                  expression: "close",
                },
              ],
              staticClass: "mo-accountpanel-dropdown__content",
            },
            [
              _c(
                "div",
                { staticClass: "mo-accountpanel-dropdown__content__wrapper" },
                [
                  _vm.hasMultipleRoles
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "mo-accountpanel-dropdown__list mo-accountpanel-dropdown__list--rolepicker",
                        },
                        _vm._l(_vm.roles, function (role) {
                          return _c(
                            "li",
                            {
                              class: {
                                "mo-accountpanel-dropdown__list__item": true,
                                "mo-accountpanel-dropdown__list__item--active":
                                  _vm.isActiveRole(role),
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "mo-accountpanel-dropdown__list__item__link",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.switchRole(role)
                                    },
                                  },
                                },
                                [
                                  _vm.isActiveRole(role)
                                    ? _c("vl-icon", {
                                        attrs: { icon: "bullet" },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mo-accountpanel-dropdown__list__item__label",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.getRoleDisplayname(role))
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("ul", { staticClass: "mo-accountpanel-dropdown__list" }, [
                    _c(
                      "li",
                      { staticClass: "mo-accountpanel-dropdown__list__item" },
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "mo-accountpanel-dropdown__list__item__link to-role-selection",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toRoleSelectionpage()
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "mo-accountpanel-dropdown__list__item__label",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("header.label.overviewProfiles")
                                  )
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }