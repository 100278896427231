import { IpproDatatable } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-datatable'
import Vue from 'vue'
import { IpproMap } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map'
// import { IpproMapSearchExtended } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/components/ippro-map-search-extended'
import { IpproMapZoomToFeature } from '@ippro/be.vlaanderen.ippro.web.vue/src/vl-ippro-map/components/ippro-map-zoom-to-feature'

attachComponentsToVue(Vue)

export function attachComponentsToVue(vueInstance) {
  vueInstance.component('ippro-datatable', IpproDatatable)
  vueInstance.component('ippro-map', IpproMap)
  // vueInstance.component('ippro-map-search-extended', IpproMapSearchExtended)
  vueInstance.component('ippro-map-zoom-to-feature', IpproMapZoomToFeature)
}
