var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vl-multiselect",
    _vm._b(
      {
        attrs: {
          "mod-disabled": _vm.modDisabled,
          "mod-error": _vm.showError,
          options: _vm.dropDownOptions,
        },
        model: {
          value: _vm.localValue,
          callback: function ($$v) {
            _vm.localValue = $$v
          },
          expression: "localValue",
        },
      },
      "vl-multiselect",
      _vm.$attrs,
      false
    ),
    [
      _c("template", { slot: "maxElements" }, [
        _c("span", [_vm._v(_vm._s(_vm.$t("general.multiSelect.maxOptions")))]),
      ]),
      _c("template", { slot: "noResult" }, [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("general.error.noResults.subtitle"))),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }