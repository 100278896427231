import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import MoDatepicker from '@/components/shared/mo-datepicker/mo-datepicker.vue'
import validationMixin from '@/mixins/validation'

@Component({
  components: {
    MoDatepicker,
  },
})
export default class MoDatepickerWrapper extends mixins(validationMixin) {
  @Prop(String)
  value: string

  @Prop(String)
  minDate: string

  @Prop({ type: Boolean, required: false, default: false })
  enableTime: boolean

  @Prop({ type: Number, required: false, default: 0 })
  defaultHour: number

  @Prop({ type: Number, required: false, default: 0 })
  defaultMinute: number

  @Prop(String)
  maxDate: string

  @Prop({ default: true })
  showMask: boolean

  @Prop({ default: false })
  guide: boolean

  @Prop({ default: ' ' })
  placeholderChar: string

  @Prop({ default: 'dd.mm.jjjj' })
  placeholder: string

  @Prop({ default: 'd.m.Y' })
  format: string

  @Prop({ default: false })
  modDisabled: boolean
}
