var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vl-upload",
    _vm._g(
      _vm._b(
        {
          ref: "upload",
          class: { readonly: _vm.modDisabled },
          attrs: { options: _vm.dropzoneOptions, "mod-error": !!_vm.error },
          on: {
            "upload-file-added": _vm.filesChanged,
            "upload-files-added": _vm.filesChanged,
            "upload-removed-file": _vm.filesChanged,
            "upload-drop": _vm.filesChanged,
            "upload-drag-end": _vm.filesChanged,
          },
          model: {
            value: _vm.localValue,
            callback: function ($$v) {
              _vm.localValue = $$v
            },
            expression: "localValue",
          },
        },
        "vl-upload",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }