import { IKeyVal } from '@/declarations/location.models'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validation from '@/mixins/validation'

@Component
export default class MoCheckboxWrapper extends mixins(validation) {
  @Prop(String)
  name: string

  @Prop({ default: [] as IKeyVal[] })
  keyValues: IKeyVal[]

  @Prop({ default: [] as IKeyVal[] })
  value: IKeyVal

  @Prop({ default: false })
  modDisabledKeep: boolean

  @Prop({ default: false })
  modBlock: boolean

  @Prop({ default: false })
  modSwitch: boolean

  inputHandler(_value) {
    this.localValue = this.keyValues.filter((obj) => obj.checked)
    this.validateInputFields()

    this.$emit('change', this.localValue)
  }

  @Watch('localValue')
  watcher(val) {
    this.$emit('change', this.localValue)
  }
}
