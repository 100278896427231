import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MoContactCard extends Vue {
  @Prop({ type: String, required: false })
  title: string

  @Prop({ type: String, required: false })
  subtitle: string

  @Prop({ type: String, required: false, default: 'span' })
  titleTag: string

  @Prop({ type: String, required: false })
  address: string

  @Prop({ type: String, required: false })
  organisation: string

  @Prop({ type: String, required: false })
  insz: string
}
