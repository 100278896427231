import { Component, Prop, Watch } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { IList, ISelectionItem } from '@/declarations/references.models'
import validation from '@/mixins/validation'

@Component
export default class MoSelectWrapper extends mixins(validation) {
  @Prop(Array)
  items: ISelectionItem[]
  @Prop(String)
  keyId: string
  @Prop(Boolean)
  modDisabledKeep: boolean
  @Prop({ default: true })
  emptyField: boolean
  @Prop({ default: '' })
  emptyMessage: string
  @Prop(Number)
  timeoutLength
  @Prop({ type: Object, required: false })
  value: IList
  @Prop({ default: true })
  enableSearch: boolean

  @Watch('modDisabled')
  onDisabledChange(newValue, _oldValue) {
    if (newValue) {
      this.localValue = null
    }
  }
}
