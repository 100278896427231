import { ISimpleBulkJobResult } from '@/declarations/documentendienst/bulk.models'
import MoLoadingBar from '@/components/shared/mo-loading-bar/mo-loading-bar.vue'
import moment from 'moment'
import { computed, defineComponent, onMounted, ref, watch, Ref, ComputedRef, toRefs } from 'vue'
import { API } from '@/plugins/api'
import axios from '@/infrastructure/axios'
import { IJobProgressResponse } from '@/api/mopro-api.dto'
import i18n from '@/infrastructure/i18n'

export default defineComponent({
  name: 'MoBulkProcessing',
  components: {
    MoLoadingBar,
  },
  props: {
    jobId: {
      type: String,
      default: '',
      required: true,
    },
    jobType: {
      type: String,
      default: '',
      required: true,
    },
    scroll: {
      type: Boolean,
      default: false,
      required: false,
    },
    canDownload: {
      type: Boolean,
      default: true,
      required: false,
    },
    replaceUpload: {
      type: Boolean,
      default: false,
      required: false,
    },
    closeable: {
      type: Boolean,
      default: false,
      required: false,
    },
    downloading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props, { emit }) {
    const bulkResult: Ref<IJobProgressResponse> = ref(null)
    const isJobFound: Ref<boolean> = ref(false)

    let polling = null
    let isGettingProgress = false
    const propRefs = toRefs(props)

    onMounted(() => {
      if (!propRefs.jobId.value) {
        isJobFound.value = false
      }
    })

    const progress = computed(() => {
      return Math.round(
        (bulkResult?.value?.itemCount - bulkResult?.value?.statusProgress.find((status) => status.status === 'PROCESSING')?.amount) /
          (bulkResult?.value?.itemCount / 100),
      )
    })

    const nameOfFile = computed(() => {
      return bulkResult?.value?.jobDescription
    })

    const startDate = computed(() => {
      return moment(bulkResult?.value?.timeStamp).format('DD.MM.YYYY HH:mm')
    })

    const amountSuccess = computed(() => {
      let success = 0
      bulkResult?.value.statusProgress.forEach((status) => {
        if (parseInt(status?.status) == 200) {
          const numberOfAmountsSent = status?.amount
          success = success + numberOfAmountsSent
        }
      })
      return success
    })

    const amountError = computed(() => {
      let error = 0
      bulkResult?.value.statusProgress.forEach((status) => {
        if (parseInt(status?.status) >= 205) {
          const numberOfAmountsError = status?.amount
          error = error + numberOfAmountsError
        }
      })
      return error
    })

    const itemCount = computed(() => {
      return bulkResult?.value?.itemCount
    })

    const isFinished: ComputedRef<boolean> = computed(() => {
      return bulkResult?.value?.status === 1
    })

    const jobTitle: ComputedRef<string> = computed(() => {
      switch (propRefs.jobType.value) {
        case 'Kadaster.zoekPerceel':
          return i18n.tc('kadaster.bulk.parcelTitle')
        case 'Kadaster.geefEigendomstoestand':
          return i18n.tc('kadaster.bulk.ownershipTitle')
        default:
          return ''
      }
    })

    const jobItem: ComputedRef<string> = computed(() => {
      switch (propRefs.jobType.value) {
        case 'Kadaster.zoekPerceel':
          return i18n.tc('kadaster.bulk.parcel', itemCount.value)
        case 'Kadaster.geefEigendomstoestand':
          return i18n.tc('kadaster.bulk.ownership', itemCount.value)
        default:
          return ''
      }
    })

    const progressLabel: ComputedRef<string> = computed(() => {
      switch (propRefs.jobType.value) {
        case 'Kadaster.zoekPerceel':
          return i18n.t('kadaster.bulk.percentageProcessedParcel', { percentage: progress.value, sendingCount: itemCount.value })
        case 'Kadaster.geefEigendomstoestand':
          return i18n.t('kadaster.bulk.percentageProcessedOwnership', { percentage: progress.value, sendingCount: itemCount.value })
        default:
          return ''
      }
    })

    const uploadLabel: ComputedRef<string> = computed(() => {
      switch (propRefs.jobType.value) {
        case 'Kadaster.zoekPerceel':
          return i18n.t('kadaster.bulk.parcelUploadLabel', { fileName: bulkResult.value.jobDescription })
        default:
          return ''
      }
    })

    const getJobProgress = async () => {
      if (isFinished.value) clearInterval(polling)
      if (!propRefs.jobId.value) return
      if (isGettingProgress) return

      isGettingProgress = true
      
      await new API({ $axios: axios }, false)
        .jobProgress(propRefs.jobId.value, propRefs.jobType.value)
        .then((response) => {
          bulkResult.value = response
          isJobFound.value = true
        })
        .catch((error) => {
          clearInterval(polling)
        })
        .finally(() => (isGettingProgress = false))
    }

    watch(
      () => propRefs.jobId.value,
      (value, old) => {
        bulkResult.value = null
        isJobFound.value = false
        getJobProgress()

        polling = setInterval(() => {
          getJobProgress()
        }, 5000)
      },
      { immediate: true },
    )

    watch(
      () => isFinished.value,
      (value, old) => {
        if (value) {
          clearInterval(polling)
          emit('finished', propRefs.jobId.value)
        }
      },
      { immediate: true, deep: true },
    )

    const download = () => {
      emit('download', propRefs.jobId.value)
    }

    const clear = () => {
      emit('clear')
    }

    const close = () => {
      isJobFound.value = false
      clearInterval(polling)
    }

    return {
      isJobFound,
      progress,
      nameOfFile,
      startDate,
      amountSuccess,
      amountError,
      itemCount,
      isFinished,
      jobTitle,
      jobItem,
      progressLabel,
      download,
      uploadLabel,
      clear,
      close
    }
  },
})
