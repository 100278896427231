var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "flex" } },
    [
      _c("masked-input", {
        ref: "inputValueDay",
        class: _vm.classes,
        attrs: {
          type: _vm.type,
          disabled: _vm.modDisabled,
          mask: _vm.maskDay,
          "placeholder-char": _vm.placeholderChar,
          placeholder: "DD",
        },
        on: {
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          paste: _vm.onPaste,
        },
        model: {
          value: _vm.inputValueDay,
          callback: function ($$v) {
            _vm.inputValueDay = $$v
          },
          expression: "inputValueDay",
        },
      }),
      _c("masked-input", {
        ref: "inputValueMonth",
        class: _vm.classes,
        attrs: {
          type: _vm.type,
          disabled: _vm.modDisabled,
          mask: _vm.maskMonth,
          "placeholder-char": _vm.placeholderChar,
          placeholder: "MM",
        },
        on: {
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
        },
        model: {
          value: _vm.inputValueMonth,
          callback: function ($$v) {
            _vm.inputValueMonth = $$v
          },
          expression: "inputValueMonth",
        },
      }),
      _c("masked-input", {
        ref: "inputValueYear",
        class: _vm.classes,
        attrs: {
          type: _vm.type,
          disabled: _vm.modDisabled,
          mask: _vm.maskYear,
          "placeholder-char": _vm.placeholderChar,
          placeholder: "YYYY",
        },
        on: {
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
        },
        model: {
          value: _vm.inputValueYear,
          callback: function ($$v) {
            _vm.inputValueYear = $$v
          },
          expression: "inputValueYear",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }