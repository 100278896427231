import Vue from 'vue'
import MoDialog from '@/components/shared/mo-dialog/mo-dialog.vue'

let mounted = false
let root = {}

class Dialog {
  mount() {
    if (mounted !== true) {
      root = (() => {
        // creating constructor and instance
        let DialogConstructor = Vue.extend(MoDialog)
        let dialogInstance = new DialogConstructor()

        // creating mountpoint
        let node = document.createElement('div')
        document.querySelector('#__layout').appendChild(node)

        // mount the dialog
        return dialogInstance.$mount(node)
      })()

      mounted = true
    }
  }

  open(message = null, title = null) {
    this.mount()

    return new Promise((resolve, reject) => {
      root.setParams({
        title: title,
        message: message,
        resolve: resolve,
        reject: reject,
      })

      root.show()
    })
  }
}

Vue.prototype.$dialog = new Dialog()
