import { Component, Prop } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import validation from '@/mixins/validation'

export interface DateFields {
  month: string
  day: string
  year: string
}

@Component
export default class MoDateYearWrapper extends mixins(validation) {
  maskDay = [/[0-3]/, /[0-9]/]
  maskMonth = [/[0-1]/, /[0-9]/]
  maskYear = [/[0-2]/, /9|0/, /[0-9]/, /[0-9]/]
  regexDate = /^(.{0,4})-(.{0,2})-(.{0,2})$/

  inputValueDay = ''
  inputValueMonth = ''
  inputValueYear = ''

  @Prop(Boolean)
  modDisabled: boolean
  @Prop(Boolean)
  modSmall: boolean
  @Prop(Boolean)
  modInline: boolean
  @Prop(Boolean)
  modBlock: boolean
  @Prop({ default: true })
  showMask: boolean
  @Prop({
    default: () => {
      return { day: '', month: '', year: '' }
    },
  })
  value: DateFields
  @Prop({ default: ' ' })
  placeholderChar: string

  @Prop({
    default: () => {
      return ['YEAR']
    },
  })
  requiredFields: string

  @Prop({ default: false })
  guide: boolean

  //error: boolean = false

  get classes() {
    return [
      'vl-input-field',
      'mo-date-field',
      { 'vl-input-field--error': this.showError },
      { 'vl-input-field--disabled': this.modDisabled },
      { 'vl-input-field--small': this.modSmall },
      { 'vl-input-field--inline': this.modInline },
      { 'vl-input-field--block': this.modBlock },
    ]
  }

  isFieldRequired(field: string) {
    return this.requiredFields.indexOf(field.toUpperCase()) > -1
  }

  get inputValue(): DateFields {
    return this.value ? this.value : { day: '', month: '', year: '' }
  }

  set inputValue(newValue: DateFields) {
    this.$emit('input', newValue)
  }

  onChangeInput(): void {
    this.$emit('input', this.inputValue)
  }
}
