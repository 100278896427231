import i18n from '@/infrastructure/i18n'
export const defaultLocale = 'nl'

export const translate = (key: string, locale: string = null, values: Record<string, unknown> = null): string => {
  if (locale) {
    return i18n.t(key, locale, values)
  }
  return i18n.t(key, null, values)
}
export const locale = () => {
  return i18n.locale
}
