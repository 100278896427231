var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "__layout" } }, [
    _c(
      "div",
      { staticClass: "docu-vue-wrapper" },
      [
        _c("div", { staticClass: "content" }, [
          _c("div", { attrs: { id: "global-header" } }),
          _c(
            "div",
            { staticClass: "mo-header-wrapper" },
            [
              _c("mo-environment-header"),
              !_vm.isPublic ? _c("mo-functional-header-wrapper") : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "docu-vue-content" }, [
            _c(
              "main",
              [
                _c(
                  "vl-region",
                  [
                    _vm._l(_vm.alerts, function (alert) {
                      return _c("mo-alert", {
                        key: alert.type + "-" + alert.id,
                        attrs: {
                          "mod-warning": alert.type === "warning",
                          "mod-error": alert.type === "error",
                          "mod-success": alert.type === "success",
                          title: alert.title,
                          content: alert.content,
                          classes: "vl-layout",
                          decorator: "vl-u-spacer--large",
                          icon: _vm.alertIcon(alert),
                          buttons: alert.buttons,
                          "on-close": alert.onClose,
                          "scroll-to": alert.scrollTo,
                        },
                      })
                    }),
                    _vm.showPageContent
                      ? _c("router-view")
                      : _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "vl-align",
                                rawName: "v-vl-align:center",
                                arg: "center",
                              },
                            ],
                          },
                          [
                            _c("vl-loader", {
                              attrs: {
                                message: _vm.$t("general.label.loading"),
                              },
                            }),
                          ],
                          1
                        ),
                    _vm._l(_vm.toasts, function (toast, index) {
                      return _c(
                        "vl-toaster",
                        {
                          key: "toast_" + index,
                          attrs: { "mod-bottom-right": "", "fade-out": "" },
                        },
                        [
                          _c("vl-alert", {
                            attrs: {
                              title: toast.title,
                              content: toast.content,
                              icon: _vm.alertIcon(toast),
                              "mod-success": "",
                              "mod-fade-out": "",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "vl-modal",
          {
            attrs: {
              open: _vm.isSessionTimeoutVisible,
              closable: false,
              id: "sessiontimeout-modal",
            },
          },
          [
            _c("vl-title", { attrs: { "tag-name": "h3" } }, [
              _vm._v("Uw beveiligde sessie is verlopen"),
            ]),
            _c("vl-typography", [
              _vm._v("Geen probleem, meld opnieuw aan om verder te werken."),
            ]),
            _c("vl-u-spacer", { attrs: { "mod-medium": "" } }),
            _c(
              "vl-action-group",
              [
                _c(
                  "vl-button",
                  {
                    staticClass: "vl-button vl-button--large",
                    attrs: {
                      "tag-name": "a",
                      icon: "text-redo",
                      "mod-icon-before": "",
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.redirectToLogin.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Opnieuw aanmelden ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { attrs: { id: "global-footer" } }),
      ],
      1
    ),
    _c("div", { attrs: { id: "__dialog" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }