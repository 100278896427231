var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mo-textarea", {
    attrs: {
      id: _vm.id,
      name: _vm.name,
      "mod-error": _vm.showError,
      "mod-disabled": _vm.modDisabled,
      "mod-block": _vm.modBlock,
      placeholder: _vm.placeholder,
    },
    on: {
      blur: function ($event) {
        return _vm.$emit("blur", $event)
      },
    },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }