import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { clearTimeout, setTimeout } from 'timers'
import { ISelectionItem } from '@/declarations/references.models'

@Component
export default class MoSelectSearch extends Vue {
  open = false
  selectPosition = -1
  filter = ''
  scrollTriggered = 0
  timeout = null

  @Prop({ default: [], type: Array })
  items: ISelectionItem[]

  @Prop(Object)
  value

  @Prop({ default: '', type: String })
  keyId

  @Prop({ default: false, type: Boolean })
  modError

  @Prop({ default: true, type: Boolean })
  emptyField

  @Prop({ default: '', type: String })
  emptyMessage

  @Prop({ default: false, type: Boolean })
  modDisabled

  @Prop({ default: true, type: Boolean })
  enableSearch

  @Prop({ default: 250, type: Number })
  timeoutLength

  get filtered() {
    if (this.items) {
      if (this.items.length == 0) {
        return [
          {
            val: null,
            label: this.emptyMessage != '' ? this.emptyMessage : 'Geen data',
          },
        ]
      }

      // Make a copy of the list since we will manipulate the data in it
      let items: ISelectionItem[] = JSON.parse(JSON.stringify(this.items))

      items = items.filter((item) => {
        return item.label.match(new RegExp(this.filter, 'i'))
      })

      if (this.emptyField) {
        const message = 'leeg veld'
        items.unshift({ val: null, label: `(${message})` })
      }

      // Add a selected attribute to the list for arrow selection
      items.map((item) => {
        return { val: item.val, label: item.label, selected: false }
      })

      // If we have a selectPosition then set the selected attribute
      if (items[this.selectPosition] !== undefined) {
        items[this.selectPosition].selected = true
      }

      return items
    }
    return []
  }

  get mappedValue() {
    if (this.value && this.value !== undefined) {
      if (this.value.val === null) {
        return { label: '' }
      } else {
        return this.value
      }
    } else {
      return { label: '' }
    }
  }

  @Watch('filter')
  onFilterChange(newValue, _oldValue) {
    if (newValue.length > 1 && this.timeoutLength) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('filterChange', newValue)
      }, this.timeoutLength)
    } else {
      this.$emit('filterChange', newValue)
    }
  }

  itemText(text) {
    if (text == '') {
      return 'Selecteer optie'
    }
    return text
  }

  setFocus() {
    if (
      (this.$refs.focusable as HTMLElement) &&
      !(document.activeElement.classList.contains('vl-select__item') && (document.activeElement as HTMLElement).tabIndex === 999)
    ) {
      ;(this.$refs.focusable as HTMLElement).focus()
    }
  }

  keyPressHandler(event) {
    const selectPosition = this.selectPosition >= 0 ? this.selectPosition : 0
    if (this.$refs['select_' + selectPosition][0]) {
      const elementTop = this.$refs['select_' + selectPosition][0].offsetTop
      const elementHeight = this.$refs['select_' + selectPosition][0].clientHeight
      const parentHeight = this.$refs['select_' + selectPosition][0].parentElement.clientHeight
      const scrollTop = this.$refs['select_' + selectPosition][0].parentElement.scrollTop

      if (event.key === 'ArrowDown' || event.key === 'Down') {
        if (selectPosition < this.filtered.length - 1) {
          this.selectPosition++
          this.$emit('input', this.filtered[this.selectPosition])
        }
        if (elementTop - scrollTop > parentHeight - elementHeight) {
          this.$refs['select_' + selectPosition][0].parentElement.scrollTop = this.$refs['select_' + selectPosition][0].parentElement.scrollTop + elementHeight
          this.scrollTriggered = Date.now()
        }
      } else if (event.key === 'ArrowUp' || event.key === 'Up') {
        if (selectPosition > 0) {
          this.selectPosition--
          this.$emit('input', this.filtered[this.selectPosition])
        }
        if (elementTop - scrollTop - elementHeight < 0) {
          this.$refs['select_' + selectPosition][0].parentElement.scrollTop = this.$refs['select_' + selectPosition][0].parentElement.scrollTop - elementHeight
          this.scrollTriggered = Date.now()
        }
      } else {
        this.$emit('keydown', event)
      }
    } else {
      this.$emit('keydown', event)
    }
    if (selectPosition >= this.filtered.length) {
      this.selectPosition = this.filtered.length - 1
    }
  }

  expand() {
    if (!this.modDisabled === null || this.modDisabled === false) {
      this.open = true
      this.$nextTick(() => {
        if (this.$refs.search as HTMLElement) {
          ;(this.$refs.search as HTMLElement).focus()
        }
      })
      this.$root.$el.addEventListener('click', this.clickOutside, false)
    }
  }

  clickOutside(event) {
    if (event) {
      if (!(this.$el == event.target || this.$el.contains(event.target))) {
        this.close()
      }
    }
  }

  close() {
    this.open = false
    this.$emit('blur', event)
    this.$root.$el.removeEventListener('click', this.clickOutside, false)
  }

  selectValue() {
    if (this.selectPosition > -1) {
      this.$emit('input', this.filtered[this.selectPosition])
      this.close()
    }
  }

  mouseOver(index) {
    if (Date.now() - this.scrollTriggered > 200) {
      this.scrollTriggered = 0
      this.selectPosition = index
    }
  }
}
