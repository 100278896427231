import { Component, Vue, Watch } from 'vue-property-decorator'
import { Location } from 'vue-router'

@Component
export default class MoLanguageSwitcher extends Vue {
  show: boolean = false

  get availableLocales() {
    return this.$i18n.availableLocales.reverse()
  }

  activeLocale(locale: string) {
    return this.$i18n.locale === locale
  }

  @Watch('$route', { immediate: true })
  onRouteChange(newRoute: Location) {
    if (newRoute && newRoute.name) {
      if (newRoute.name.indexOf('__nl') > -1 || newRoute.name.indexOf('__en') > -1) {
        this.show = true
        return
      }
    }
    this.show = false
  }

  setLocale(locale: string) {
    this.$router.push({
      name: this.$route.name.split('___')[0] + '___' + locale,
      params: { ...this.$route.params, locale: locale },
      query: this.$route.query,
    })
  }
}
