import { Component, Prop, Vue } from 'vue-property-decorator'
enum DatePart {
  Day,
  Month,
  Year,
}

@Component
export default class MoDateInputField extends Vue {
  maskDay = [/[0-9]/, /[0-9]/]
  maskMonth = [/[0-9]/, /[0-9]/]
  maskYear = [/[0-2]/, /9|0/, /[0-9]/, /[0-9]/]
  regexDate = /^(.{0,4})-(.{0,2})-(.{0,2})$/

  @Prop({ default: 'text' })
  type: string
  @Prop(Boolean)
  modError: boolean
  @Prop(Boolean)
  modDisabled: boolean
  @Prop(Boolean)
  modSmall: boolean
  @Prop(Boolean)
  modInline: boolean
  @Prop(Boolean)
  modBlock: boolean
  @Prop({ default: true })
  showMask: boolean
  @Prop({ default: '--' })
  value: string

  @Prop({ default: false })
  guide: boolean

  @Prop({ default: ' ' })
  placeholderChar: string

  @Prop(String)
  placeholder: string

  get classes() {
    return [
      'vl-input-field',
      'mo-date-field',
      { 'vl-input-field--error': this.modError },
      { 'vl-input-field--disabled': this.modDisabled },
      { 'vl-input-field--small': this.modSmall },
      { 'vl-input-field--inline': this.modInline },
      { 'vl-input-field--block': this.modBlock },
    ]
  }

  set inputValueDay(newValue: string) {
    this.setPartOfDate(DatePart.Day, newValue)
    this.focusNextElement(newValue, this.$refs.inputValueMonth)
  }
  set inputValueMonth(newValue: string) {
    this.setPartOfDate(DatePart.Month, newValue)
    this.focusNextElement(newValue, this.$refs.inputValueYear)
  }
  set inputValueYear(newValue: string) {
    this.setPartOfDate(DatePart.Year, newValue)
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get inputValueDay(): string {
    return this.getPartOfDate(DatePart.Day)
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get inputValueMonth(): string {
    return this.getPartOfDate(DatePart.Month)
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  get inputValueYear(): string {
    return this.getPartOfDate(DatePart.Year)
  }

  getPartOfDate(datePart: DatePart): string {
    let safeValue = this.value
    if (!this.value) {
      safeValue = '--'
    }
    const dateParts = this.regexDate.exec(safeValue)
    if (dateParts && dateParts.length === 4) {
      switch (datePart) {
        case DatePart.Day:
          return dateParts[3]
        case DatePart.Month:
          return dateParts[2]
        case DatePart.Year:
          return dateParts[1]
      }
    }
  }

  onPaste(event) {
    const pastedContent: string = event.clipboardData
      ? event.clipboardData.getData('Text')
      : // tslint:disable-next-line:no-string-literal
        window['clipboardData'].getData('Text')
    if (pastedContent) {
      const numbersOnly = pastedContent.replace(/\D/g, '')
      let date = '--'
      if (numbersOnly.length === 8) {
        date =
          numbersOnly.slice(4, 8) +
          '-' +
          numbersOnly.slice(2, 4) +
          '-' +
          numbersOnly.slice(0, 2)
      }
      this.$nextTick(() => {
        this.$emit('input', date)
      })
    }
  }

  setPartOfDate(datePart: DatePart, value: string) {
    let safeValue = this.value
    if (!this.value) {
      safeValue = '--'
    }
    switch (datePart) {
      case DatePart.Day:
        this.$emit(
          'input',
          safeValue.replace(
            this.regexDate,
            `$1-$2-${this.removeSpaces(value)}`,
          ),
        )
        break
      case DatePart.Month:
        this.$emit(
          'input',
          safeValue.replace(
            this.regexDate,
            `$1-${this.removeSpaces(value)}-$3`,
          ),
        )
        break
      case DatePart.Year:
        this.$emit(
          'input',
          safeValue.replace(
            this.regexDate,
            `${this.removeSpaces(value)}-$2-$3`,
          ),
        )
        break
    }
  }

  focusNextElement(value, nextElement) {
    if (nextElement && value.replace(/\s/g, '').length === 2) {
      if (nextElement) {
        nextElement.$el.focus()
      }
    }
  }

  removeSpaces(value) {
    return value.replace(/\s/g, '')
  }
}