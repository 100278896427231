import VueRouter from 'vue-router'
import setLocale from './set-locale.guard'
import singleDomain from './single-domain.guard'
import authenticated from './authenticated.guard'
import setPagetitle from './set-pagetitle.guard'
import cleaner from './cleaner.guard'
import initUser from './init-user.guard'
import { startLoading, endLoading } from './loading.guards'
import { multiAfterGuard, multiBeforeGuard } from './multi-guard'
import gTag from '@/infrastructure/ga'
export interface IMultiGuardOptions {
  enableLogging: boolean
}

// add the guards in order of execution.
// always call the next() function in each guard
// the first middleware to call next with an non-empty argument will return to Vue and stop the middleware chain.
const beforeGuards = {
  startLoading,
  initUser,
  setLocale,
  authenticated,
  singleDomain,
  setPagetitle,
  cleaner,
}

// add the guards in order of execution
const afterGuards = {
  endLoading,
}

const initBeforeGuards = (router: VueRouter): void => {
  router.beforeEach((to, from, next) => {
    return multiBeforeGuard(to, from, next, beforeGuards)
    //}
  })
}

const initAfterGuards = (router: VueRouter): void => {
  router.afterEach((to, from) => {
    gTag.pageview({
      page_path: to.name,
      page_title: to.name,
      page_location: to.name,
    })
    console.log('afterguard',to)
    //return function (to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    return multiAfterGuard(to, from, afterGuards)
    //}
  })
}

export const initGuards = (router: VueRouter) => {
  initBeforeGuards(router)
  initAfterGuards(router)
}
