import moment from 'moment'
import { clearObject } from '@/assets/util'
import { IPersonToSave } from '@/declarations/persoon/persoon.models'
import { IPerson } from '@/declarations/references.models'

export function mapToPersonToSave(person: IPerson): IPersonToSave {
  const undefToNull = (v) => (v === undefined ? null : v)

  const newPerson = {
    burgerlijkeStaat: {
      code: null as string,
      datumBegin: null as string,
      datumEind: null as string,
      gemeente: null as string,
      gemeenteNisCode: null as string,
      land: null as string,
      landNisCode: null as string,
      partnerInsz: null as string,
      postcode: null as string,
    },
    contactadres: {
      busnummer: null as string,
      datumBegin: null as string,
      datumEinde: null as string,
      gemeente: null as string,
      gemeenteNis: null as string,
      huisnummer: null as string,
      land: null as string,
      landNisCode: null as string,
      postcode: null as string,
      straat: null as string,
      type: null as string,
    },
    geboorte: {
      datum: null as string,
      gemeente: null as string,
      gemeenteNisCode: null as string,
      land: null as string,
      landNisCode: null as string,
      postcode: null as string,
    },
    geslacht: {
      code: null as string,
      datumVan: null as string,
    },
    hoofdverblijfplaats: {
      busnummer: null as string,
      datumBegin: null as string,
      gemeente: null as string,
      gemeenteNis: null as string,
      huisnummer: null as string,
      land: null as string,
      landNisCode: null as string,
      postcode: null as string,
      straat: null as string,
    },
    naam: null as string,
    naamGeldigVanaf: null as string,
    nationaliteiten: [] as Array<{
      code: string
      datumBegin: string
      datumEind?: string
    }>,
    overlijden: {
      datum: null as string,
      gemeente: null as string,
      gemeenteNis: null as string,
      land: null as string,
      landNisCode: null as string,
    },
    voornamen: null as string[],
  }

  // Adding gender
  if (person.gender) {
    newPerson.geslacht = {
      code: person.gender.val ? person.gender.val : person.gender.toString(),
      datumVan: MagdaDate(person.genderSince),
    }
  }

  // Adding lastname
  newPerson.naam = person.lastname

  // Adding firstnames
  if (person.firstname?.length) {
    newPerson.voornamen = person.firstname.filter((firstname) => !!firstname)
    if (newPerson.voornamen.length === 0) {
      delete newPerson.voornamen
    }
  }

  newPerson.naamGeldigVanaf = MagdaDate(person.nameSince)

  // Adding birth
  newPerson.geboorte = {
    datum: MagdaDate(person.birthdate),
    gemeente: person.birthPlaceObject && person.birthPlaceObject.val ? person.birthPlaceObject.name : person.birthPlace,
    gemeenteNisCode: person.birthPlaceObject && person.birthPlaceObject.val ? person.birthPlaceObject.val : undefined,
    land: person.birthCountry ? person.birthCountry.label : null,
    landNisCode: person.birthCountry ? person.birthCountry.val : null,
    postcode: person.birthPlaceObject && person.birthPlaceObject.val ? person.birthPlaceObject.zip : undefined,
  }

  // Adding nationalities
  if (person.nationalities?.length) {
    newPerson.nationaliteiten = person.nationalities
      .filter((nationality) => {
        const since = moment(nationality.since, 'DD.MM.YYYY', true).isValid()

        if (nationality.until) {
          return since && moment(nationality.until, 'DD.MM.YYYY', true).isValid()
        }

        return since
      })
      .map((nationality) => {
        if (nationality.until) {
          return {
            code: nationality.nationality.val,
            datumBegin: moment(nationality.since, 'DD.MM.YYYY').format(),
            datumEind: moment(nationality.until, 'DD.MM.YYYY').format(),
          }
        } else {
          return {
            code: nationality.nationality.val,
            datumBegin: moment(nationality.since, 'DD.MM.YYYY').format(),
          }
        }
      })
  }

  // Adding marital status
  if (moment(undefToNull(person.maritalSince), 'DD.MM.YYYY').isValid()) {
    newPerson.burgerlijkeStaat.datumBegin = moment(person.maritalSince, 'DD.MM.YYYY').format()
  }

  if (moment(undefToNull(person.maritalUntil), 'DD.MM.YYYY').isValid()) {
    newPerson.burgerlijkeStaat.datumEind = moment(person.maritalUntil, 'DD.MM.YYYY').format()
  }

  if (person.maritalInsz) {
    newPerson.burgerlijkeStaat.partnerInsz = person.maritalInsz.match(/\d+/g).join('')
  }

  if (person.maritalStatus && Object.keys(person.maritalStatus).length !== 0 && person.maritalStatus.constructor === Object) {
    newPerson.burgerlijkeStaat.code = person.maritalStatus.val
  }

  if (person.maritalCountry && person.maritalCountry.val) {
    newPerson.burgerlijkeStaat.landNisCode = person.maritalCountry.val
    newPerson.burgerlijkeStaat.land = person.maritalCountry.label
  }

  if (person.maritalTownObject && person.maritalTownObject.val) {
    newPerson.burgerlijkeStaat.gemeente = person.maritalTownObject.name
    newPerson.burgerlijkeStaat.gemeenteNisCode = person.maritalTownObject.val
    newPerson.burgerlijkeStaat.postcode = person.maritalTownObject.zip
  } else if (person.maritalTown) {
    newPerson.burgerlijkeStaat.gemeente = person.maritalTown
  }

  // Adding residence
  if (person.pResidenceCountry && person.pResidenceCountry.val) {
    newPerson.hoofdverblijfplaats.landNisCode = person.pResidenceCountry.val
    newPerson.hoofdverblijfplaats.land = person.pResidenceCountry.label
    if (person.pResidenceCountry.val === '150') {
      newPerson.hoofdverblijfplaats.straat = person.pResidenceStreetObject.label
      newPerson.hoofdverblijfplaats.gemeente = person.pResidenceTownObject.name
      newPerson.hoofdverblijfplaats.gemeenteNis = person.pResidenceTownObject.val
      newPerson.hoofdverblijfplaats.postcode = person.pResidenceTownObject.zip
    } else {
      newPerson.hoofdverblijfplaats.straat = person.pResidenceStreet || null
      newPerson.hoofdverblijfplaats.gemeente = person.pResidenceTown || null
      newPerson.hoofdverblijfplaats.postcode = person.pResidenceZip || null
    }
    newPerson.hoofdverblijfplaats.huisnummer = person.pResidenceNumber || null
    newPerson.hoofdverblijfplaats.busnummer = person.pResidenceBus || null
    if (person.pResidenceSince) {
      newPerson.hoofdverblijfplaats.datumBegin = moment(person.pResidenceSince, 'DD.MM.YYYY').format()
    }
  }

  // Adding contact
  if (person.contactSince && person.contactStreet && person.contactTown && person.contactTown.val) {
    newPerson.contactadres.landNisCode = '150'
    newPerson.contactadres.straat = person.contactStreet.label
    newPerson.contactadres.huisnummer = person.contactStreetNumber || null
    newPerson.contactadres.busnummer = person.contactStreetBus || null
    newPerson.contactadres.gemeente = person.contactTown.name
    newPerson.contactadres.gemeenteNis = person.contactTown.val
    newPerson.contactadres.postcode = person.contactTown.zip
    newPerson.contactadres.type = person.contactType.val
    newPerson.contactadres.datumBegin = moment(person.contactSince, 'DD.MM.YYYY').format()
    if (person.contactUntil) {
      newPerson.contactadres.datumEinde = moment(person.contactUntil, 'DD.MM.YYYY').format()
    }
  }

  // Adding deceased
  if (person.passingDate && person.passingCountry && person.passingCountry.val && (person.passingTown || person.passingTownObject.val)) {
    newPerson.overlijden.datum = moment(person.passingDate, 'DD.MM.YYYY').format()

    newPerson.overlijden.land = person.passingCountry.label
    newPerson.overlijden.landNisCode = person.passingCountry.val

    if (person.passingCountry.val === '150') {
      newPerson.overlijden.gemeente = person.passingTownObject.name
      newPerson.overlijden.gemeenteNis = person.passingTownObject.val
    } else {
      newPerson.overlijden.gemeente = person.passingTown
    }
  }

  if (clearObject(newPerson.burgerlijkeStaat)) {
    delete newPerson.burgerlijkeStaat
  }
  if (clearObject(newPerson.hoofdverblijfplaats)) {
    delete newPerson.hoofdverblijfplaats
  }
  if (clearObject(newPerson.contactadres)) {
    delete newPerson.contactadres
  }
  if (clearObject(newPerson.overlijden)) {
    delete newPerson.overlijden
  }
  if (clearObject(newPerson.geslacht)) {
    delete newPerson.geslacht
  }
  if (!newPerson.nationaliteiten && !newPerson.nationaliteiten && newPerson.nationaliteiten.length === 0) {
    delete newPerson.nationaliteiten
  }

  return newPerson
}

export function MagdaDate(date: string): string {
  if (!date) {
    return '0000-00-00'
  }
  const match = date.match(/^([0-9]{0}|[0-9]{4})-([0-9]{0,2})-([0-9]{0,2})$/)

  if (moment(date, 'DD.MM.YYYY', true).isValid()) {
    return moment(date, 'DD.MM.YYYY', true).format()
  } else {
    if (match) {
      const year = match[1] || '0000'
      const month = match[2] || '00'
      const day = match[3] || '00'

      return [year, month, day].join('-')
    }
    return date
  }
}