var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        { staticClass: "vl-functional-header__language-switch" },
        _vm._l(_vm.availableLocales, function (locale) {
          return _c(
            "span",
            { key: locale, staticClass: "locale" },
            [
              !_vm.activeLocale(locale)
                ? [
                    _c(
                      "vl-link",
                      {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.setLocale(locale)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(locale.toUpperCase()) + " ")]
                    ),
                  ]
                : [
                    _c("span", { staticClass: "active" }, [
                      _vm._v(" " + _vm._s(locale.toUpperCase()) + " "),
                    ]),
                  ],
            ],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }