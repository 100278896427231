import { Component, Prop } from 'vue-property-decorator'
import Validation from '@/mixins/validation'
import { MonthYear } from '../mo-month-year-selector/mo-month-year-selector'
import MoMonthYearSelector from '../mo-month-year-selector/mo-month-year-selector.vue'

@Component({
  components: {
    MoMonthYearSelector,
  },
})
export default class MoMonthYearSelectorWrapper extends Validation {
  @Prop({ type: MonthYear, required: false })
  value: null
}
