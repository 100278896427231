import { BuildGeneralErrorObject } from './errorhandling'

export default class ErrorMessageMapper {
  static getError(response) {
    const title = this.getMessage(response.status)
    const content = this.getDescription(response)

    return {
      title,
      content,
    }
  }

  static getMessage(statusCode) {
    switch (Number(statusCode)) {
      case 401:
        return 'Enkel aangemelde gebruikers kunnen deze gegevens raadplegen'
      case 403:
        return 'U bent niet gemachtigd om deze actie uit te voeren'
      case 404:
        return 'We kunnen deze pagina helaas niet terug vinden'
      case 418:
        return 'Ik ben een teepot!'
      case 500:
      case 501:
      case 502:
      case 503:
      case 504:
      case 505:
      case 509:
      case 510:
      case 511:
        return 'Interne server fout'
      default:
        return 'Er is een probleem opgetreden met de verbinding naar de server'
    }
  }

  static getDescription(response) {
    let correlationId = null
    let correlationIdMessage = ''

    if (response.data?.headers && response.data?.headers['x-correlation-id']) {
      correlationId = response.data.headers['x-correlation-id']
    }

    if (response.headers && response.headers['x-correlation-id']) {
      correlationId = response.headers['x-correlation-id']
    }

    if (correlationId) {
      correlationIdMessage = `<br /> Er is een onverwachte fout opgetreden. Indien dit probleem zich meermaals voordoet, contacteer ons dan met volgende referentie: ${correlationId}`
    }

    if (correlationId && response.status >= 500 && response.status <= 511) {
      correlationIdMessage = BuildGeneralErrorObject({ correlationId }).description
      return correlationIdMessage
    }

    if (response.data && response.data.description) {
      return correlationId === null ? response.data.description : `${response.data.description} ${correlationIdMessage}`
    }

    if (response.status) {
      return correlationId === null
        ? `De server heeft geantwoord met foutcode: ${response.status}`
        : `De server heeft geantwoord met foutcode: ${response.status} ${correlationIdMessage}`
    }
    return ''
  }
}
