var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    _vm._b(
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue",
          },
        ],
        class: _vm.classes,
        attrs: { tabindex: "0" },
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.inputValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      "select",
      _vm.$attrs,
      false
    ),
    [
      _vm.placeholderLabel && !_vm.hidePlaceholder
        ? _c("option", { attrs: { disabled: "", value: "" } }, [
            _vm._v(_vm._s(_vm.placeholderLabel)),
          ])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }