import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { initGuards } from './guards'
import { RouteNames } from './RouteNames'
import { Permission } from '@/libs/constants'

Vue.use(VueRouter)

const Attesten = () => import('@/pages/attesten/index.vue' /* webpackChunkName: "attesten" */)
const Documentendienst = () => import('@/pages/documentendienst/index.vue' /* webpackChunkName: "documentendienst" */)
const DocumentProvider = () => import('@/pages/documentprovider/index.vue' /* webpackChunkName: "documentprovider" */)
const Index = () => import('@/pages/index.vue' /* webpackChunkName: "index" */)
const Kadaster = () => import('@/pages/kadaster/index.vue' /* webpackChunkName: "kadaster" */)
const Led = () => import('@/pages/leer_en_ervaring/index.vue' /* webpackChunkName: "leer_en_ervaring" */)
const Logging = () => import('@/pages/logging/index.vue' /* webpackChunkName: "logging" */)
const Mobiliteit = () => import('@/pages/mobiliteit/index.vue' /* webpackChunkName: "mobiliteit" */)
const Notificatie = () => import('@/pages/notificatie/index.vue' /* webpackChunkName: "notificatie" */)
const Persoon = () => import('@/pages/persoon/index.vue' /* webpackChunkName: "persoon" */)
const Profielselectie = () => import('@/pages/profielselectie/index.vue' /* webpackChunkName: "profielselectie" */)
const Rapportering = () => import('@/pages/rapportering/index.vue' /* webpackChunkName: "rapportering" */)
const SocialeZekerheid = () => import('@/pages/socialezekerheid/index.vue' /* webpackChunkName: "socialezekerheid" */)
const OneDoseRequired = () => import('@/pages/attesten/onedoserequired/index.vue' /* webpackChunkName: "attesten" */)
const LedToevoegen = () => import('@/pages/leer_en_ervaring/toevoegen/index.vue' /* webpackChunkName: "leer_en_ervaring" */)
const NotificatieBulk = () => import('@/pages/notificatie/bulk/index.vue' /* webpackChunkName: "notificatie" */)
const NotificatieLid = () => import('@/pages/notificatie/lid/index.vue' /* webpackChunkName: "notificatie" */)
const NotificatieVersturen = () => import('@/pages/notificatie/versturen/index.vue' /* webpackChunkName: "notificatie" */)
const NotificatieGroepVersturen = () => import('@/pages/notificatie/groep/versturen/index.vue' /* webpackChunkName: "notificatie" */)
const NotificatieGroep = () => import('@/pages/notificatie/groep/index.vue' /* webpackChunkName: "notificatie" */)
const NotificatieBulkResultaat = () => import('@/pages/notificatie/bulk/resultaat/index.vue' /* webpackChunkName: "notificatie" */)
const NotificatieLidNieuw = () => import('@/pages/notificatie/lid/nieuw/index.vue' /* webpackChunkName: "notificatie" */)
const NotificatieLidDetail = () => import('@/pages/notificatie/lid/_id/index.vue' /* webpackChunkName: "notificatie" */)
const PersoonFout = () => import('@/pages/persoon/fout/index.vue' /* webpackChunkName: "persoon" */)
const PersoonGezinssamenstelling = () => import("@/pages/persoon/gezinssamenstelling/index.vue" /* webpackChunkName: "persoon" */)
const PersoonToevoegen = () => import('@/pages/persoon/toevoegen.vue' /* webpackChunkName: "persoon" */)
const ProfielNietBeschikbaar = () => import('@/pages/profielselectie/unavailable.vue' /* webpackChunkName: "profielselectie" */)
const DocumentendienstBriefVerzenden = () => import('@/pages/documentendienst/brief/verzenden.vue' /* webpackChunkName: "documentendienst" */)
const DocumentendienstAdvancedBulk = () => import('@/pages/documentendienst/bulk/advanced/index.vue' /* webpackChunkName: "documentendienst" */)
const DocumentendienstSimpleBulk = () => import('@/pages/documentendienst/bulk/simple/index.vue' /* webpackChunkName: "documentendienst" */)
const DocumentendienstEboxVerzenden = () => import('@/pages/documentendienst/ebox/verzenden.vue' /* webpackChunkName: "documentendienst" */)
const DocumentendienstEmailVerzenden = () => import('@/pages/documentendienst/email/verzenden.vue' /* webpackChunkName: "documentendienst" */)
const LedToevoegenViaBestand = () => import('@/pages/leer_en_ervaring/toevoegen/via_bestand/index.vue' /* webpackChunkName: "leer_en_ervaring" */)
const DocumentendienstAdvancedBulkResultaat = () => import('@/pages/documentendienst/bulk/advanced/resultaat/index.vue' /* webpackChunkName: "documentendienst" */)
const DocumentendienstSimpleBulkResultaat = () => import('@/pages/documentendienst/bulk/simple/resultaat/index.vue' /* webpackChunkName: "documentendienst" */)
const DocumentendienstBriefHerverzenden = () => import('@/pages/documentendienst/brief/herverzenden/_messageId/index.vue' /* webpackChunkName: "documentendienst" */)
const DocumentendienstEboxHerverzenden = () => import('@/pages/documentendienst/ebox/herverzenden/_messageId/index.vue' /* webpackChunkName: "documentendienst" */)
const LedToevoegenViaBestandResultaat = () => import('@/pages/leer_en_ervaring/toevoegen/via_bestand/resultaat/_jobId/index.vue' /* webpackChunkName: "leer_en_ervaring" */)
const DocumentendienstVerzendingMessageid1 = () => import('@/pages/documentendienst/verzending/_messageid1/index.vue' /* webpackChunkName: "documentendienst" */)
const PersoonGezinssamenstellingInsz = () => import('@/pages/persoon/gezinssamenstelling/insz/_insz/index.vue' /* webpackChunkName: "persoon" */)
const DocumentendienstVerzendingMessageid1Messageid2 = () => import('@/pages/documentendienst/verzending/_messageid1/_messageid2/index.vue' /* webpackChunkName: "documentendienst" */)
const Ctpc = () => import('@/pages/ctpc/_ctpcId/index.vue' /* webpackChunkName: "ctpc" */)
const AttestId = () => import('@/pages/attesten/_attestId/index.vue' /* webpackChunkName: "attesten" */)
const LoggingRequest = () => import('@/pages/logging/_requestid/index.vue' /* webpackChunkName: "logging" */)
const BelgischVoertuig = () => import('@/pages/mobiliteit/belgischvoertuig/_nummerplaat/index.vue' /* webpackChunkName: "mobiliteit" */)
const PersoonInsz = () => import('@/pages/persoon/insz/_insz/index.vue' /* webpackChunkName: "persoon" */)
const BuitenlandVoertuigNummerplaat1 = () => import('@/pages/mobiliteit/buitenlandsvoertuig/_land/_nummerplaat1/index.vue' /* webpackChunkName: "mobiliteit" */)
const BuitenlandVoertuigNummerplaat2 = () => import('@/pages/mobiliteit/buitenlandsvoertuig/_land/_nummerplaat1/_nummerplaat2/index.vue' /* webpackChunkName: "mobiliteit" */)
const Download = () => import('@/pages/download/_guid/index.vue' /* webpackChunkName: "download/_guid/index" */)
const KadasterEigendomstoestandDetail = () => import('@/pages/kadaster/_capakeyPartitionId/index.vue' /* webpackChunkName: "kadaster" */)
const KadasterVerkoopprijzenDetail = () => import('@/pages/kadaster/salesdetail/index.vue' /* webpackChunkName: "kadaster" */)
const LedInsz = () => import('@/pages/leer_en_ervaring/_insz/index.vue' /* webpackChunkName: "leer_en_ervaring" */)
const LedInszReferte = () => import('@/pages/leer_en_ervaring/_insz/_referte/index.vue' /* webpackChunkName: "leer_en_ervaring" */)
const PageNotFound = () => import('@/pages/404.vue' /* webpackChunkName: "404" */)
const Gezin = () => import('@/pages/gezin/index.vue' /* webpackChunkName: "gezin" */)
const Onderneming = () => import('@/pages/onderneming/index.vue' /* webpackChunkName: "onderneming" */)
const OndernemingDetailKbo = () => import('@/pages/onderneming/_kbo/index.vue' /* webpackChunkName: "onderneming" */)
const KadasterHistoriekEigendomstoestand = () => import('@/pages/kadaster/ownershipstatushistory/index.vue' /* webpackChunkName: "kadaster" */)
const RapporteringMetTitel = () => import('@/pages/rapportering/rapport/rapport.vue' /* webpackChunkName: "rapportering" */)

const routes: RouteConfig[] = [
  {
    path: '/:locale(nl)?',
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      breadcrumb: {
        label: 'MAGDA Online',
      },
    },
    name: RouteNames.Index.nl,
    children: [
      {
        path: '',
        component: Index,
        name: RouteNames.Index.nl,
        meta: {
          breadcrumb: {
            label: 'Aan de slag met MAGDA Online',
          },
        },
      },
      {
        path: 'profielselectie',
        component: Profielselectie,
        name: RouteNames.ProfielSelectie.nl,
        meta: {
          breadcrumb: {
            label: 'Kies je profiel',
          },
        },
      },
      {
        path: 'profielselectie/nietbeschikbaar',
        component: ProfielNietBeschikbaar,
        name: RouteNames.ProfielNietBeschikbaar.nl,
        meta: {
          public: true,
        },
      },
      {
        path: 'notificatie',
        component: Notificatie,
        name: RouteNames.Notificatie.nl,
        meta: {
          breadcrumb: {
            label: 'Notificatie',
          },
          permissions: [
            Permission.pushNotifications.managementReceivers,
            Permission.pushNotifications.notificationsGetMember,
            Permission.pushNotifications.notificationsGetMembers,
          ]
        },
      },
      {
        path: 'notificatie/bulk',
        component: NotificatieBulk,
        name: RouteNames.NotificatieBulk.nl,
         meta: {
          breadcrumb: {
            label: 'Naar meerdere ontvangers versturen',
            parent: RouteNames.Notificatie.nl,
          },
          permissions: [
            Permission.bulk.bulkJobs,
            Permission.bulk.bulkJobsResults
          ]
        },
      },
      {
        path: 'notificatie/bulk/resultaat',
        component: NotificatieBulkResultaat,
        name: RouteNames.NotificatieBulkResultaat.nl,
         meta: {
          breadcrumb: {
            label: 'Resultaat',
            parent: RouteNames.NotificatieBulk.nl,
          },
          permissions: [
            Permission.bulk.bulkJobsResults
          ]
        },

      },
      {
        path: 'notificatie/lid',
        component: NotificatieLid,
        name: RouteNames.NotificatieLid.nl,
         meta: {
          breadcrumb: {
            label: 'Leden',
            parent: RouteNames.Notificatie.nl,
          },
          permissions: [
            Permission.pushNotifications.notificationsGetMember,
          ]
        },
      },
      {
        path: 'notificatie/versturen',
        component: NotificatieVersturen,
        name: RouteNames.NotificatieVersturen.nl,
         meta: {
          breadcrumb: {
            label: 'Notificatie versturen',
            parent: RouteNames.Notificatie.nl,
          },
          permissions: [
            Permission.pushNotifications.postNotificationsReceiver
          ]
        },
      },
      {
        path: 'notificatie/groep',
        component: NotificatieGroep,
        name: RouteNames.NotificatieGroep.nl,
         meta: {
          breadcrumb: {
            label: 'Groepen',
            parent: RouteNames.Notificatie.nl,
          },
        },
      },
      {
        path: 'notificatie/groep/versturen',
        component: NotificatieGroepVersturen,
        name: RouteNames.NotificatieGroepVersturen.nl,
         meta: {
          breadcrumb: {
            label: 'Notificatie versturen',
            parent: RouteNames.NotificatieGroep.nl,
          },
          permissions: [
            Permission.pushNotifications.postNotifications,
          ]
        },
      },
      {
        path: 'notificatie/lid/nieuw',
        component: NotificatieLidNieuw,
        name: RouteNames.NotificatieLidNieuw.nl,
         meta: {
          breadcrumb: {
            label: 'Nieuw lid',
            parent: RouteNames.NotificatieLid.nl,
          },
          permissions: [
            Permission.pushNotifications.notificationsPostMembers,
          ]
        },
      },
      {
        path: 'notificatie/lid/:id',
        component: NotificatieLidDetail,
        name: RouteNames.NotificatieLidDetail.nl,
         meta: {
          breadcrumb: {
            label: 'Detail lid',
            parent: RouteNames.NotificatieLid.nl,
          },
          permissions: [
            Permission.pushNotifications.notificationsGetMember,
          ]
        },
      },
      {
        path: 'rapportering',
        component: Rapportering,
        name: RouteNames.Rapportering,
        meta: {
          breadcrumb: {
            label: 'Rapportering',
          },
          permissions: [
            Permission.rapportering.zoek,
          ]
        },
      },
      {
        path: 'rapportering/:titel',
        component: RapporteringMetTitel,
        name: RouteNames.RapporteringTitel,
        meta: {
          breadcrumb: {
            label: 'Rapportering',
            parent:RouteNames.Rapportering
          },
          permissions: [
            Permission.rapportering.zoek,
          ]
        }
      },
      {
        path: '/download/:guid',
        component: Download,
        name: RouteNames.Download,
        meta: {
          public: true,
        },
      },
      {
        path: 'documentendienst',
        component: Documentendienst,
        name: RouteNames.Documentendienst.nl,
        meta: {
          breadcrumb: {
            label: 'Documentendienst',
          },
          permissions: [
            Permission.document.zoekVerzending,
          ]
        }
      },
      {
        path: 'documentendienst/brief/verzenden',
        component: DocumentendienstBriefVerzenden,
        name: RouteNames.DocumentendienstBriefVerzenden,
        meta: {
          breadcrumb: {
            label: 'Via brief verzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.verstuurBrief,
          ]
        },
      },
      {
        path: 'documentendienst/ebox/verzenden',
        component: DocumentendienstEboxVerzenden,
        name: RouteNames.DocumentendienstEboxVerzenden,
        meta: {
          breadcrumb: {
            label: 'Via eBox verzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.verstuurEboxBurger,
            Permission.document.verstuurEboxOnderneming,
          ]
        },
      },
      {
        path: 'documentendienst/email/verzenden',
        component: DocumentendienstEmailVerzenden,
        name: RouteNames.DocumentendienstEmailVerzenden,
        meta: {
          breadcrumb: {
            label: 'Via e-mail verzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.verstuurEmail,
          ]
        },
      },
      {
        path: 'documentendienst/verzending/:messageid1',
        component: DocumentendienstVerzendingMessageid1,
        name: RouteNames.DocumentendienstVerzendingMessageid1.nl,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.metadata,
          ]
        },
      },
      {
        path: 'documentendienst/verzending/:messageid1/:messageid2',
        component: DocumentendienstVerzendingMessageid1Messageid2,
        name: RouteNames.DocumentendienstVerzendingMessageid1Messageid2.nl,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.metadata,
          ]
        },
      },
      {
        path: 'documentendienst/bulk/advanced',
        component: DocumentendienstAdvancedBulk,
        name: RouteNames.DocumentendienstAdvancedBulk,
        meta: {
          breadcrumb: {
            label: 'Naar meerdere bestemmelingen verzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.verstuurBrief,
            Permission.document.verstuurEboxBurger,
            Permission.document.verstuurEboxOnderneming,
          ]
        },
      },
      {
        path: 'documentendienst/bulk/advanced/resultaat',
        component: DocumentendienstAdvancedBulkResultaat,
        name: RouteNames.DocumentendienstAdvancedBulkResultaat,
        meta: {
          breadcrumb: {
            label: 'Naar meerdere bestemmelingen verzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.verstuurBrief,
            Permission.document.verstuurEboxBurger,
            Permission.document.verstuurEboxOnderneming,
          ]
        },
      },
      {
        path: 'documentendienst/bulk/simple',
        component: DocumentendienstSimpleBulk,
        name: RouteNames.DocumentendienstSimpleBulk,
        meta: {
          breadcrumb: {
            label: 'Naar meerdere bestemmelingen verzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.bulk.bulkJobs,
            Permission.bulk.bulkJobsResults,
          ]
        },
      },
      {
        path: 'documentendienst/bulk/simple/resultaat',
        component: DocumentendienstSimpleBulkResultaat,
        name: RouteNames.DocumentendienstSimpleBulkResultaat,
        meta: {
          breadcrumb: {
            label: 'Naar meerdere bestemmelingen verzenden',
            parent: RouteNames.DocumentendienstSimpleBulk,
          },
          permissions: [
            Permission.bulk.bulkJobsResults,
          ]
        },
      },
      {
        path: 'documentendienst/brief/herverzenden/:messageId',
        component: DocumentendienstBriefHerverzenden,
        name: RouteNames.DocumentendienstBriefHerverzenden.nl,
        meta: {
          breadcrumb: {
            label: 'Via brief herverzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.verstuurBrief,
          ]
        },
      },
      {
        path: 'documentendienst/ebox/herverzenden/:messageId',
        component: DocumentendienstEboxHerverzenden,
        name: RouteNames.DocumentendienstEboxHerverzenden.nl,
        meta: {
          breadcrumb: {
            label: 'Via eBox herverzenden',
            parent: RouteNames.Documentendienst.nl,
          },
          permissions: [
            Permission.document.verstuurEboxBurger,
            Permission.document.verstuurEboxOnderneming,
          ]
        },
      },
      {
        path: 'persoon',
        component: Persoon,
        name: RouteNames.Persoon,
        meta: {
          breadcrumb: {
            label: 'Persoon',
          },
          permissions: [
            Permission.persoon.geefGezinssamenstelling,
            Permission.persoon.geefHistoriekGezinssamenstelling,
            Permission.persoon.geefHistoriekPersoon,
            Permission.persoon.geefPersoon,
            Permission.persoon.zoekPersoonOpAdres,
            Permission.persoon.zoekPersoonOpNaam,
          ]
        },
      },
      {
        path: 'persoon/toevoegen',
        component: PersoonToevoegen,
        name: RouteNames.PersoonToevoegen,
        meta: {
          breadcrumb: {
            label: 'Persoon toevoegen',
            parent: RouteNames.Persoon,
          },
          permissions: [
            Permission.persoon.creeerBis,
          ]
        },
      },
      {
        path: 'persoon/insz/:insz',
        component: PersoonInsz,
        name: RouteNames.PersoonInsz,
        meta: {
          breadcrumb: {
            label: 'Detail persoon',
            parent: RouteNames.Persoon,
          },
          permissions: [
            Permission.persoon.geefHistoriekPersoon,
            Permission.persoon.geefPersoon,
          ]
        },
      },
      {
        path: 'persoon/gezinssamenstelling',
        component: PersoonGezinssamenstelling,
        name: RouteNames.PersoonGezinssamenstelling,
        meta: {
          breadcrumb: {
            label: 'Gezinssamenstelling',
            parent: RouteNames.Persoon,
          },
        },
      },
      {
        path: 'persoon/gezinssamenstelling/insz/:insz',
        component: PersoonGezinssamenstellingInsz,
        name: RouteNames.PersoonGezinssamenstellingInsz,
        meta: {
          breadcrumb: {
            label: 'Detail gezinssamenstelling',
            parent: RouteNames.PersoonGezinssamenstelling,
          },
          permissions: [
            Permission.persoon.geefGezinssamenstelling,
            Permission.persoon.geefHistoriekGezinssamenstelling,
          ]
        },
      },
      {
        path: 'persoon/fout',
        component: PersoonFout,
        name: RouteNames.PersoonFout,
        meta: {
          breadcrumb: {
            label: 'Fout bij toevoegen',
            parent: RouteNames.Persoon,
          },
        },
      },
      {
        path: 'mobiliteit',
        component: Mobiliteit,
        name: RouteNames.Mobiliteit,
        meta: {
          breadcrumb: {
            label: 'Voertuig',
          },
          permissions: [
            Permission.mobiliteit.binnenlandsVoertuig,
            Permission.mobiliteit.buitenlandsVoertuig,
          ]
        },
      },
      {
        path: 'mobiliteit/belgischvoertuig/:nummerplaat',
        component: BelgischVoertuig,
        name: RouteNames.BelgischVoertuig,
        meta: {
          breadcrumb: {
            label: 'Voertuig',
            parent: RouteNames.Mobiliteit,
          },
          permissions: [
            Permission.mobiliteit.binnenlandsVoertuig,
          ]
        },
      },
      {
        path: 'mobiliteit/buitenlandsvoertuig/:land/:nummerplaat1',
        component: BuitenlandVoertuigNummerplaat1,
        name: RouteNames.BuitenlandVoertuigNummerplaat1,
        meta: {
          breadcrumb: {
            label: 'Voertuig',
            parent: RouteNames.Mobiliteit,
          },
          permissions: [
            Permission.mobiliteit.buitenlandsVoertuig,
          ]
        },
      },
      {
        path: 'mobiliteit/buitenlandsvoertuig/:land/:nummerplaat1/:nummerplaat2',
        component: BuitenlandVoertuigNummerplaat2,
        name: RouteNames.BuitenlandVoertuigNummerplaat2,
        meta: {
          breadcrumb: {
            label: 'Voertuig',
            parent: RouteNames.Mobiliteit,
          },
          permissions: [
            Permission.mobiliteit.buitenlandsVoertuig,
          ]
        },
      },
      {
        path: 'socialezekerheid',
        component: SocialeZekerheid,
        name: RouteNames.SocialeZekerheid,
        meta: {
          breadcrumb: {
            label: 'Sociale zekerheid',
          },
          permissions: [
            Permission.socialSecurity.geefSociaalStatuut,
          ]
        },
      },
      {
        path: 'documentprovider',
        component: DocumentProvider,
        name: RouteNames.DocumentProvider,
        meta: {
          breadcrumb: {
            label: 'Document provider',
          },
          permissions: [
            Permission.docProvider.admin,
          ]
        },
      },
      {
        path: 'kadaster',
        component: Kadaster,
        name: RouteNames.Kadaster,
        meta: {
          breadcrumb: {
            label: 'Kadaster',
          },
          permissions: [
            Permission.kadaster.zoekEigendomstoestanden,
            Permission.kadaster.zoekPerceel,
          ]
        },
      },
      {
        path: 'kadaster/verkoopprijzen',
        component: KadasterVerkoopprijzenDetail,
        name: RouteNames.KadasterVerkoopprijzenDetail,
        meta: {
          breadcrumb: {
            label: 'Verkoopprijzen',
            parent: RouteNames.Kadaster,
          },
          permissions: [
            Permission.kadaster.zoekVerkoopprijzen,
          ]
        },
      },
      {
        path: 'kadaster/historiek',
        component: KadasterHistoriekEigendomstoestand,
        name: RouteNames.KadasterHistoriekEigendomstoestand,
        meta: {
          breadcrumb: {
            label: 'Historiek',
            parent: RouteNames.Kadaster
          },
          permissions: [
            Permission.kadaster.geefHistoriekEigendomstoestand,
          ]
        }
      },
      {
        path: 'kadaster/:capakeyPartitionId',
        component: KadasterEigendomstoestandDetail,
        name: RouteNames.KadasterEigendomstoestandDetail,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Kadaster,
          },
          permissions: [
            Permission.kadaster.geefEigendomstoestanden,
          ]
        },
      },
      {
        path: 'leer_en_ervaring',
        component: Led,
        name: RouteNames.LED,
        meta: {
          breadcrumb: {
            label: 'Leer- en Ervaringsbewijzen',
          },
          permissions: [
            Permission.LED.geefBewijs
          ]
        },
      },
      {
        path: 'leer_en_ervaring/toevoegen',
        component: LedToevoegen,
        name: RouteNames.LedToevoegen,
        meta: {
          breadcrumb: {
            label: 'Bewijs toevoegen',
            parent: RouteNames.LED,
          },
          permissions: [
            Permission.LED.registreerBewijs,
            Permission.LED.bewijsMetadata,
          ]
        },
      },
      {
        path: 'leer_en_ervaring/toevoegen/via_bestand',
        component: LedToevoegenViaBestand,
        name: RouteNames.LedToevoegenViaBestand,
        meta: {
          breadcrumb: {
            label: 'Via bestand',
            parent: RouteNames.LedToevoegen,
          },
          permissions: [
            Permission.bulk.bulkJobs,
            Permission.bulk.bulkJobsResults,
          ]
        },
      },
      {
        path: 'leer_en_ervaring/toevoegen/via_bestand/resultaat/:jobId',
        component: LedToevoegenViaBestandResultaat,
        name: RouteNames.LedToevoegenViaBestandResultaat,
        meta: {
          breadcrumb: {
            label: 'Resultaat',
            parent: RouteNames.LedToevoegenViaBestand,
          },
          permissions: [
            Permission.bulk.bulkJobsResults,
          ]
        },
      },
      {
        path: 'leer_en_ervaring/:insz',
        component: LedInsz,
        name: RouteNames.LedInsz,
        meta: {
          breadcrumb: {
            label: `Detail Leer- en Ervaringsbewijzen`,
            parent: RouteNames.LED,
          },
          permissions: [
            Permission.LED.geefBewijs
          ]
        },
      },
      {
        path: 'leer_en_ervaring/:insz/:referte',
        component: LedInszReferte,
        name: RouteNames.LedInszReferte,
        meta: {
          breadcrumb: {
            label: 'Detail Leer- en Ervaringsbewijzen',
            parent: RouteNames.LedInsz,
          },
          permissions: [
            Permission.LED.geefBewijs
          ]
        },
      },
      {
        path: 'attesten',
        component: Attesten,
        name: RouteNames.Attesten.nl,
        meta: {
          breadcrumb: {
            label: 'Attesten',
          },
          permissions: [
            Permission.attesten.attesten,
            Permission.attesten.ctpc
          ]
        },
      },
      {
        path: 'attesten/eendosisvereist',
        component: OneDoseRequired,
        name: RouteNames.OnDoseRequired.nl,
        meta: {
          breadcrumb: {
            label: '1 dosis vereist',
            parent: RouteNames.Attesten.nl,
          },
          permissions: [
            Permission.attesten.oneDoseRequired,
          ]
        },
      },
      {
        path: 'attesten/:attestId',
        component: AttestId,
        name: RouteNames.AttestId.nl,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Attesten.nl,
          },
          permissions: [
            Permission.attesten.metadata
          ]
        },
      },
      {
        path: 'ctpc/:ctpcId',
        component: Ctpc,
        name: RouteNames.CtpcId.nl,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Attesten.nl,
          },
          permissions: [
            Permission.attesten.ctpc.metadata
          ]
        },
      },
      {
        path: 'logging',
        component: Logging,
        name: RouteNames.Logging,
        meta: {
          breadcrumb: {
            label: 'Audit register',
          },
          permissions: [
            Permission.logging.zoekLegalLog,
          ]
        },
      },   
      {
        path: 'logging/detail',
        component: LoggingRequest,
        name: RouteNames.LoggingRequestId,
        meta: {
          breadcrumb: {
            parent: RouteNames.Logging,
          },
        },

      },
      {
        path: 'gezin',
        component: Gezin,
        name: RouteNames.Gezin,
        meta: {
          breadcrumb: {
            label: 'Gezin',
          },
          permissions: [
            Permission.gezin.geefKindVoordelen,
          ]
        },
      },
      {
        path: 'onderneming',
        component: Onderneming,
        name: RouteNames.Onderneming,
        meta: {
          breadcrumb: {
            label: 'Onderneming',
          },
          permissions: [
            Permission.onderneming.geefOnderneming,
          ]
        },
      },
      {
        path: 'onderneming/:kbo',
        component: OndernemingDetailKbo,
        name: RouteNames.OndernemingDetailKbo,
        meta: {
          breadcrumb: {
            label: 'Detail onderneming',
            parent: RouteNames.Onderneming,
          },
          permissions: [
            Permission.onderneming.geefOnderneming
          ]
        },
      },
    ],
  },
  {
    path: '/:locale(en)',
    component: {
      template: `<router-view></router-view>`,
    },
    meta: {
      breadcrumb: {
        label: 'MAGDA Online',
      },
    },
    name: RouteNames.Index.en,
    children: [
      {
        path: '/',
        component: Index,
        name: RouteNames.Index.en,
        meta: {
          breadcrumb: {
            label: 'Get started with Magda online',
          },
        },
      },
      {
        path: 'profileselection',
        component: Profielselectie,
        name: RouteNames.ProfielSelectie.en,
        meta: {
          breadcrumb: {
            label: 'Choose your profile',
          },
        },
      },
      {
        path: 'profileselection/notavailable',
        component: ProfielNietBeschikbaar,
        name: RouteNames.ProfielNietBeschikbaar.en,
        meta: {
          public: true,
        },
      },
      {
        path: 'notification',
        component: Notificatie,
        name: RouteNames.Notificatie.en,
        meta: {
          breadcrumb: {
            label: 'Notifications',
          },
          permissions: [
            Permission.pushNotifications.managementReceivers,
            Permission.pushNotifications.notificationsGetMember,
            Permission.pushNotifications.notificationsGetMembers,
          ]
        },
      },
      {
        path: 'notification/member',
        component: NotificatieLid,
        name: RouteNames.NotificatieLid.en,
         meta: {
          breadcrumb: {
            label: 'Members',
            parent: RouteNames.Notificatie.en,
          },
          permissions: [
            Permission.pushNotifications.notificationsGetMember,
          ]
        },
      },
      {
        path: 'notification/bulk',
        component: NotificatieBulk,
        name: RouteNames.NotificatieBulk.en,
         meta: {
          breadcrumb: {
            label: 'Send with a file',
            parent: RouteNames.Notificatie.en,
          },
          permissions: [
            Permission.bulk.bulkJobs,
            Permission.bulk.bulkJobsResults
          ]
        },
      },
      {
        path: 'notification/bulk/result',
        component: NotificatieBulkResultaat,
        name: RouteNames.NotificatieBulkResultaat.en,
         meta: {
          breadcrumb: {
            label: 'Result',
            parent: RouteNames.NotificatieBulk.en,
          },
          permissions: [
            Permission.bulk.bulkJobsResults
          ]
        },
      },
      {
        path: 'notification/member/new',
        component: NotificatieLidNieuw,
        name: RouteNames.NotificatieLidNieuw.en,
         meta: {
          breadcrumb: {
            label: 'New member',
            parent: RouteNames.NotificatieLid.en,
          },
          permissions: [
            Permission.pushNotifications.notificationsPostMembers,
          ]
        },
      },
      {
        path: 'notification/send',
        component: NotificatieVersturen,
        name: RouteNames.NotificatieVersturen.en,
         meta: {
          breadcrumb: {
            label: 'Send notification',
            parent: RouteNames.Notificatie.en,
          },
          permissions: [
            Permission.pushNotifications.postNotificationsReceiver
          ]
        },
      },
      {
        path: 'notification/group',
        component: NotificatieGroep,
        name: RouteNames.NotificatieGroep.en,
         meta: {
          breadcrumb: {
            label: 'Groups',
            parent: RouteNames.Notificatie.en,
          },
        },
      },
      {
        path: 'notification/group/send',
        component: NotificatieGroepVersturen,
        name: RouteNames.NotificatieGroepVersturen.en,
         meta: {
          breadcrumb: {
            label: 'Send notification',
            parent: RouteNames.NotificatieGroep.en,
          },
          permissions: [
            Permission.pushNotifications.postNotifications,
          ]
        },
      },
      {
        path: 'notification/member/:id',
        component: NotificatieLidDetail,
        name: RouteNames.NotificatieLidDetail.en,
         meta: {
          breadcrumb: {
            label: 'Detail member',
            parent: RouteNames.NotificatieLid.en,
          },
          permissions: [
            Permission.pushNotifications.notificationsGetMember,
          ]
        },
      },
      {
        path: 'documentservice',
        component: Documentendienst,
        name: RouteNames.Documentendienst.en,
        meta: {
          breadcrumb: {
            label: 'Document service',
          },
          permissions: [
            Permission.document.zoekVerzending
          ]
        },
      },
      {
        path: 'documentservice/dispatch/:messageid1',
        component: DocumentendienstVerzendingMessageid1,
        name: RouteNames.DocumentendienstVerzendingMessageid1.en,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Documentendienst.en,
          },
          permissions: [
            Permission.document.metadata,
          ]
        },
      },
      {
        path: 'documentservice/dispatch/:messageid1/:messageid2',
        component: DocumentendienstVerzendingMessageid1Messageid2,
        name: RouteNames.DocumentendienstVerzendingMessageid1Messageid2.en,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Documentendienst.en,
          },
          permissions: [
            Permission.document.metadata,
          ]
        },
      },
      {
        path: 'documentendienst/brief/herverzenden/:messageId',
        component: DocumentendienstBriefHerverzenden,
        name: RouteNames.DocumentendienstBriefHerverzenden.en,
        meta: {
          breadcrumb: {
            label: 'Resend through paper',
            parent: RouteNames.Documentendienst.en,
          },
          permissions: [
            Permission.document.verstuurBrief,
          ]
        },
      },
      {
        path: 'documentendienst/ebox/herverzenden/:messageId',
        component: DocumentendienstEboxHerverzenden,
        name: RouteNames.DocumentendienstEboxHerverzenden.en,
        meta: {
          breadcrumb: {
            label: 'Resend through eBox',
            parent: RouteNames.Documentendienst.en,
          },
          permissions: [
            Permission.document.verstuurEboxBurger,
            Permission.document.verstuurEboxOnderneming,
          ]
        },
      },
      {
        path: 'healthattestations',
        component: Attesten,
        name: RouteNames.Attesten.en,
        meta: {
          breadcrumb: {
            label: 'Attestations',
          },
          permissions: [
            Permission.attesten.attesten,
            Permission.attesten.ctpc
          ]
        },
      },
      {
        path: 'healthattestations/onedoserequired',
        component: OneDoseRequired,
        name: RouteNames.OnDoseRequired.en,
        meta: {
          breadcrumb: {
            label: 'One dose required',
            parent: RouteNames.Attesten.en,
          },
          permissions: [
            Permission.attesten.oneDoseRequired,
          ]
        },
      },
      {
        path: 'healthattestations/:attestId',
        component: AttestId,
        name: RouteNames.AttestId.en,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Attesten.en,
          },
          permissions: [
            Permission.attesten.metadata
          ]
        },
      },
      {
        path: 'ctpc/:ctpcId',
        component: Ctpc,
        name: RouteNames.CtpcId.en,
        meta: {
          breadcrumb: {
            label: 'Detail',
            parent: RouteNames.Attesten.en,
          },
          permissions: [
            Permission.attesten.ctpc.metadata
          ]
        },
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
    meta: {
      breadcrumb: {
        label: 'Pagina niet gevonden',
        parent: RouteNames.Index.nl,
      },
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})

initGuards(router)

export default router
