export const routeMap = {
  logging: 'logging',
  person: 'persoon',
  messages: 'documentendienst',
  led: 'leer_en_ervaring',
  mobility: 'mobiliteit',
  healthattestations: 'attesten',
  reporting: 'rapportering',
  docprovider: 'documentprovider',
  socialsecurity: 'socialezekerheid',
  landregistry: 'kadaster',
  pushnotifications: 'notificatie',
  family: 'gezin',
  company: 'onderneming',
}
