import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MoModal extends Vue {
  @Prop({ default: 'div' })
  tagName: string

  @Prop({ default: true })
  closable: boolean

  @Prop({ required: true })
  id: string

  @Prop({ default: '' })
  title: string

  @Prop({ required: true })
  value: boolean

  get classes() {
    return ['vl-modal']
  }
  get labelId() {
    return this.title ? `${this.id}-label` : false
  }
  get describedId() {
    return `${this.id}-description`
  }
  get reference() {
    return `${this.id}`
  }

  get isVisible() {
    return this.value
  }
  set isVisible(val) {
    this.$emit('input', val)
  }
}
