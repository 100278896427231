var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "info-row", attrs: { width: "12" } },
    [
      _vm.label
        ? _c(
            "vl-title",
            {
              staticClass: "vl-text--alt info-cell",
              attrs: { "tag-name": "h6" },
            },
            [_vm._v(_vm._s(_vm.label) + ":")]
          )
        : _vm._e(),
      _c("div", {
        staticClass: "info-cell",
        domProps: { innerHTML: _vm._s(_vm.value) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }