import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Route } from 'vue-router'
import { uniqBy } from 'lodash'
import { moproStore } from '@/store/mopro'
import { userStore } from '@/store/user'

export interface IUpdateBreadcrumbEvent {
  routeName: string
  label: string
}

@Component
export default class MoFunctionalHeaderWrapper extends Vue {
  @Prop({ default: '' })
  goBackRouteName: string

  routes: Route[] = null

  moproStore = moproStore()
  userStore = userStore()
  
  get userExists() {
    return this.userStore.exists
  }

  get breadcrumbs() {
    return uniqBy(
      this.routes.filter((b) => b.name),
      'name',
    )
  }

  breadcrumbLabel(route: Route) {
    if (typeof route.meta.breadcrumb === 'function') {
      return route.meta.breadcrumb(this.$route.params).label
    }
    return route.meta.breadcrumb.label
  }

  created() {
    this.routes = this.$breadcrumbs
    this.$root.$on('update-breadcrumb', (event: IUpdateBreadcrumbEvent) => {
      const routeToUpdate = this.routes.filter((r) => r.name === event.routeName)[0]
      if (routeToUpdate) {
        const index = this.routes.indexOf(routeToUpdate)
        routeToUpdate.meta.breadcrumb.label = event.label
        this.routes[index] = routeToUpdate
        this.$forceUpdate()
      }
    })
  }

  @Watch('$breadcrumbs')
  onBreadCrumbsChanged() {
    this.routes = this.$breadcrumbs
  }

  goBack() {
    // Navigate to BackRoute if defined, else go to the previous page
    if (this.moproStore.goBackRouteName !== null) {
      this.$router.push(this.localePath({ name: this.moproStore.goBackRouteName }))
    } else {
      this.$router.go(-1)
    }
  }
}
