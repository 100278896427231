import i18n from '@/infrastructure/i18n'
import { ISupportedLocale } from '@/infrastructure/i18n/locales'
import { defineStore } from 'pinia'
export interface ILocaleState {
  _localizedUriPrefix: string
}



export const localeStore = defineStore('locale', {
  state: () => {
    return {
      _localizedUriPrefix: '',
    } as ILocaleState
  },
  getters: {
    locale: (): string => {
      return i18n.locale
    },
    localizedUriPrefix: (state: ILocaleState): string => {
      return state._localizedUriPrefix
    },
  },
  actions: {
    setLocale({ locale, app }: { locale: ISupportedLocale; app: Vue }) {
      if (locale) {
        // update both the $i18n Vue instance as the import instance (don't know why they are not in sync)
        app['$i18n'].locale = locale.code
        i18n.locale = locale.code
      }
    },
    setLocaleParam(localeParam: string) {
      this._localizedUriPrefix = localeParam
    },
  },
})
