import { Component, Prop, Vue } from 'vue-property-decorator'
import ErrorMessageMapper from '@/libs/errorMessageMapper'
import { userStore } from '@/store/user'

@Component
export default class Error extends Vue {
  @Prop({ type: Object })
  error

  @Prop({ default: false, type: Boolean })
  noButton: boolean

  @Prop(String)
  title: string

  @Prop(String)
  message: string

  @Prop({ type: String, default: 'h1' })
  tagName: string

  @Prop({ type: Boolean, default: true })
  padding: boolean

  @Prop(Object)
  button: {
    title: string
    function: () => void
  }

  userStore = userStore()

  get statusCode(): string {
    if (this.error.statusCode) {
      return this.error.statusCode
    }
    return ''
  }

  get isError404() {
    return this.error?.statusCode === 404
  }

  get mappedTitle(): string {
    if (this.title) {
      return this.title
    } else if (this.error.statusCode) {
      return `Error ${this.error.statusCode}`
    }
    return ''
  }

  get userExists(): boolean {
    return this.userStore.exists
  }

  get mappedButton(): { title: string; function: () => void } {
    if (this.button) {
      return this.button
    } else {
      return {
        title: 'Naar de startpagina',
        function: () => {
          this.$router.replace(this.localePath({ name: 'index' }))
        },
      }
    }
  }

  get mappedMessage() {
    if (this.message) {
      return this.message
    } else if (this.error.statusCode) {
      return ErrorMessageMapper.getMessage(this.error.statusCode)
    } else {
      return `<p>${this.error.message}</p><p>${this.error.stack ? this.error.stack : ''}</p>`
    }
  }
}
