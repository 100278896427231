var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.padding ? "vl-layout" : "" },
    [
      _vm.isError404
        ? [
            _c(
              "vl-region",
              [
                _c(
                  "vl-layout",
                  [
                    _c(
                      "vl-form-grid",
                      {
                        staticClass: "vl-grid--align-center vl-grid--v-center",
                        attrs: { "mod-stacked": "" },
                      },
                      [
                        _c(
                          "vl-column",
                          {
                            staticClass: "vl-u-hidden vl-u-visible--s",
                            attrs: { width: "6" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center",
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/images/page-not-found.svg" },
                                }),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "vl-column",
                          {
                            attrs: {
                              width: "6",
                              "width-s": "8",
                              "width-xs": "12",
                            },
                          },
                          [
                            _c("vl-title", { attrs: { "tag-name": "h2" } }, [
                              _vm._v(" Oeps, die pagina vonden we niet terug "),
                            ]),
                            _c("vl-typography", [
                              _vm._v(
                                "De pagina die u zoekt, vonden we niet terug."
                              ),
                            ]),
                            _c("vl-u-spacer", { attrs: { "mod-medium": "" } }),
                            _vm.userExists
                              ? _c(
                                  "vl-action-group",
                                  [
                                    _c(
                                      "vl-button",
                                      {
                                        attrs: { "mod-secondary": "" },
                                        on: {
                                          click: _vm.mappedButton.function,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.mappedButton.title) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "vl-column",
                          {
                            staticClass: "vl-u-hidden--s",
                            attrs: { width: "6" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vl-u-display-flex vl-u-flex-align-center vl-u-flex-v-center",
                              },
                              [
                                _c("img", {
                                  attrs: { src: "/images/page-not-found.svg" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "vl-title",
              { attrs: { "tag-name": _vm.tagName } },
              [
                _c(
                  "vl-icon-wrapper",
                  [
                    _c("vl-icon", { attrs: { icon: "alert-circle-filled" } }),
                    _c("span", { staticStyle: { "margin-left": "15px" } }, [
                      _vm._v(_vm._s(_vm.mappedTitle)),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "vl-grid",
              { attrs: { "mod-stacked": "" } },
              [
                _c("vl-column", [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.mappedMessage) },
                  }),
                ]),
                !_vm.noButton
                  ? _c(
                      "vl-column",
                      [
                        _c("div", { staticClass: "vl-u-spacer" }),
                        _c(
                          "vl-button",
                          {
                            attrs: { "mod-secondary": "" },
                            on: { click: _vm.mappedButton.function },
                          },
                          [_vm._v(" " + _vm._s(_vm.mappedButton.title) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }