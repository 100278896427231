var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        staticClass: "vl-form vl-u-spacer--large",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.searchInsz.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "vl-form__group" },
          [
            _c(
              "vl-form-grid",
              {
                staticClass: "vl-u-spacer--medium",
                attrs: { "mod-stacked": "" },
              },
              [
                _c(
                  "vl-column",
                  {
                    attrs: {
                      width: "3",
                      "width-m": "6",
                      "width-s": "12",
                      "width-xs": "12",
                    },
                  },
                  [
                    _c(
                      "mo-formfield-decorator",
                      { attrs: { label: "INSZ" } },
                      [
                        _c("mo-input-wrapper", {
                          ref: "focusElement",
                          attrs: {
                            guide: "",
                            options: _vm.options.insz,
                            "mod-disabled-keep": _vm.searching,
                            placeholder: "12.34.56-123.45",
                          },
                          model: {
                            value: _vm.insz,
                            callback: function ($$v) {
                              _vm.insz = $$v
                            },
                            expression: "insz",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.searchMode === "Persoon"
                  ? _c(
                      "vl-column",
                      {
                        attrs: {
                          width: "3",
                          "width-m": "6",
                          "width-s": "12",
                          "width-xs": "12",
                        },
                      },
                      [
                        _c("mo-person-search-source", {
                          attrs: {
                            dienst: _vm.searchSource,
                            selectedSource: _vm.source,
                          },
                          on: {
                            "update:selectedSource": function ($event) {
                              _vm.source = $event
                            },
                            "update:selected-source": function ($event) {
                              _vm.source = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showHistoryToggle
                  ? _c("vl-column", {
                      staticClass: "vl-u-hidden--m",
                      attrs: {
                        width:
                          _vm.searchMode === "Gezinssamenstelling" ? "9" : "6",
                      },
                    })
                  : _vm._e(),
                _vm.showHistoryToggle
                  ? _c(
                      "vl-column",
                      {
                        attrs: {
                          width: "3",
                          "width-m": "6",
                          "width-s": "12",
                          "width-xs": "12",
                        },
                      },
                      [
                        _c(
                          "mo-formfield-decorator",
                          [
                            _c("mo-checkbox-wrapper", {
                              attrs: {
                                "mod-switch": "",
                                options: _vm.options.showHistory,
                                "key-values": _vm.historyOptions,
                                name: "showHistory",
                                "mod-block": "",
                                "mod-disabled": _vm.hasHistoriekOnly,
                              },
                              model: {
                                value: _vm.showHistory,
                                callback: function ($$v) {
                                  _vm.showHistory = $$v
                                },
                                expression: "showHistory",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.historyChecked
                          ? _c(
                              "mo-formfield-decorator",
                              [
                                _c("mo-radio-wrapper", {
                                  staticClass: "vl-radio__wrapper__indent",
                                  attrs: {
                                    "key-values": _vm.dateOptions,
                                    name:
                                      _vm.searchMode === "Gezinssamenstelling"
                                        ? "familyHistoryDateChoice"
                                        : "dateChoice",
                                  },
                                  model: {
                                    value: _vm.dateChoice,
                                    callback: function ($$v) {
                                      _vm.dateChoice = $$v
                                    },
                                    expression: "dateChoice",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.historyChecked
                          ? _c(
                              "mo-formfield-decorator",
                              { staticClass: "vl-input__wrapper__indent" },
                              [
                                _c("mo-input-wrapper", {
                                  attrs: {
                                    type: "date",
                                    options: _vm.options.historyDate,
                                    "mod-disabled-keep": _vm.searching,
                                    guide: "",
                                    placeholder: "DD.MM.JJJJ",
                                  },
                                  model: {
                                    value: _vm.historyDate,
                                    callback: function ($$v) {
                                      _vm.historyDate = $$v
                                    },
                                    expression: "historyDate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("vl-column", {
                  attrs: {
                    width: "9",
                    "width-m": "6",
                    "width-s": "12",
                    "width-xs": "12",
                  },
                }),
                _vm.hasLegalLogReference
                  ? _c(
                      "vl-column",
                      { attrs: { width: "12" } },
                      [
                        _c(
                          "vl-column",
                          {
                            attrs: {
                              width: "3",
                              "width-m": "6",
                              "width-s": "12",
                              "width-xs": "12",
                            },
                          },
                          [
                            _c(
                              "mo-formfield-decorator",
                              { attrs: { label: "Uw referentie" } },
                              [
                                _c("mo-input-wrapper", {
                                  ref: "reference",
                                  attrs: { options: _vm.options.reference },
                                  model: {
                                    value: _vm.reference,
                                    callback: function ($$v) {
                                      _vm.reference = $$v
                                    },
                                    expression: "reference",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vl-form-grid vl-form-grid--is-stacked" },
              [
                _c("div", { staticClass: "vl-col--1-1" }, [
                  _c(
                    "div",
                    { staticClass: "vl-action-group" },
                    [
                      _c(
                        "vl-button",
                        { attrs: { modLoading: _vm.searching } },
                        [_vm._v("Bekijk detail")]
                      ),
                      _c(
                        "vl-button",
                        {
                          attrs: {
                            "mod-disabled": _vm.searching,
                            "mod-tertiary": "",
                            "mod-narrow": "",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.clearAll()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.clearFieldsLabel))]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]
    ),
    _vm.notFound
      ? _c(
          "div",
          { staticClass: "vl-u-spacer--large" },
          [
            _c("mo-error", {
              attrs: {
                title: "Geen resultaat gevonden",
                message:
                  "Er komen geen resultaten overeen met uw zoekcriteria.",
                "tag-name": "h2",
                "no-button": "",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }