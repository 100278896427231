import Vue from 'vue'
import VlUiVueComponents, {
  VlModalToggle,
  VlTooltip,
  VlToaster,
  VlAlert,
} from '@govflanders/vl-ui-vue-components'

Vue.use(VlUiVueComponents)
Vue.directive('vl-modal-toggle', VlModalToggle)
Vue.directive('vl-tooltip', VlTooltip)
Vue.directive('vl-toaster', VlToaster)
Vue.directive('vl-alert', VlAlert)
