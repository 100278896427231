var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "date-fields-wrapper" },
    [
      _c("masked-input", {
        ref: "inputValueDay",
        class: _vm.classes,
        attrs: {
          disabled: _vm.modDisabled,
          mask: _vm.maskDay,
          "placeholder-char": _vm.placeholderChar,
          placeholder: `${_vm.$t("general.placeholder.day")}${
            _vm.isFieldRequired("day") ? "*" : ""
          }`,
        },
        on: { change: _vm.onChangeInput },
        model: {
          value: _vm.inputValue.day,
          callback: function ($$v) {
            _vm.$set(_vm.inputValue, "day", $$v)
          },
          expression: "inputValue.day",
        },
      }),
      _c("masked-input", {
        ref: "inputValueMonth",
        class: _vm.classes,
        attrs: {
          disabled: _vm.modDisabled,
          mask: _vm.maskMonth,
          "placeholder-char": _vm.placeholderChar,
          placeholder: `${_vm.$t("general.placeholder.month")}${
            _vm.isFieldRequired("month") ? "*" : ""
          }`,
        },
        on: { change: _vm.onChangeInput },
        model: {
          value: _vm.inputValue.month,
          callback: function ($$v) {
            _vm.$set(_vm.inputValue, "month", $$v)
          },
          expression: "inputValue.month",
        },
      }),
      _c("masked-input", {
        ref: "inputValueYear",
        class: _vm.classes,
        attrs: {
          disabled: _vm.modDisabled,
          mask: _vm.maskYear,
          "placeholder-char": _vm.placeholderChar,
          placeholder: `${_vm.$t("general.placeholder.year")}${
            _vm.isFieldRequired("year") ? "*" : ""
          }`,
        },
        on: { change: _vm.onChangeInput },
        model: {
          value: _vm.inputValue.year,
          callback: function ($$v) {
            _vm.$set(_vm.inputValue, "year", $$v)
          },
          expression: "inputValue.year",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }